import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-query-display-modal',
  templateUrl: './query-display-modal.component.html',
  styleUrls: ['./query-display-modal.component.scss']
})
export class QueryDisplayModalComponent implements OnInit {
  queryForm = new UntypedFormControl('');

  @Input() set query(value: string) {
    this.queryForm.setValue(value);
  }

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
