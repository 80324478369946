import { Injectable, OnDestroy } from '@angular/core';
import { PageName, ComponentType, PropertyTag, TableInput, DisplayTabInput } from '../model/page-configuration.model';
import { PageConfigService } from './page-config.service';
import { ProcessProperty } from '../model/graph.model';
import { Subject, Observable } from 'rxjs';
import { DisplayTime } from '../common/display-time';
import { ReportInput, PowerBIInput, PowerBIType, PowerBIBaseInput } from '../../power-bi/model/power-bi.model';

/**
 * property tagging service handles the creation of input for the
 * tabbed input component. The tabbed display component requires
 * input in a specific format and its is designed to read the input only
 * from the property tagging service. This service is to be injected thru
 * the providers for each component which uses the display tab component.
 */
@Injectable()
export class PropertyTaggingService implements OnDestroy {
  private componentInputArray: Array<any>;
  private inputSequenceMap: Map<string, number>;
  private inputSubject: Subject<DisplayTabInput> = new Subject<DisplayTabInput>();
  private currentPageNameSubject: Subject<PageName>;
  private disableComponentSubject: Subject<boolean>;
  private clearComponentSubject: Subject<boolean>;
  private enableTab: Array<boolean>;

  constructor(
    private pageConfigService: PageConfigService,
  ) {
    this.currentPageNameSubject = new Subject<PageName>();
    this.disableComponentSubject = new Subject<boolean>();
    this.clearComponentSubject = new Subject<boolean>();
    this.enableTab = new Array<boolean>();
  }

  ngOnDestroy() {
    this.inputSubject.complete();
    this.currentPageNameSubject.complete();
    this.disableComponentSubject.complete();
    this.clearComponentSubject.complete();
  }

  /**
   * This methods initializes the setup for property tagging service.
   * method should be called before adding properties or to add new set of properties.
   * For each page and component, create the required input.
   *
   * @param pageName Name of the current page to create input for.
   */
  public createPageComponentInput(pageName: PageName) {
    this.componentInputArray = new Array<any>();
    this.inputSequenceMap = new Map<string, number>();
    const displayConfig = this.pageConfigService.getPageDisplayConfig(pageName);
    // iterate over the current page config and create the input array.
    let sequenceNumber = 0;
    for (const dConfig of displayConfig) {
      this.inputSequenceMap.set(dConfig.id, sequenceNumber);
      this.enableTab.push(false);
      if (dConfig.componentType === ComponentType.Object) {
        this.componentInputArray.push(this.createObjectInput());
      } else if (dConfig.componentType === ComponentType.Graph) {
        this.componentInputArray.push(this.createGraphInput());
      } else if (dConfig.componentType === ComponentType.Table) {
        this.componentInputArray.push(this.createTableInput());
      } else if (dConfig.componentType === ComponentType.Report) {
        this.componentInputArray.push(this.createReportInput());
      } else if (dConfig.componentType === ComponentType.ProcessMap) {
        this.componentInputArray.push(this.createGraphInput());
      } else if (dConfig.componentType === ComponentType.QueryOptions) {
        this.componentInputArray.push(this.createNullInput());
      }
      sequenceNumber += 1;
    }
  }

  /**
   * This method adds the property to the component input array.
   *
   * @param pageName page name to add the property
   * @param property the current property to add to the input array.
   */
  public addProperty(pageName: PageName, property: ProcessProperty) {
    const propertyTags = property.propertyTags;
    // process all the tags for each property except system tags.
    for (const propertyTag of propertyTags) {
      if (propertyTag !== PropertyTag.System) {
        const displayConfigId = this.pageConfigService.getConfigByPropertyTag(pageName, propertyTag);
        const displayConfig = this.pageConfigService.getConfigById(pageName, displayConfigId);
        const componentInputArrayIndex = this.inputSequenceMap.get(displayConfigId);
        // enable tab which has a property.
        this.enableTab[componentInputArrayIndex] = true;
        const componentInput = this.componentInputArray[componentInputArrayIndex];
        if (propertyTag === PropertyTag.CSOGraph) {
          this.componentInputArray[componentInputArrayIndex] = property.value;
        } else if (propertyTag === PropertyTag.SampleCases) {
          // convert sample data to required table input format.
          const tableData = this.constructSampleCaseTable(property);
          const tableInput = {} as TableInput;
          tableInput.columns = new Array<string>();
          tableInput.rows = new Array<Array<string>>();
          if (displayConfig.componentType === ComponentType.Table) {
            for (const column of tableData[0]) {
              tableInput.columns.push(column);
            }
            for (const rowValue of tableData.slice(1)) {
              tableInput.rows.push(rowValue);
            }
            this.componentInputArray[componentInputArrayIndex] = tableInput;
          } else {
            console.log('For property tag SampleCases, only component type Table is supported.');
          }
        } else if (propertyTag === PropertyTag.MetricsTable) {
          if (displayConfig.componentType === ComponentType.Table) {
            (componentInput as TableInput).rows.push([property.name, property.value]);
          } else {
            console.log('For property tag MetricTable, only component type Table is supported.');
          }
        } else if (propertyTag === PropertyTag.Payload) {
          if (displayConfig.componentType === ComponentType.Object) {
            try {
              componentInput[property.name] = JSON.parse(property.value);
            } catch {
              componentInput[property.name] = property.value;
            }
          } else {
            console.log('For property tag Payload, only component type Object is supported.');
          }
        } else if (propertyTag === PropertyTag.PathEdgeMetrics) {
          // convert data to required table input format.
          const tableData = this.constructPathEdgeMetrics(property);
          if (displayConfig.componentType === ComponentType.Table) {
            for (const column of tableData[0]) {
              (componentInput as TableInput).columns.push(column);
            }
            for (const rowValue of tableData.slice(1)) {
              (componentInput as TableInput).rows.push(rowValue);
            }
          } else {
            console.log('For property tag PathEdgeMetrics, only component type Table is supported.');
          }
        } else if (propertyTag === PropertyTag.PathMetrics) {
          if (displayConfig.componentType === ComponentType.Table) {
            (componentInput as TableInput).rows.push([property.name, this.getDecoratedPathMetricPropertyValue(property)]);
          } else {
            console.log('For property tag PathMetrics, only component type Table is supported.');
          }
        } else if (propertyTag === PropertyTag.PowerBIReport) {
          const powerBIInput: PowerBIInput = JSON.parse(property.value);
          if (powerBIInput.type === PowerBIType.Report) {
            const reportInput: ReportInput = powerBIInput.value as ReportInput;
            this.componentInputArray[componentInputArrayIndex] = reportInput;
          }
          // TODO: add handling for other power bi types.
        } else if (propertyTag === PropertyTag.ProcessMiningGraph) {
          this.componentInputArray[componentInputArrayIndex] = null;
        } else if (propertyTag === PropertyTag.ProcessMiningGraphV2) {
          this.componentInputArray[componentInputArrayIndex] = null;
        } else if (propertyTag === PropertyTag.QueryOptions) {
          this.componentInputArray[componentInputArrayIndex] = null;
        } else if (propertyTag === PropertyTag.System) {
        }
      }
    }
  }

  // Method to construct the sample case table
  constructSampleCaseTable(property: ProcessProperty): Array<Array<string>> {
    const tableData = new Array<Array<string>>();
    tableData.push(['Serial Number', 'Case Id']);
    for (let index = 0; index < property.value.length; index++) {
      const row = Array<string>();
      row.push(String(index + 1));
      row.push(property.value[index]);
      tableData.push(row);
    }
    return tableData;
  }

  constructPathEdgeMetrics(property: ProcessProperty): Array<Array<string>> {
    // Add path's edge level data detail
    const pathEdgeInfoTable = new Array<Array<string>>();
    pathEdgeInfoTable.push(['Source Node', 'Target Node', 'Count', 'Average Time']);
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let index = 0; index < property.value.length; index++) {
      const row = Array<string>();
      row.push(property.value[index].sourceNode);
      row.push(property.value[index].targetNode);
      row.push(property.value[index].count.toString());
      row.push(DisplayTime.getFormattedDisplayTime(property.value[index].averageTime));
      pathEdgeInfoTable.push(row);
    }
    return pathEdgeInfoTable;
  }

  // Get decorated value for path metrics
  getDecoratedPathMetricPropertyValue(property: ProcessProperty): string {
    let decoratedValue: string;
    const propertyName = property.name;
    if (propertyName === 'Frequency' || propertyName === 'CumulativeFrequencyDesc') {
      decoratedValue = (property.value * 100).toFixed(2) + '%';
    } else if (propertyName === 'AverageExecutionTime') {
      decoratedValue = DisplayTime.getDisplayTime(property.value);
    } else {
      decoratedValue = property.value;
    }
    return decoratedValue;
  }

  /**
   * return the observable to get the value of component input.
   */
  public getComponentInput(): Observable<DisplayTabInput> {
    return this.inputSubject.asObservable();
  }

  /**
   * Emit the value from the service to the display tab component.
   */
  public setComponentInput(): void {
    const displayTabInput = new DisplayTabInput(this.componentInputArray, this.enableTab);
    this.inputSubject.next(displayTabInput);
  }

  public setCurrentPagename(pageName: PageName) {
    this.currentPageNameSubject.next(pageName);
  }

  public getCurrentPagename(): Observable<PageName> {
    return this.currentPageNameSubject.asObservable();
  }

  public disableComponent(): void {
    this.disableComponentSubject.next(true);
  }

  public enableComponent(): void {
    if (this.enableTab.filter((t) => t).length) {
      this.disableComponentSubject.next(false);
    } else {
      this.disableComponentSubject.next(true);
    }
  }

  public getComponentStatus(): Observable<boolean> {
    return this.disableComponentSubject.asObservable();
  }

  public clearInput(): void {
    this.enableTab = new Array<boolean>();
    this.clearComponentSubject.next(true);
  }

  public getInputStatus(): Observable<boolean> {
    return this.clearComponentSubject.asObservable();
  }

  // create input types for component types.
  private createObjectInput(): any {
    return {};
  }

  private createGraphInput(): any {
    return null;
  }

  private createTableInput(): TableInput {
    return new TableInput();
  }

  private createReportInput(): ReportInput {
    return {} as ReportInput;
  }

  private createNullInput(): any {
    return null;
  }
}
