<ng-template #itemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand"
  let-onCheckedChange="onCheckedChange">
  <ng-container>
    <div class="text-nowrap row-item p-0 m-0">
      <button *ngIf="item.children" aria-label="Collapse Expand Arrow" type="button" class="btn btn p-0 m-0 ml-1"
        [id]="item.value.id" (click)="onCollapseExpand();" [ngSwitch]="item.collapsed" [routerLink]="[]">
        <ng-container *ngSwitchCase="true">
          <span class="fa fa-caret-right fa-1x"></span>
        </ng-container>
        <ng-container *ngSwitchCase="false">
          <span class="fa fa-caret-down fa-1x"></span>
        </ng-container>
      </button>
      <button *ngIf="!item.children && item.value.hasChildren" aria-label="Collapse Expand Arrow" type="button"
        [id]="'button-'+item.value.id" class="btn btn p-0 m-0 ml-1" (click)="getChildrens(item)" [ngSwitch]="item.collapsed"
        [routerLink]="[]">
        <ng-container *ngSwitchCase="true">
          <ng-container *ngIf="!isLoadingChildren">
            <span class="fa fa-caret-right fa-1x"></span>
          </ng-container>
          <ng-container *ngIf="isLoadingChildren && loadingItem === item?.value.id" class="ml-2">
            <span class="fa fa-spinner fa-spin fa-1x"></span>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="false">
          <span class="fa fa-caret-down fa-1x"></span>
        </ng-container>
      </button>
      <button type="button" [attr.aria-label]="item.text + 'tree item'"
      [ngClass]="(item?.value.type === lastSelectedItem?.value.type && item?.value.id === lastSelectedItem?.value.id) ?'btn btn-secondary px-1 py-0 ml-1 rounded':'btn p-0 ml-1 text-dark rounded'"
        [ngStyle]="{'cursor': item.value.hasChildren ? 'default' : 'pointer' }" [routerLink]="[]" type="button"
        (click)="clickItem(item)" style="border: none;">{{item.text}}</button>
    </div>
  </ng-container>
</ng-template>
<ng-template #headerTemplate let-config="config" let-item="item" let-onCollapseExpand="onCollapseExpand"
  let-onCheckedChange="onCheckedChange" let-onFilterTextChange="onFilterTextChange">
  <ng-container>
    <div class="d-flex bd-highlight" #headerDiv>
      <div class="p-1 flex-grow-1 bd-highlight" *ngIf="config.hasFilter">
        <input class="form-control" style="height: 2rem;" title="filter textbox" type="text" [formControl]="filterText"
          [placeholder]="getFilterPlaceholder()" aria-label="Filter Text Box" />
      </div>
      <div *ngIf="config.hasAllCheckBox || config.hasCollapseExpand" class="p-1 bd-highlight">
        <div>
          <label *ngIf="config.hasAllCheckBox" (click)="select(item)">
            <strong>{{ getAllCheckboxText() }}</strong>
          </label>
          <button *ngIf="config.hasCollapseExpand" type="button" class="btn btn p-0 m-1" (click)="onCollapseExpand();"
            aria-label="Collapse or Expand All" [ngSwitch]="item.collapsed" [routerLink]="[]">
            <ng-container *ngSwitchCase="true">
              <span class="fa fa-expand"></span>
            </ng-container>
            <ng-container *ngSwitchCase="false">
              <span class="fa fa-compress"></span>
            </ng-container>
          </button>
        </div>
      </div>
      <div *ngIf="config.hasDivider" class="dropdown-divider"></div>
    </div>
  </ng-container>
</ng-template>
<div class="d-flex flex-column py-1">
  <!-- business process title. -->
  <div class="d-flex flex-row">
    <div style="float: left;" class="ml-3 mt-2 mb-0">
      <label [attr.aria-label]="'Selected Item'+ getSelectedText()" tabindex="0" #titleLabel>
        <h6 class="m-0"> {{title}}: {{ getSelectedText() }}</h6>
      </label>
    </div>
    <div *ngIf="isTreeViewCollapsed" class="ml-auto pt-2 mr-1">
      <button type="button" class="btn p-0 mb-1" data-toggle="collapse" data-target="#TreeViewDetails"
        aria-label="Expand" aria-label="Open Tree View" title="Open Tree View" (click)="toggleTreeView()">
        <span class="fa fa-angle-double-down"></span>
      </button>
    </div>
  </div>

  <div class="collapse show mx-2 mb-1" id="TreeViewDetails" #treeViewDivElement>
    <div *ngIf="!isTreeLoaded" class="ml-2">
      <span class="fa fa-spinner fa-spin fa-2x"></span>
    </div>
    <!-- TODO: update height to viewport instead of px -->
    <div *ngIf="isTreeLoaded" class="treeviewDiv mt-1 mb-1" style="height: 300px;" appDivDimChange (divDimChange)="resizeTree()">
      <div>
        <ngx-treeview [config]="config" [items]="items" [headerTemplate]="headerTemplate" [itemTemplate]="itemTemplate" #treeView>
        </ngx-treeview>
      </div>
      <div class="d-flex justify-content-end" #footerElement>
        <div class="mt-3 mb-2">
          <button class="btn btn-sm secondary-button" aria-label="Select Item Button" #selectItem type="button"
            [disabled]="!lastSelectedItem" (click)="okClick()">
            <span>Select Item</span>
          </button>
        </div>
        <div *ngIf="!isTreeViewCollapsed" class="mt-3 mb-2 ml-3 pb-2">
          <button type="button" class="btn p-0 mb-1" data-toggle="collapse" data-target="#TreeViewDetails"
            aria-label="Collapse" aria-label="Collapse Tree View" title="Collapse Tree View" (click)="toggleTreeView()">
            <span class="fa fa-angle-double-up"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
