<form [formGroup]="dateRange">
    <div class="d-flex flex-column pl-2 pb-1">
        <h6>Filter Options</h6>
        <div class="d-flex flex-row">
            <!-- start date. -->
            <div class="input-group-sm d-flex flex-row pl-3 mr-3">
                <div class="input-group-prepend">
                    <span style="width: 5rem;" class="input-group-text" id="startDateLabel">Start Date: </span>
                </div>
                <input type="text" style="width: 10rem;" class="form-control required" aria-label="Start Date"
                    formControlName="startDateTimeControl" [owlDateTimeTrigger]="startDateTime"
                    [owlDateTime]="startDateTime" attr.aria-label="Start Date" attr.aria-describedby="startDateLabel">
                <owl-date-time #startDateTime></owl-date-time>
            </div>

            <!-- end date. -->
            <div class="input-group-sm d-flex flex-row">
                <div class="input-group-prepend">
                    <span style="width: 5rem;" class="input-group-text" id="endDateLabel">End Date: </span>
                </div>
                <input type="text" style="width: 10rem;" class="form-control required" aria-label="End Date"
                    formControlName="endDateTimeControl" [owlDateTimeTrigger]="endDateTime" [owlDateTime]="endDateTime"
                    attr.aria-label="End Date" attr.aria-describedby="endDateLabel">
                <owl-date-time #endDateTime></owl-date-time>
            </div>
        </div>

        <!-- error messages -->
        <div class="text-danger">
            <small> {{ dateRange.errors?.errorMessage }} </small>
        </div>

        <!-- Apply button. -->
        <div class="d-flex flex-row my-2 ml-1">
            <button class="btn btn-sm primary-button" type="submit" [disabled]="isValueUnchanged === false && dateRange.invalid === true"
                (click)="applyFilter()">Apply</button>
            <button class="btn btn-sm danger-button ml-2" type="button" [disabled]="!isRequestInProgress"
                (click)="cancelExecution()">Cancel</button>
            <div *ngIf="isRequestInProgress === true">
                <span class="ml-2 text-primary fa fa-spinner fa-spin fa-2x" aria-label="Processing"></span>
            </div>
        </div>
    </div>
</form>