import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Graph } from '../../graph/graph';
import { GraphService } from '../../../service/graph.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Component({
  selector: 'app-display-graph',
  templateUrl: './display-graph.component.html',
  styleUrls: ['./display-graph.component.css']
})
export class DisplayGraphComponent implements OnInit {
  isGraphLoading: boolean;
  csoGraphName: string;
  @Input() set graphName(value: string) {
    if (value != null) {
      this.csoGraphName = value;
      this.displayGraph(value);
    }
  }

  @Input() set redraw(value: boolean) {
    if (this.csoGraphName != null) {
      this.displayGraph(this.csoGraphName);
    }
  }

  constructor(
    private graphService: GraphService,
    private appInsightsService: ApplicationInsights
  ) {
    this.isGraphLoading = false;
  }

  ngOnInit() { }

  displayGraph(value: string): void {
    this.isGraphLoading = true;
    this.graphService.getCSOFlowGraphs(value).subscribe(
      (flowGraph: Graph) => {
        if (flowGraph != null) {
          const displayElement = document.getElementById('graphDiv');
          flowGraph.setHTMLElement(displayElement);
          flowGraph.initializeGraph();
          flowGraph.redrawGraph();
          this.isGraphLoading = false;
        } else {
          this.isGraphLoading = false;
        }
      },
      (error: HttpErrorResponse) => {
        this.isGraphLoading = false;
        console.error('error in CSO Flow Graph for graph name: ', this.graphName, error);
        this.appInsightsService.trackTrace({ message: 'error in CSO Flow Graph for graph name: ' + this.graphName});
      }
    );
  }

}
