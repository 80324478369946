<!-- service tree selection. -->
<div class="card bg-transparent mx-2">
    <div class="card-header">
      <div class="border border-dark rounded ml-1 mt-2" id="Treeview" style="width: 49rem;">
        <!-- Treeview -->
        <app-tree-view [loadProcess]="true" (valueChange)="serviceTreeHandler($event)"></app-tree-view>
      </div>
    </div>
  </div>

<!-- table label and search box. -->
<div class="d-flex flex-rows mx-2 mt-3">
    <h5 class="display-5 ml-2">Template Variable List for Process: {{ processName }}</h5>
    <button class="btn btn-sm btn-link ml-auto" aria-label="add new template variable" type="button"
        (click)="addTemplateVariable()">
        <i class="fa fa-plus" aria-hidden="true"></i>
        Add New Variable
    </button>
    <div class="search">
        <span class="fa fa-search"></span>
        <input type="text" [formControl]="search" placeholder="Search" style="width: 14rem;"
            aria-label="Search Component">
    </div>
</div>

<!-- loader. -->
<div *ngIf="isListLoading" class="text-center mt-5">
    <span class="text-info fa fa-spinner fa-spin fa-2x" style="font-size:75px;" aria-label="Loading"
        role="progressbar"></span>
</div>

<!-- template variable list. -->
<div *ngIf="!isListLoading" class="mx-2 mt-2">
    <app-display-table-v2 [tableInput]="tableData.asObservable()" [tableOptions]="tableOptions"
        [searchTable]="tableSearch.asObservable()" (rowEditEvent)="updateTemplateVariable($event)">
    </app-display-table-v2>

    <!-- Back Button. -->
    <button class="btn btn-sm secondary-button ml-1" aria-label="back to rule page" type="button" (click)="navigateToRulePage()">
        Back
    </button>
</div>