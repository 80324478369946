import { QoSRouterPage } from '../common/constants';

export class Operation {
  id: number;
  name: string;
  type: OperationType;
  latencyThreshold: number;
  critical: string;
  customers: string;
  process: string;
  lastModifiedBy: string;
  lastModifiedDate: Date;
}

export class ServiceComponent {
  componentOid: string;
  componentName: string;
  operations: Array<Operation>;
}

export class ServiceComponentInput {
  teamGroupOid: string;
  components: Array<ServiceComponent>;
}

export class Service {
  serviceOid: string;
  serviceName: string;
  operations: Array<Operation>;
}

export class ServiceInput {
  teamGroupOid: string;
  services: Array<Service>;
}

export class IKeyMapping {
  serviceOid: string;
  teamGroupOid: string;
  iKeys: Array<string>;
}

export enum OperationType {
  Request = 'Request',
  RemoteDependency = 'RemoteDependency'
}

export interface QosRoutingState {
  previousUrl: QoSRouterPage;
  teamGroupName: string;
  selectedServiceId: string;
  serviceInput: ServiceInput;
}

export class IKeyServiceMapping {
  serviceOid: string;
  iKey: string;
}
