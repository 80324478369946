<div class='container-fluid pt-2'>
  <h5 class="display-5 mt-2 ml-2">QoS Operation Onboarding</h5>
  <hr>
  <!-- Add service tree structure -->
  <div class="d-flex flex-column">
    <div class="card bg-transparent mx-2">
      <div class="card-header">
        <h6>Service Tree Selection</h6>
        <div class="d-flex flex-rows">
          <app-service-tree [trackServiceTree]=null [loadLevels]="levelsToLoadServiceTree"
            (teamGroupIdEvent)="teamGroupIdEventHandler($event)"
            (teamGroupNameEvent)="teamGroupNameEventHandler($event)">
          </app-service-tree>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex flex-column">
    <div *ngIf="isTeamGroupSelected" class="card bg-transparent mx-2">
      <div class="card-header">
        <div class="d-flex flex-rows">
          <!-- Add New Operations Button -->
          <button class="btn btn-sm info-button border border-secondary" type="button" id="addNewOperationsButton"
            (click)="addOperations()" style="width: 10rem;" [disabled]="!loadedOperations">
            Add New Operations
          </button>
          <button class="btn btn-sm info-button border border-secondary ml-2" type="button" (click)="manageIkey()"
            style="width: 10rem;" [disabled]="!loadedOperations">
            Manage iKeys
          </button>
          <!-- Search by Oid or Name -->
          <div class="ml-auto search">
            <span class="fa fa-search"></span>
            <input type="text" [formControl]="searchBox" placeholder="Search by Oid/Name" style="width: 20rem;"
              aria-label="Search Operation">
          </div>
        </div>
      </div>
    </div>
    <br />
    <div>
      <div *ngIf="isTeamGroupSelected && !loadedOperations" class="text-center mt-5">
        <span class="info-text fa fa-spinner fa-spin fa-2x" style="font-size:75px;" aria-label="Loading"></span>
      </div>
      <ng-container *ngIf="loadedOperations">
        <div ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs" role="tablist" aria-label="tablist">
          <div *ngFor="let id of tabs" [ngbNavItem]="id">
            <a ngbNavLink (click)="setTabContent(id)" class="link-text">
              {{ id }}
            </a>
            <ng-template ngbNavContent>
              <div class="table-responsive">
                <table class="table table-bordered table-striped">
                  <thead class="thead-dark">
                    <tr>
                      <th id="h1" class="text-center" scope="col" style="width: 21rem;">ServiceOid</th>
                      <th id="h2" class="text-center" scope="col">Service Name</th>
                      <th id="h3" class="text-center" scope="col">Operation Name</th>
                      <th id="h4" class="text-center" scope="col" style="width: 13rem;">Latency Threshold (ms)</th>
                      <th id="h5" class="text-center" scope="col" style="width: 3rem;">Critical</th>
                      <th id="h6" class="text-center" scope="col" style="width: 3rem;">Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- should iterate through filtered list and then iterate through operations  -->
                    <ng-template ngFor let-component [ngForOf]="filteredData" let-componentIndex="index">
                      <ng-template ngFor let-operation [ngForOf]="component.operations" let-operationIndex="index">
                        <tr>
                          <td headers="h1">{{component.serviceOid}}</td>
                          <td headers="h2">{{component.serviceName}}</td>
                          <td headers="h3">{{operation.name}}</td>
                          <td headers="h4" class="text-center">{{operation.latencyThreshold/10000}}</td>
                          <td headers="h5" class="text-center">{{operation.critical}}</td>
                          <td headers="h6" class="text-center"><a class="tab-click" role='button' [routerLink]="[]" aria-label="Edit Operation"
                              (click)="updateOperation(component, operation)"><span class="fa fa-edit"></span></a></td>
                        </tr>
                      </ng-template>
                    </ng-template>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </div>
        </div>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </ng-container>
    </div>
  </div>
</div>
