import { Component, OnInit, AfterViewInit, Input, Inject } from '@angular/core';
import { ScenarioPerformance } from '../model/scenarioStatus';
import { NgbModalErrorComponent } from '../../modal/ngb-modal-error.component';
import { ScenarioHealthService } from '../service/scenario-health.service';
import { UserDataService } from '../../service/user-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { APP_CONFIG } from '../../common/constants';
import { UntypedFormControl } from '@angular/forms';
import { DetailsUpdateComponent } from '../details-update/details-update.component';
import { DetailsDisplayComponent } from '../details-display/details-display.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalRef } from '../../model/common.model';
import { AppConfig } from '../../model/app-config.model';
import { DetailsComponentInput } from '../model/scenario';

@Component({
  selector: 'app-scenario-health-v2',
  templateUrl: './scenario-health-v2.component.html',
  styleUrls: ['./scenario-health-v2.component.scss']
})
export class ScenarioHealthV2Component implements OnInit, AfterViewInit {

  /* Description
    This component is the parent component of sc-scenario-health and finance-scenario-health components
  */

  weekStart: Date;
  weekEnd: Date;
  scenarios: ScenarioPerformance[];
  viewingWeek: number;
  editable: boolean;
  canViewAutomatedValue: boolean;
  loading: string;
  wikiUrl: string;
  reportUrl: string;
  healthMap: Map<string, number[]>;
  healthTargetMap: Map<string, number[]>;
  icmUrlPrefix: string;
  showLowerBound: boolean;
  showTargetAsterix: number;
  targetTitle: string;

  // All up health dial
  allUpHealthDialName: string;
  allUpHealthDialScore: number;
  allUpHealthDialLB: number;
  allUpHealthDialUB: number;
  allUpHealthDialColor: string;

  public options = {
    hasNeedle: true,
    needleColor: 'gray',
    arcColors: ['#dc3545', '#ffc107', '#208836'],
    arcDelimiters: [93, 95],
    rangeLabel: ['0', '100'],
    needleStartValue: 50,
  };

  dashboardId: number;
  scoreCardName: string;
  showAutomatedValueToggle = new UntypedFormControl(false);
  showAutomatedValue = false;                         // toggle value is false by default

  constructor(private scenarioHealthService: ScenarioHealthService, private router: Router,
    private userDataService: UserDataService, private modalService: NgbModal, @Inject(APP_CONFIG) appConfig: AppConfig) {
    this.viewingWeek = this.scenarioHealthService.getCurrentViewingWeek();
    this.weekStart = this.scenarioHealthService.getViewingWeekStartDate(this.viewingWeek);
    this.weekEnd = this.scenarioHealthService.getViewingWeekEndDate(this.viewingWeek);

    const scoreCardConfig = appConfig.scoreCards.find(config => router.url.endsWith(config.route));
    this.dashboardId = scoreCardConfig.id;
    this.scoreCardName = scoreCardConfig.title;
    this.wikiUrl = scoreCardConfig.wikiUrl;
    this.allUpHealthDialName = scoreCardConfig.allupName;
    this.reportUrl = scoreCardConfig.reportUrl;
    this.showLowerBound = scoreCardConfig.showLowerBound;
    this.showTargetAsterix = scoreCardConfig.showTargetAsterix;
    this.targetTitle = scoreCardConfig.targetTitle;

    this.icmUrlPrefix = appConfig.icmUrlPrefix;

    // check if user has access to update
    this.userDataService.checkUserAccess(this.router.url, 'Update', '', this.dashboardId.toString()).subscribe((response: boolean) => {
      this.editable = response;
    });
  }

  ngOnInit() {
    this.loadData();

    // check if user has access to view AutomatedValues
    this.userDataService.checkUserAccess('DashboardAutomatedValues', 'Read', '', this.dashboardId.toString()).subscribe((response: boolean) => {
      this.canViewAutomatedValue = response;
    },
    (error: Error) => {
      this.showError(error);
    },
    () => {
      if (this.canViewAutomatedValue) {
        this.showAutomatedValueToggle.setValue(true);
        this.showAutomatedValue = true;
      }
    });

    this.showAutomatedValueToggle.valueChanges.subscribe((val: boolean) => {
      this.showAutomatedValue = val;
    });
  }

  ngAfterViewInit() {
    const focusElement: HTMLElement = document.getElementById('page-title') as HTMLElement;
    focusElement.focus();
  }

  private loadData() {
    // this.scenarios = null;
    this.loading = "Loading...";
    this.scenarioHealthService.GetPerformance(this.dashboardId, new Date(this.weekStart), new Date(this.weekEnd)).subscribe((r) => {
      this.scenarios = r;
      this.scenarioHealthService.updateReportLinksToAbsoluteUrl(this.scenarios);
      this.loadHealthCharts();
      this.SetColorForScoreColumns();
      this.loading = "";
    }, (error: Error) => {
      this.showError(error);
      this.loading = "";
    });
  }

  SetColorForScoreColumns() {
    for (let i = 0; i < this.scenarios.length; i++) {
      const scenario = this.scenarios[i];
      // Set color for Auto Score
      if (scenario.automatedValue === null || scenario.automatedValue === -1) {
        scenario.autoScoreColor = "white";
      } else if (this.showLowerBound && scenario.scenario.intermediateTarget > 0 ? 
        scenario.automatedValue < scenario.scenario.intermediateTarget : scenario.automatedValue < scenario.scenario.targetPerformance) {
          scenario.autoScoreColor = "red";
      } else if (scenario.automatedValue >= scenario.scenario.targetPerformance) {
        scenario.autoScoreColor = "green";
      } else if (this.showLowerBound && scenario.scenario.intermediateTarget > 0 && scenario.automatedValue >= scenario.scenario.intermediateTarget 
        && scenario.automatedValue < scenario.scenario.targetPerformance) {
        scenario.autoScoreColor = "yellow";
      }

      // Set Color for Actual Score
      if (this.showLowerBound && scenario.scenario.intermediateTarget > 0 ? scenario.performance < scenario.scenario.intermediateTarget : 
        scenario.performance < scenario.scenario.targetPerformance) {
        scenario.actualScoreColor = "red";
      } else if (scenario.performance >= scenario.scenario.targetPerformance) {
        scenario.actualScoreColor = "green";
      } else if (this.showLowerBound && scenario.scenario.intermediateTarget > 0 && scenario.performance >= scenario.scenario.intermediateTarget && 
        scenario.performance < scenario.scenario.targetPerformance) {
        scenario.actualScoreColor = "yellow";
      }
    }
  }

  loadHealthCharts() {
    this.healthMap = new Map<string, number[]>();
    this.healthTargetMap = new Map<string, number[]>();
    for (let i = 0; i < this.scenarios.length; i++) {
      const scenario = this.scenarios[i];
      if (this.healthMap.has(scenario.scenario.area)) {
        // actual health scores
        const performanceValues = this.healthMap.get(scenario.scenario.area)!;
        performanceValues.push(scenario.performance);
        this.healthMap.set(scenario.scenario.area, performanceValues);
        // target health scores
        let targetValues = this.healthTargetMap.get(scenario.scenario.area)!;
        targetValues[0] += scenario.scenario.targetPerformance;
        targetValues[1] += scenario.scenario.intermediateTarget;
        this.healthTargetMap.set(scenario.scenario.area, targetValues);
      } else {
        // actual health scores
        const performanceValues = new Array<number>();
        performanceValues.push(scenario.performance);
        this.healthMap.set(scenario.scenario.area, performanceValues);
        // target health scores
        this.healthTargetMap.set(scenario.scenario.area, [scenario.scenario.targetPerformance, scenario.scenario.intermediateTarget]);
      }
    }
    this.healthMap.forEach((value: number[], key: string) => {
      const total = value.reduce((sum, current) => sum + current, 0);
      const ubAvg = Math.round(this.healthTargetMap.get(key)[0]! / value.length * 100) / 100;
      const lbAvg = Math.round(this.healthTargetMap.get(key)[1]! / value.length * 100) / 100;
      // value[0] holds avg of actual score
      // value[1] holds avg of Upper Bound/Target Score
      // value[2] holds avg of Lower Bound
      this.healthMap.set(key, [Math.round(total / value.length * 100) / 100, ubAvg, lbAvg]);
    });

    // Update All Up health Dial 
    this.allUpHealthDialScore = this.getScenarioHealth();
    this.allUpHealthDialLB = this.getLowerBoundAvg();
    this.allUpHealthDialUB = this.getUpperBoundAvg();    
    if (this.showLowerBound && this.allUpHealthDialLB > 0 ? this.allUpHealthDialScore < this.allUpHealthDialLB : this.allUpHealthDialScore < this.allUpHealthDialUB) {
      this.allUpHealthDialColor = "red";
    } else if (this.allUpHealthDialScore >= this.allUpHealthDialUB) {
      this.allUpHealthDialColor = "green";
    } else if (this.showLowerBound && this.allUpHealthDialLB > 0 && this.allUpHealthDialScore >= this.allUpHealthDialLB && this.allUpHealthDialScore < this.allUpHealthDialUB) {
      this.allUpHealthDialColor = "yellow";
    }
  }

  // keyvalue pipe when applied to healthMap in the html is sorting the keys alphabetically by default which we do not want.
  // Below code is added in order to preserve the order in the hashmap
  asIsOrder(a: {[key: string]: number[]}, b: {[key: string]: number[]}) {
    return 1;
  }

  changeWeek(value: number) {
    this.viewingWeek = this.viewingWeek + value;
    this.weekStart = this.scenarioHealthService.getViewingWeekStartDate(this.viewingWeek);
    this.weekEnd = this.scenarioHealthService.getViewingWeekEndDate(this.viewingWeek);
    this.loadData();
  }

  updateWeeklyInfo(scenarioId: number, scenario: ScenarioPerformance, weekStart: Date) {
    this.scenarioHealthService.UpdateWeeklyPerformance(scenarioId, scenario, weekStart)
    .subscribe(() => { }, (error: Error) => this.showError(error));
  }

  getScenarioHealth(): number {
    if (this.scenarios) {
      return Math.round(this.scenarios.reduce((x, y) => (x + y.performance), 0) / this.scenarios.length * 100) / 100;
    } else {
      return 0;
    }
  }

  getUpperBoundAvg(): number {
    if (this.scenarios) {
      return Math.round(this.scenarios.reduce((x, y) => (x + y.scenario.targetPerformance), 0) / this.scenarios.length * 100) / 100;
    } else {
      return 0;
    }
  }

  getLowerBoundAvg(): number {
    if (this.scenarios) {
      return Math.round(this.scenarios.reduce((x, y) => (x + y.scenario.intermediateTarget), 0) / this.scenarios.length * 100) / 100;
    } else {
      return 0;
    }
  }

  showError(error: Error) {
    if (error.message === "Cannot send request to registered endpoint if the user is not authenticated") {
      const modal = this.modalService.open(NgbModalErrorComponent);
      (modal.componentInstance as ModalRef).message = "Login expired refresh the browser to continue";
    } else {
      this.modalService.open(NgbModalErrorComponent);
    }
  }

  // open modal with single entry
  updateDetails(scenarioPerf: ScenarioPerformance) {
    if (this.editable) {
      const updateModal = this.modalService.open(DetailsUpdateComponent);
      (updateModal.componentInstance as DetailsComponentInput).scenarioPerf = scenarioPerf;
      (updateModal.componentInstance as DetailsComponentInput).weekStart  = this.weekStart;

      if (updateModal) {
        updateModal.result
          .then((updatedScenarioPerf: ScenarioPerformance) => {
            scenarioPerf.performance = updatedScenarioPerf.performance;
            scenarioPerf.comments = updatedScenarioPerf.comments;
            scenarioPerf.icm = updatedScenarioPerf.icm;
            scenarioPerf.updated = { by: "you", on: new Date().toISOString().split('.')[0] };

            // recompute health dials
            this.loadHealthCharts();
            this.SetColorForScoreColumns();
          })
          // no processing necessary, when dismiss or cancel button used, the error callback is called.
          .catch(() => { });
      }
    } else {
      // launch readDetailsModal
      const readModal = this.modalService.open(DetailsDisplayComponent);
      (readModal.componentInstance as DetailsComponentInput).scenarioPerf = scenarioPerf;
      (readModal.componentInstance as DetailsComponentInput).weekStart = this.weekStart;
    }
  }
}
