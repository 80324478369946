import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import {
  DashboardInput,
  PowerBINavContentPanePosition,
  ReportInput,
} from "../model/power-bi.model";
import { Subscription, timer } from "rxjs";
import * as pbi from "powerbi-client";
import { PowerBIService } from "../service/power-bi.service";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { mergeMap } from "rxjs/operators";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { constants } from "../../common/constants";

@Component({
  selector: "app-power-bi-input",
  templateUrl: "./power-bi-input.component.html",
  styleUrls: ["./power-bi-input.component.css"],
})
export class PowerBiInputComponent implements OnDestroy {
  dashboardInputBase: DashboardInput;
  reportInputBase: ReportInput;
  renewToken$: Subscription;
  height: string;
  globalEmbedURL: string;

  @Input() filterValue: string;

  @Input() set reportInput(value: ReportInput) {
    if (value != null) {
      this.reportInputBase = value;
      this.height = this.reportInputBase.hasOwnProperty("height")
        ? this.reportInputBase.height
        : "";
      this.powerBIService.getAccessToken().subscribe((accessToken) => {
        this.display(accessToken, constants.Report);
      });
    }
  }

  @Input() set dashboardInput(value: DashboardInput) {
    this.dashboardInputBase = value;
    this.height = this.dashboardInputBase.hasOwnProperty("height")
      ? this.dashboardInputBase.height
      : "";
    if (this.dashboardInputBase != null) {
      this.powerBIService.getAccessToken().subscribe((accessToken) => {
        this.display(accessToken, constants.Dashboard);
      });
    }
  }

  private powerBiInput: pbi.Embed;
  private powerbi: pbi.service.Service;

  constructor(
    private powerBIService: PowerBIService,
    private appInsightsService: ApplicationInsights
  ) {
    this.powerbi = this.powerBIService.getPowerBIService();
    this.renewToken$ = null;
  }

  ngOnDestroy() {
    if (this.renewToken$ != null) {
      this.renewToken$.unsubscribe();
    }
  }

  private display(accessToken: string, inputType: string) {
    let uniqueId: string;
    let navContentPaneEnabled: boolean;
    let navContentPanePosition: string;
    let embedUrl: string;

    if (inputType === constants.Dashboard) {
      uniqueId = this.dashboardInputBase.dashboardId;
      embedUrl =
        constants.DashboardEmbedUrl +
        "dashboardId=" +
        this.dashboardInputBase.dashboardId +
        "&groupId=" +
        this.dashboardInputBase.groupId;
      navContentPaneEnabled =
        this.dashboardInputBase.navContentPaneEnabled ?? false;
      navContentPanePosition =
        this.dashboardInputBase.navContentPanePosition ??
        PowerBINavContentPanePosition.Bottom;
    } else if (inputType === constants.Report) {
      uniqueId = this.reportInputBase.reportId;
      embedUrl =
        constants.ReportEmbedUrl +
        "reportId=" +
        this.reportInputBase.reportId +
        "&groupId=" +
        this.reportInputBase.groupId;
      navContentPaneEnabled =
        this.reportInputBase.navContentPaneEnabled ?? false;
      navContentPanePosition =
        this.reportInputBase.navContentPanePosition ??
        PowerBINavContentPanePosition.Bottom;
    }
    this.globalEmbedURL = embedUrl;
    this.appInsightsService.trackTrace({
      message: `powerbi-input-component: embed url created ${embedUrl}`,
    });

    // hiding the filter pane.
    const powerBISettings: pbi.models.ISettings = {
      filterPaneEnabled: this.reportInputBase.filterPaneEnabled ?? false,
      panes: {
        pageNavigation: {
          visible: navContentPaneEnabled,
          position:
            navContentPanePosition === PowerBINavContentPanePosition.Bottom
              ? pbi.models.PageNavigationPosition.Bottom
              : pbi.models.PageNavigationPosition.Left,
        },
      },
    };

    // create input config.
    const powerBIInputConfig: pbi.IEmbedConfiguration = {
      type: inputType,
      uniqueId,
      embedUrl,
      accessToken,
      settings: powerBISettings,
    };

    if (
      inputType === constants.Report &&
      this.reportInputBase.pageName != null
    ) {
      powerBIInputConfig["pageName"] = this.reportInputBase.pageName;
      if (this.reportInputBase.filterPaneEnabled) {
        // add filter for report
        const filter: pbi.models.IBasicFilter = {
          $schema: "http://powerbi.com/product/schema#advanced",
          target: {
            table: this.reportInputBase.filterTable,
            column: this.reportInputBase.filterColumn,
          },
          filterType: pbi.models.FilterType.Basic,
          operator: "In",
          values: [this.filterValue],
        };
        powerBIInputConfig["filters"] = [filter];
      }
    }
    // get input container.
    const inputDivElement = document.getElementById("powerBIInputDiv");

    // embed the input.
    this.powerBiInput = this.powerbi.embed(inputDivElement, powerBIInputConfig);
    this.appInsightsService.trackTrace({
      message: `powerbi-input-component: powerbi input embedded`,
    });

    // setup token renewal.
    this.renewToken(accessToken);
  }

  private renewToken(accessToken: string): void {
    // get token expiration time.
    const token = jwt_decode<JwtPayload>(accessToken);

    // 5 mins before token expiration time.
    const tokenExpirationTime =
      (token["exp"] - (Math.floor(Date.now() / 1000) - 5 * 60)) * 1000;
    // validity of issued token.
    const refreshInterval = (token["exp"] - token["iat"]) * 1000;
    this.renewToken$ = timer(tokenExpirationTime, refreshInterval)
      .pipe(mergeMap(() => this.powerBIService.getAccessToken()))
      .subscribe((renewedAccessToken: string) => {
        this.powerBiInput.setAccessToken(renewedAccessToken);
        this.appInsightsService.trackTrace({
          message: `powerbi-input-component: access token updated`,
        });
      });
  }
}
