export class EventHandler {
  ehType: string;
  requiredArgs: EventHandlerArg[];
  optionalArgs: EventHandlerArg[];

  constructor()

  constructor(ehType: string, requiredArgs: EventHandlerArg[], optionalArgs: EventHandlerArg[])

  constructor(ehType?: string, requiredArgs?: EventHandlerArg[], optionalArgs?: EventHandlerArg[]) {
    this.ehType = ehType;
    this.requiredArgs = requiredArgs;
    this.optionalArgs = optionalArgs;
  }
}

export class EventHandlerArg {
  name: string;
  displayName: string;
  ehType: string;
  canUpdate: boolean;
  value: any;

  constructor(name: string, displayName: string, ehType: string, canUpdate: boolean, value?: any) {
    this.name = name;
    this.displayName = displayName;
    this.ehType = ehType;
    this.canUpdate = canUpdate;
    this.value = value;
  }
}
