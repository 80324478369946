<div class="modal-header">
    <h4 class="modal-title">Expanded Rule Query</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <textarea title="Direct query editor" aria-label="Direct query editor" class="rounded required" rows="6"
        [formControl]="queryForm" readonly style="width: 28rem;">
    </textarea>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" ngbAutofocus (click)="activeModal.close()">Ok</button>
</div>