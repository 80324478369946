import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { APP_CONFIG } from '../../common/constants';
import { AppConfig } from '../../model/app-config.model';
import { CacheService } from '../../service/cache.service';
import { StorageService } from '../../service/storage.service';
import { DataSource, DataSourceRequest, DataSourceTypeDetails } from '../model/data-source.model';
import { DataSourceService } from '../service/data-source.service';
import { MasterDataService } from '../service/master-data.service';

@Component({
  selector: 'app-modify-data-source',
  templateUrl: './modify-data-source.component.html',
  styleUrls: ['./modify-data-source.component.scss'],
  providers: [DataSourceService]
})
export class ModifyDataSourceComponent implements OnInit, OnDestroy {

  @Input() teamGroupId: string;
  @Input() dataSourceId: number;
  @Input() isUpdate: boolean;

  dataSourceForm = new UntypedFormGroup({
    dataSourceName: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl(''),
    type: new UntypedFormControl('', [Validators.required]),
    file: new UntypedFormControl('', [this.fileControlValidator()]),
    connectionString: new UntypedFormGroup({})
  });

  dataSourceTypes: DataSourceTypeDetails[];
  dataSource: DataSource;
  selectedFile: File;
  selectedType: DataSourceTypeDetails;
  isImportedDataSource: boolean;

  showError: boolean;
  errorMessage: string;
  isNewFileSelected: boolean;

  isLoading: boolean;
  isDataSourceLoading: boolean;
  isDataSourceTypeLoading: boolean;

  subscription: Subscription;


  constructor(
    private activeModal: NgbActiveModal,
    private masterDataService: MasterDataService,
    private storageService: StorageService,
    private dataSourceService: DataSourceService,
    private cacheService: CacheService,
    private formBuilder: UntypedFormBuilder,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
  ) {
    this.subscription = new Subscription();
    this.isLoading = false;
    this.isDataSourceLoading = false;
    this.isDataSourceTypeLoading = false;
    this.isNewFileSelected = false;
    this.showError = false;
    this.errorMessage = "";
    this.isImportedDataSource = false;
    this.selectedFile = null;
  }

  ngOnInit(): void {
    // init data source types
    this.isDataSourceTypeLoading = true;
    const dataSourceTypeSubscription = this.masterDataService.getDataSourceTypes(true).subscribe(
      (dataSourceTypes: DataSourceTypeDetails[]) => this.dataSourceTypes = dataSourceTypes,
      (error: HttpErrorResponse) => {
        console.error('Error occurred while fetching data source types', error);
        this.setError(error.error.value);
      }
    ).add(() => this.isDataSourceTypeLoading = false);
    this.subscription.add(dataSourceTypeSubscription);

    // load existing data source configuration if needed
    if (this.isUpdate) {
      this.isDataSourceLoading = true;
      const dataSourceSubscription = this.masterDataService.getDataSourceById(this.dataSourceId).subscribe(
        (dataSource: DataSource) => {
          this.dataSource = dataSource;
          this.selectedType = dataSource.typeDetails;

          // load values into form group
          this.dataSourceForm.controls.dataSourceName.setValue(dataSource.name);
          this.dataSourceForm.controls.description.setValue(dataSource.description);
          this.dataSourceForm.controls.type.setValue(dataSource.type);
          this.dataSourceForm.controls.type.disable();
          this.createConnectionStringFormGroupFromParameters(dataSource.typeDetails, dataSource.connectionString);
        },
        (error: HttpErrorResponse) => {
          console.error('Error occurred while fetching new data source', error);
          this.setError(error.error.value);
        }
      ).add(() => this.isDataSourceLoading = false);
      this.subscription.add(dataSourceSubscription);
    }

    // set bindings
    this.onDataSourceTypeChange();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  cancel(): void {
    this.activeModal.dismiss();
  }

  onDataSourceTypeChange(): void {
    const dataSourceTypeSubscription = this.dataSourceForm.get('type').valueChanges.subscribe(
      (val: string) => {
        if (this.dataSourceTypes != null) {
          this.clearError();

          const dataType = this.dataSourceTypes.find(x => (x.type === val));
          this.selectedType = dataType;
          this.isImportedDataSource = dataType.isImported;

          if (dataType.isImported) {
            this.dataSourceForm.get('file').enable();
            this.dataSourceForm.setControl('connectionString', new UntypedFormGroup({}));
          } else {
            this.dataSourceForm.get('file').disable();
            this.dataSourceForm.get("file").setValue(null);
            this.createConnectionStringFormGroupFromParameters(dataType);
          }
        }
      });

    this.subscription.add(dataSourceTypeSubscription);
  }

  saveDataSource(): void {
    this.isLoading = true;
    let createObs: Observable<DataSource>;

    // set up create observable based on if import is required
    if (this.selectedType.isImported) {
      const importObs = this.storageService.importFile(this.teamGroupId, this.selectedFile);
      createObs = importObs.pipe(
        switchMap((url: string) => this.createHelper(url))                    // imported: provides url of import
      );
    } else {
      const connectionString = this.buildConnectionStringFromFormGroup();
      createObs = this.createHelper(connectionString);                        // not imported: created from form group entries
    }

    // subscribe
    const createSubscription = createObs.subscribe(
      () => {
        this.masterDataService.clearDataSourceCache();
        this.activeModal.close();
      },
      (error: HttpErrorResponse) => {
        console.error('Error occurred while creating new Datasource', error);
        this.setError(error.error.value);
      }
    ).add(() => this.isLoading = false);

    this.subscription.add(createSubscription);
  }

  createHelper(connectionString: string): Observable<DataSource> {
    const request = new DataSourceRequest();

    request.name = this.dataSourceForm.controls.dataSourceName.value as string;
    request.description = this.dataSourceForm.controls.description.value as string;
    request.ownerGroupId = this.teamGroupId;
    request.type = this.dataSourceForm.controls.type.value as string;
    request.connectionString = connectionString;

    return this.dataSourceService.addDataSource(request);
  }

  updateDataSource(): void {
    this.isLoading = true;
    let updateObs: Observable<DataSource>;

    // set up update observable based on isImported and whether if a new file needs to be uploaded
    if (this.selectedType.isImported) {
      if (this.selectedFile !== null) {
        let importObs = this.storageService.importFile(this.teamGroupId, this.selectedFile);
        updateObs = importObs.pipe(
          switchMap((url: string) => this.updateHelper(this.dataSourceId, url))               // imported: provides url of new import
        );
      } else {
        updateObs = this.updateHelper(this.dataSourceId, this.dataSource.connectionString);   // imported: provides url of existing import
      }
    } else {
      let connectionString = this.buildConnectionStringFromFormGroup();
      updateObs = this.updateHelper(this.dataSourceId, connectionString);                     // not imported: created from form group entries
    }
    
    // subscribe
    var updateSubscription = updateObs.subscribe(
      () => {
        this.masterDataService.clearDataSourceCache();
        this.activeModal.close();
      },
      (error: HttpErrorResponse) => {
        console.error('Error occurred while creating new Datasource', error);
        this.setError(error.error.value);
      }
    ).add(() => this.isLoading = false);
        
    this.subscription.add(updateSubscription);
  }

  updateHelper(dataSourceId: number, connectionString: string): Observable<DataSource> {
    const request = new DataSourceRequest();

    request.name = this.dataSourceForm.controls.dataSourceName.value as string;
    request.description = this.dataSourceForm.controls.description.value as string;
    request.connectionString = connectionString;
    
    // As of now type and ownerGroup id is not allowed to update from UI ,setting up same values which we have
    request.ownerGroupId = this.dataSource.ownerGroupId.toString();
    request.type = this.dataSource.type;

    return this.dataSourceService.updateDataSource(dataSourceId, request);
  }

  onFileSelection(files: File[]) {
    if (files[0].size < 2000000000) {
      this.selectedFile = files[0];
      this.showError = false;
    } else {
      this.setError("File size should not exceed 2 GB");
      this.dataSourceForm.get('file').setValue(null);
      this.dataSourceForm.get('file').setErrors({ 'incorrect': true });
    }
    if (this.isUpdate) {
      this.isNewFileSelected = true;
    }
  }

  fileControlValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (this.isUpdate) {
        return null;
      } else {
        const val = control.value as string;
        if (val) {
          return null;
        } else {
          return { isFileRequired: true };
        }
      }
    };
  }

  getFormGroupKeys(f: UntypedFormGroup): string[] {
    var keys = Object.keys(f?.controls ?? {});
    return keys;
  }

  createConnectionStringFormGroupFromParameters(dataType: DataSourceTypeDetails, currentConnectionString: string = null) {
    // parse connection string
    var connStrValues: { [key: string]: string } = {};
    if (currentConnectionString !== null) {
      let values = currentConnectionString.split(";");
      values.forEach((p: string) => {
        let split = p.split("=");
        let name = split[0];
        let value = split[1];
        connStrValues[name] = value;
      })
    }

    // create and populate form controls
    var connStrParams = dataType.connectionStringParameters?.split(",") ?? [];
    var connStrFormControls: { [key: string]: UntypedFormControl } = {};
    for (var p of connStrParams) {
      let value = connStrValues[p] ?? '';
      connStrFormControls[p] = new UntypedFormControl(value, Validators.required);
    }
    var formGroup = this.formBuilder.group(connStrFormControls);
    this.dataSourceForm.setControl('connectionString', formGroup);
  } 

  buildConnectionStringFromFormGroup(): string {
    var formGroup = this.dataSourceForm.get('connectionString') as UntypedFormGroup;
    var paramNames = Object.keys(formGroup.controls);
    
    var connectionString = "";
    paramNames.forEach((p: string) => connectionString += p + "=" + formGroup.controls[p].value + ";");

    return connectionString;
  }

  setError(msg: string) {
    this.showError = true;
    this.errorMessage = msg;
  }

  clearError() {
    this.showError = false;
    this.errorMessage = '';
  }
}
