import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, ReplaySubject, Subject, of, forkJoin } from 'rxjs';
import { TemplateVariableService } from '../service/template-variable.service';
import { map, switchMap } from 'rxjs/operators';
import { TemplateVariable } from '../model/template-variable.model';
import { TableOptions, TableInputV2, RowEditEvent } from '../model/common.model';
import { UntypedFormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlingService } from '../../service/error-handling.service';
import { Process } from '../model/process.model';
import { MasterDataService } from '../service/master-data.service';
import { TreeSelection } from '../../common/model/tree-view.model';
import { TreeviewService } from '../../common/service/treeview.service';
import { TreeDataService } from '../../common/interface/tree-data-service';
import { ServicetreeDataService } from '../../common/service/servicetree-data.service';
import { constants } from '../common/constants';

@Component({
  selector: 'app-template-variable-list',
  templateUrl: './template-variable-list.component.html',
  styleUrls: ['./template-variable-list.component.scss'],
  providers : [
    TreeviewService,
    { provide: TreeDataService, useClass: ServicetreeDataService }
  ]
})
export class TemplateVariableListComponent implements OnInit, OnDestroy {
  processId: number;
  processName: string;
  serviceTreeProcessId: string;

  isListLoading: boolean;

  search = new UntypedFormControl('');

  subscription: Subscription;

  // display table components.
  tableColumns: Array<string>;
  tableOptions: TableOptions;
  tableData: ReplaySubject<TableInputV2>;
  tableSearch: Subject<string>;

  constructor(
    private templateVariableService: TemplateVariableService,
    private route: ActivatedRoute,
    private errorHandling: ErrorHandlingService,
    private router: Router,
    private masterDataService: MasterDataService,
    private treeviewService: TreeviewService,
  ) {
    // seting table output columns.
    this.tableColumns = ['Id', 'Name', 'Description', 'Language Type', 'Edit'];
    this.tableOptions = {
      addEditColumn: true,
      editColumns: ['Edit'],
      enableSearch: true,
      searchColumns: ['Name', 'Description', 'Language Type'],
      columnWidths: [0.15, 1.1, 2, 0.8, 0.25],
      centeredColumns: ['Id']
    };
    this.tableData = new ReplaySubject<TableInputV2>(1);
    this.tableSearch = new Subject<string>();

    this.isListLoading = false;

    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    // Create treeview configuration
    this.treeviewService.setHeight(200);
    this.treeviewService.setTitle("Business Process");

    const routeSubscription = this.route.params.pipe(
      map(params => {
        this.isListLoading = true;
        this.processId = +params['processId'];
        this.serviceTreeProcessId = this.processId.toString();
        this.treeviewService.loadTreeView(true, this.processId.toString());
        return this.processId;
      }),
      switchMap((processId) => {
        return forkJoin([of(processId), this.masterDataService.getBusinessProcessById(processId.toString())]);
      }),
      map((data: [number, Process]) => {
        this.processName = data[1].name;
        return data[0];
      }),
      switchMap((processId: number) => {
        return this.templateVariableService.getTemplateVariableList(processId);
      }),
      map((templateVariableList: Array<TemplateVariable>) => this.transformInput(templateVariableList)),
    ).subscribe((tableRows: Array<Array<string>>) => {
      this.isListLoading = false;
      this.displayTableData(tableRows);
    },
      (error: HttpErrorResponse) => {
        this.isListLoading = false;
        console.error('error message is ', error);
        this.errorHandling.displayError(error);
      }
    );
    this.subscription.add(routeSubscription);

    const searchSubscription = this.search.valueChanges.subscribe(
      (searchValue: string) => {
        this.tableSearch.next(searchValue);
      }
    );
    this.subscription.add(searchSubscription);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.tableData.complete();
    this.tableSearch.complete();
  }

  transformInput(templateVariableList: Array<TemplateVariable>): Array<Array<string>> {
    const tableRows = new Array<Array<string>>();

    templateVariableList.forEach((templateVariable: TemplateVariable) => {
      const templateVariableArray = new Array<string>();
      templateVariableArray.push(templateVariable.id.toString());
      templateVariableArray.push(templateVariable.name);
      templateVariableArray.push(templateVariable.description);
      templateVariableArray.push(templateVariable.languageType);
      templateVariableArray.push(templateVariable.id.toString());

      tableRows.push(templateVariableArray);
    });

    return tableRows;
  }

  displayTableData(tableRows: Array<Array<string>>): void {
    const tableInput = new TableInputV2();
    tableInput.columns = this.tableColumns;
    tableInput.rows = tableRows;

    this.tableData.next(tableInput);
  }

  serviceTreeHandler(serviceTreeSelection: TreeSelection): void {
    const processId = serviceTreeSelection.selectionHierarchy.find(x => x.type === constants.BUSINESS_PROCESS).id;
    this.processId = +processId;
    if (processId != null) {
      const routeUrl = `bam-dashboard/process/${this.processId}/template-variable`;
      this.router.navigate([routeUrl]);
    }
  }

  addTemplateVariable(): void {
    const routeUrl = `bam-dashboard/process/${this.processId}/template-variable/new`;
    this.router.navigate([routeUrl]);
  }

  updateTemplateVariable(input: RowEditEvent): void {
    const routeUrl = `bam-dashboard/process/${this.processId}/template-variable/${+input.value}`;
    this.router.navigate([routeUrl]);
  }

  navigateToRulePage(): void {
    this.router.navigate(['bam-dashboard/rule/all']);
  }
}
