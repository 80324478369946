import { Component, Input } from '@angular/core';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ngb-confirm-modal',
  styleUrls: ['../../style/common-styles.scss'],
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Confirm</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p [innerHTML]="message"></p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark primary-button" (click)="activeModal.close('Close click')">Save</button>
      <button type="button" class="btn btn-outline-dark danger-button" (click)="activeModal.dismiss('Dismissed')">Cancel</button>
    </div>
  `
})
export class NgbModalConfirmComponent {
  @Input() message: string;

  constructor(public activeModal: NgbActiveModal) {}
}
