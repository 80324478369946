import * as moment from 'moment';

export class DisplayTime {

  constructor() { }

  static getDisplayTime(minutes: number): string {
    if (minutes < 240) {
      return moment.duration(minutes, 'minutes').format('m[m]: ss[s]');
    } else if (minutes < 4320) {
      return moment.duration(minutes, 'minutes').format('h[h]: mm[m]');
    } else {
      return moment.duration(minutes, 'minutes').format('d[d]: hh[h]');
    }
  }

  static getFormattedDisplayTime(minutes: number): string {
    return moment.duration(minutes, 'minutes').format('*dd[d]:*hh[h]:*mm[m]:ss[s]');
  }
}
