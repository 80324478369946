import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModalErrorComponent } from '../modal/ngb-modal-error.component';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {

  constructor(
    private modalService: NgbModal,
    private appInsightsService: ApplicationInsights
  ) { }

  public displayError(error: HttpErrorResponse, errorMessage?: string): Promise<any> {
    this.appInsightsService.trackException(error);
    const modal = this.modalService.open(NgbModalErrorComponent);
    if (errorMessage) {
      modal.componentInstance.message = errorMessage;
    } else {
      if (error.status === 400) {
        modal.componentInstance.message = error.error.value;
      }
    }

    return modal.result;
  }
}
