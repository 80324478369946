import { OnInit, Input, Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ScenarioPerformance } from '../model/scenarioStatus';
import { IcMIncident } from '../model/icmIncident';
import { APP_CONFIG } from '../../common/constants';
import { AppConfig } from '../../model/app-config.model';

@Component({
  selector: 'app-details-display',
  templateUrl: './details-display.component.html',
  styleUrls: ['./details-display.component.css']
})
export class DetailsDisplayComponent implements OnInit {
  @Input() scenarioPerf: ScenarioPerformance;
  icmIncidents: Array<IcMIncident>;
  icmUrlPrefix: string;

  updateForm = new UntypedFormGroup({
    score: new UntypedFormControl(''),
    summary: new UntypedFormControl(''),
  });


  constructor(
    private activeModal: NgbActiveModal,
    @Inject(APP_CONFIG) appConfig: AppConfig,
  ) {
    this.icmUrlPrefix = appConfig.icmUrlPrefix ?? "https://portal.microsofticm.com/imp/v3/incidents/details";
  }

  ngOnInit() {
    this.icmIncidents = this.scenarioPerf.icm;
    if (!this.icmIncidents) {
      this.icmIncidents = new Array<IcMIncident>();
    }
    (this.updateForm.get('score') as UntypedFormControl).setValue(this.scenarioPerf.performance);
    (this.updateForm.get('summary') as UntypedFormControl).setValue(this.scenarioPerf.comments);
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }
}
