import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { DistributionPath, ProcessPath } from '../../model/graph.model';
import { BarChartApp, InputData } from '@microsoft/d3-barchart-react';
import { Subscription, Observable, of, forkJoin } from 'rxjs';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PathTaggingComponent } from '../../path-tagging/path-tagging.component';
import { PathTagInput, PathTags } from '../../model/path-tags.model';
import { DisplayGraphService } from '../../service/display-graph.service';
import { ProcessService } from '../../service/process.service';
import { mergeMap } from 'rxjs/operators';
import { GraphMap } from '../graph/graph-map';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlingService } from '../../../service/error-handling.service';

@Component({
  selector: 'app-event-sequences',
  templateUrl: './event-sequences.component.html',
  styleUrls: ['./event-sequences.component.css', '../../../../style/common-styles.scss'],
  providers: [ProcessService]
})
export class EventSequencesComponent implements OnInit, OnDestroy {

  @Input() distributionPaths: Observable<Array<DistributionPath>>;
  @Output() selectedPathIdEvent: EventEmitter<string>;
  @Output() clearPathEvent: EventEmitter<any>;
  @ViewChild('barChartDiv') private barChartElement: ElementRef<HTMLElement>;

  selectedPathId: string;
  isLoading: boolean;

  private barChart: BarChartApp;
  private allSubscriptions: Subscription;
  private processId: string;
  private graphMap: GraphMap;
  private pathIdToSequenceMap: Map<string, string>;
  private paths: Array<DistributionPath>;

  constructor(
    private modalService: NgbModal,
    private appInsights: ApplicationInsights,
    private processService: ProcessService,
    private displayGraphService: DisplayGraphService,
    private errorService: ErrorHandlingService
  ) {
    this.selectedPathIdEvent = new EventEmitter<string>();
    this.clearPathEvent = new EventEmitter<any>();
    this.selectedPathId = '';
    this.allSubscriptions = new Subscription();
    this.pathIdToSequenceMap = new Map<string, string>();
    this.isLoading = false;
  }

  ngOnInit() {
    this.processId = this.displayGraphService.getProcessId();
    this.graphMap = this.displayGraphService.getProcessMap();
    const graphPaths = this.graphMap.getGraphPaths();
    this.pathIdToSequenceMap = this.processService.getPathIdToSequenceMap(graphPaths);

    const pathTags$ = this.processService.getProcessTagMap(this.processId);
    this.isLoading = true;
    const pathAndTagSubscription = this.distributionPaths.pipe(
      mergeMap((path: DistributionPath[]) => forkJoin([pathTags$, of(path)]))
    ).subscribe(
      (response: [Map<string, string>, DistributionPath[]]) => {
        const tags = response[0];
        const paths = response[1];
        this.paths = paths;
        const data = this.initializeData(paths, tags);
        this.initializeChart(data);
        this.isLoading = false;
      },
      (error: HttpErrorResponse) => {
        console.error(error);
        this.errorService.displayError(error);
        this.isLoading = false;
      }
    );
    this.allSubscriptions.add(pathAndTagSubscription);
  }

  ngOnDestroy(): void {
    this.allSubscriptions.unsubscribe();
    if (this.barChart) {
      this.barChart.destroy();
    }
  }

  initializeIdSeqMap(): void {
    const paths: Array<ProcessPath> = this.graphMap.getGraphPaths();
    for (const path of paths) {
      const nodePathConcat = path.nodeEventPath.slice(1, path.nodeEventPath.length - 1).join('->');
      this.pathIdToSequenceMap.set(path.id, nodePathConcat);
    }
  }

  initializeData(paths: Array<DistributionPath>, tags: Map<string, string>): Array<InputData> {
    const data = new Array<InputData>();
    paths.forEach((path) => {
      let category;
      const pathId = path.pathId;
      const targetSequence = this.pathIdToSequenceMap.get(pathId);
      if (tags.has(targetSequence)) {
        category = tags.get(targetSequence);
      } else {
        category = null;
      }
      const inputData = new InputData(path.pathId, (+path.frequency).toFixed(2) + '%', +path.frequency, category);
      data.push(inputData);
    });
    return data;
  }

  initializeChart(data: Array<InputData>): void {
    // without setTimeout, the div size is picked up as 0, 0;
    setTimeout(() => {
      try {
        this.barChart = new BarChartApp(this.barChartElement.nativeElement, data);
        this.appInsights.trackTrace({ message: 'Event-Sequences-component: bar chart rendered.'});

        this.getSelectedPath(this.barChart);
      } catch (exception) {
        this.appInsights.trackException({ exception: exception as Error}, { componet: 'Event Sequences component.'});
        console.error('exception occured in Bar Chart', exception);
      }
    }, 1);
  }

  getSelectedPath(barChart: BarChartApp): void {
    const pathSubscription = barChart.getSelectedId().subscribe(
      (pathId: string) => {
        this.selectedPathId = pathId;
        this.selectedPathIdEvent.emit(this.selectedPathId);
        this.appInsights.trackTrace({ message: 'Event-Sequences-component: path id selected.'});
      });
    this.allSubscriptions.add(pathSubscription);
  }

  resizeChart(): void {
    this.barChart.resizeChart();
  }

  clearChart(): void {
    if (this.selectedPathId != null) {
      this.clearPathEvent.emit();
      this.barChart.clearChart();
      this.selectedPathId = '';
    }
  }

  handleEditPathLabels(): void {
    const pathTaggingModal = this.modalService.open(PathTaggingComponent, { size: 'lg' });
    (pathTaggingModal.componentInstance as PathTagInput).processId = this.processId;
    (pathTaggingModal.componentInstance as PathTagInput).graphMap = this.graphMap;
    if (pathTaggingModal) {
      pathTaggingModal.result.then(() => {
        this.isLoading = true;
        const tagSubscription = this.processService.getProcessTagMap(this.processId).subscribe((tags: Map<string, string>) => {
          const data = this.initializeData(this.paths, tags);
          this.initializeChart(data);
          this.isLoading = false;
        });
        this.allSubscriptions.add(tagSubscription);
      });
    }
  }
}
