import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Dashboard } from '../model/dashboard.model';
import { CatalogService } from '../service/catalog.service';
import { MasterDataService } from '../service/master-data.service';
import { AliasValidation } from '../common/alias-validation';
import { GraphDataService } from '../../service/graph-data.service';
import { AliasStatus } from '../../model/graph-data.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceTreeModalComponent } from '../service-tree/service-tree.component';
import { NgbModalSuccessComponent } from '../../modal/ngb-modal-success.component';
import { NgbModalErrorComponent } from '../../modal/ngb-modal-error.component';
import { Router } from '@angular/router';
import { ngbModalOptions } from '../../common/constants';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-add-dashboard',
  templateUrl: './add-dashboard.component.html',
  styleUrls: ['./add-dashboard.component.css', '../../../style/common-styles.scss']
})

export class AddDashboardComponent implements OnInit, AfterViewInit {
  typeList: string[];
  dataClassificationList: string[];
  accessMethodList: string[];
  dataSourceList: string[];
  alertList: boolean[];
  environmentList: string[];
  disableSecurityGroup = false;
  disableDataSourceValue = true;
  dashboard = new Dashboard('', 'L1: Infrastructure Availability & Resources Monitoring',
    '', '', 'General', 'AAD', '', 'Unknown', '', '', 'True', '', 'Production');

  // for alias validation.
  aliasValidation: AliasValidation;
  isAliasInvalid: boolean;
  isValidationInProgress: boolean;
  invalidAliasMessage: string;

  constructor(private catalogService: CatalogService,
    private masterDataService: MasterDataService,
    graphDataService: GraphDataService,
    private modalService: NgbModal,
    private router: Router,
  ) {
    this.masterDataService.getComboBoxData().subscribe(
      (response) => {
        this.accessMethodList = response.accessMethod;
        this.typeList = response.type;
        this.alertList = response.alerts;
        this.dataClassificationList = response.dataClassification;
        this.dataSourceList = response.dataSource;
        this.environmentList = response.environment;
      },
      (error) => {
        const errorModal = this.modalService.open(NgbModalErrorComponent);
        if (error.statusCode === 403) {
          errorModal.componentInstance.message = "Access denied";
        }
      }
    );

    // for verifying aliases.
    this.aliasValidation = new AliasValidation(graphDataService);
    this.isAliasInvalid = false;
    this.isValidationInProgress = false;
  }

  open() {
    this.modalService.open(ServiceTreeModalComponent, { centered: true }).result.then((node) => {
      this.dashboard.serviceTree = node.name + "(" + node.id + ")";
    }, () => { });
  }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.focusElement();
  }

  focusElement() {
    const focusElement: HTMLElement = document.getElementById('name') as HTMLElement;
    focusElement.focus();
  }

  onSubmit() {
    this.dashboard.alerting = this.dashboard.alerting.toLowerCase() === 'true' ? true : false;
    if (!this.dashboard.alerting) {
      this.dashboard.securityGroup = "";
    }
    if (this.dashboard.dataSource === "Unknown") {
      this.dashboard.dataSourceValue = "";
    }
    this.dashboard.owners = this.dashboard.owners.replace(/ /g, "");
    this.catalogService.addDashboard(this.dashboard).subscribe(
      (response) => {
        if (response.status === 201) {
          const successModal = this.modalService.open(NgbModalSuccessComponent, ngbModalOptions);
          successModal.componentInstance.message = "Successfully added Dashboard";
          successModal.result.then(
            () => {
              this.router.navigate(['/monitoring-solution/browse-catalog']);
            }
          );
        } else {
          const errorModal = this.modalService.open(NgbModalErrorComponent);
          errorModal.componentInstance.message = "Error adding Dashboard";
        }
      },
      (error: HttpErrorResponse) => {
        const errorModal = this.modalService.open(NgbModalErrorComponent);
        if (error.status === 403) {
          errorModal.componentInstance.message = "Access denied";
        }
      }
    );
  }

  newDashboard() {
    this.dashboard = new Dashboard('', 'L1: Infrastructure Availability & Resources Monitoring',
      '', '', 'General', 'AAD', '', 'Unknown', '', '', 'True', '', 'Production');
    this.isAliasInvalid = false;
    this.focusElement();
  }

  disableDataSourceValueInput(value: string) {
    if (value === 'Unknown') {
      this.disableDataSourceValue = true;
    } else {
      this.disableDataSourceValue = false;
    }
  }

  disableSecurityGroupInput(value: string) {
    if (value === 'False') {
      this.disableSecurityGroup = true;
    } else {
      this.disableSecurityGroup = false;
    }
  }

  checkAlias(aliases: string) {
    if (aliases.trim() === ';' || aliases.trim() === '') {
      this.isAliasInvalid = true;
      this.invalidAliasMessage = 'Please enter a valid alias';
      return "";
    }
    this.isAliasInvalid = false;
    this.isValidationInProgress = true;
    let invalidAlias: Array<AliasStatus>;
    this.aliasValidation.checkAlias(aliases).subscribe(
      (response) => {
        invalidAlias = response;
        invalidAlias = invalidAlias.filter(alias => alias.isValid === false);

        // display the alert message if invalidAlias has values.
        if (invalidAlias.length !== 0) {
          this.isAliasInvalid = true;
          this.invalidAliasMessage = 'Invalid Alias: ';
          for (let index = 0; index < invalidAlias.length; index++) {
            this.invalidAliasMessage += invalidAlias[index].alias;
            if (index === (invalidAlias.length - 1)) {
              this.invalidAliasMessage += '.';
            } else {
              this.invalidAliasMessage += ', ';
            }
          }
        } else {
          this.isAliasInvalid = false;
        }
      },
      (error) => {
        this.modalService.open(NgbModalErrorComponent);
      },
      () => {
        this.isValidationInProgress = false;
      }
    );
  }

  checkAliasAfterSubmit(aliases: string) {
    if (aliases.trim() === ';' || aliases.trim() === '') {
      this.isAliasInvalid = true;
      this.invalidAliasMessage = 'Please enter a valid alias';
      return "";
    }
    this.isAliasInvalid = false;
    this.isValidationInProgress = true;
    let invalidAlias: Array<AliasStatus>;
    this.aliasValidation.checkAlias(aliases).subscribe(
      (response) => {
        invalidAlias = response;
        invalidAlias = invalidAlias.filter(alias => alias.isValid === false);

        // display the alert message if invalidAlias has values.
        if (invalidAlias.length !== 0) {
          this.isAliasInvalid = true;
          this.invalidAliasMessage = 'Invalid Alias: ';
          for (let index = 0; index < invalidAlias.length; index++) {
            this.invalidAliasMessage += invalidAlias[index].alias;
            if (index === (invalidAlias.length - 1)) {
              this.invalidAliasMessage += '.';
            } else {
              this.invalidAliasMessage += ', ';
            }
          }
        } else {
          this.isAliasInvalid = false;
        }
      },
      (error) => {
        this.modalService.open(NgbModalErrorComponent);
      },
      () => {
        this.isValidationInProgress = false;
        if (!this.isAliasInvalid) {
          this.onSubmit();
        }
      }
    );
  }
}

