import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormArray, UntypedFormGroup, AbstractControl, Validators, ValidatorFn } from '@angular/forms';
import { ConditionSet, Condition, RuleOperator, EntityMetadata, RuleMasterData } from '../model/rule.model';
import { MasterDataService } from '../service/master-data.service';
import { forkJoin } from 'rxjs';
import { DataTypes, FormComponentTypes } from '../common/constants';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import * as moment from "moment";
import { constants } from '../common/constants';

@Injectable()
export class RuleFormService {
  // all controls to the form group are added dynamically.
  ruleConfigForm: UntypedFormGroup;

  // keep track of last added rules.
  conditionSetLastIndex: number;
  conditionLastIndexMap: Map<number, number>;

  // structure for storing master data
  // holds entity -> column -> data type
  entityMap: Map<string, Map<string, string>>;
  // holds conditionSetIndex -> conditionIndex -> entity1/entiy2 columnList
  columnListMap: Map<number, Map<number, Array<Array<string>>>>;
  // holds conditionSetIndex -> conditionIndex ->  operatorList
  operatorMap: Map<number, Map<number, Array<string>>>;
  // holds applicable operatorList for each data type
  operatorTypeMap = new Map<string, Array<string>>();
  // holds organization -> entity mapping
  organizationMap = new Map<string, Array<string>>();

  ruleOperators = new Array<RuleOperator>();

  constructor(private masterDataService: MasterDataService,
    private appInsightsService: ApplicationInsights,
  ) {
    // initializing last index to 0.
    this.conditionSetLastIndex = -1;
    this.conditionLastIndexMap = new Map<number, number>();

    // creating a base rule form with one condition set and one condition.
    this.ruleConfigForm = new UntypedFormGroup({});
    this.ruleConfigForm.addControl('conditionSets', new UntypedFormArray([]));

    // get entity master data.
    this.getRuleOperators();

    // get master data for conditions.
    this.columnListMap = new Map<number, Map<number, Array<Array<string>>>>();
    this.operatorMap = new Map<number, Map<number, Array<string>>>();

    // start new form.
    // this.clearForm();
  }

  createDefaultForm(): void {
    this.addConditionSet();
    this.addCondition(this.conditionSetLastIndex);
  }

  clearForm(): void {
    // initialzing last index to 0.
    this.conditionSetLastIndex = -1;
    this.conditionLastIndexMap = new Map<number, number>();

    // creating a base rule form with one condition set and one condition.
    this.ruleConfigForm = new UntypedFormGroup({});
    this.ruleConfigForm.addControl('conditionSets', new UntypedFormArray([]));

    // get master data for conditions.
    this.columnListMap = new Map<number, Map<number, Array<Array<string>>>>();
    this.operatorMap = new Map<number, Map<number, Array<string>>>();
    this.ruleConfigForm.reset();
  }

  getRuleForm(): UntypedFormGroup {
    return this.ruleConfigForm;
  }

  // component creation methods.
  // create condition set.
  createConditionSet(): AbstractControl {
    // create form group.
    const conditionSetForm = new UntypedFormGroup({});
    this.conditionSetLastIndex += 1;
    conditionSetForm.addControl('conditionSetIndex', new UntypedFormControl(this.conditionSetLastIndex));
    conditionSetForm.addControl('joiningOperator', new UntypedFormControl('Any'));
    conditionSetForm.addControl('conditions', new UntypedFormArray([]));

    // create new condition in condition set.
    this.conditionLastIndexMap.set(this.conditionSetLastIndex, 0);

    // set validators
    conditionSetForm.setValidators(this.distinctAggregations(this.ruleOperators));

    // TODO: create new condition.
    return conditionSetForm;
  }

  createLabelledInput(inputValidationType: DataTypes): AbstractControl {
    // create labelled input.
    const labelledInput = new UntypedFormGroup({});
    if (inputValidationType === DataTypes.Int) {
      labelledInput.addControl('input', new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]+')]));
    } else if (inputValidationType === DataTypes.Real) {
      labelledInput.addControl('input', new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]+(.[0-9]+)?')]));
    } else if (inputValidationType === DataTypes.String) {
      labelledInput.addControl('input', new UntypedFormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9]*')]));
    } else if (inputValidationType === DataTypes.Date) {
      labelledInput.addControl('input', new UntypedFormControl('', [Validators.required, this.validateDate()]));
    } else if (inputValidationType === DataTypes.Time) {
      labelledInput.addControl('input', new UntypedFormControl('', [Validators.required, this.validateTime()]));
    } else if (inputValidationType === DataTypes.DateTime) {
      labelledInput.addControl('input', new UntypedFormControl('', [Validators.required, this.validateDatetime()]));
    } else {
      labelledInput.addControl('input', new UntypedFormControl('', [Validators.required]));
    }

    // TODO: add validations for timestamp. The datatypes for timestamp need to be more specific.
    return labelledInput;
  }

  getConditionSetFormArray(): UntypedFormArray {
    return this.ruleConfigForm.get('conditionSets') as UntypedFormArray;
  }

  // add form group to the form array at first level.
  addConditionSet(conditionSetIndex?: number, conditionSetType: FormComponentTypes = FormComponentTypes.ConditionSet): void {
    let conditionSetForm: AbstractControl;
    if (conditionSetType === FormComponentTypes.ConditionSet) {
      conditionSetForm = this.createConditionSet();
    } else if (conditionSetType === FormComponentTypes.LabelledIntInput) {
      conditionSetForm = this.createLabelledInput(DataTypes.Int);
    } else if (conditionSetType === FormComponentTypes.LabelledRealInput) {
      conditionSetForm = this.createLabelledInput(DataTypes.Real);
    } else if (conditionSetType === FormComponentTypes.LabelledStringInput) {
      conditionSetForm = this.createLabelledInput(DataTypes.String);
    } else if (conditionSetType === FormComponentTypes.LabelledDateInput) {
      conditionSetForm = this.createLabelledInput(DataTypes.Date);
    } else if (conditionSetType === FormComponentTypes.LabelledTimeInput) {
      conditionSetForm = this.createLabelledInput(DataTypes.Time);
    } else if (conditionSetType === FormComponentTypes.LabelledDatetimeInput) {
      conditionSetForm = this.createLabelledInput(DataTypes.DateTime);
    } else {
      this.appInsightsService.trackTrace({ message: 'FormService: unsupported form type on addConditionSet'});
    }
    if (conditionSetIndex == null) {
      conditionSetIndex = this.conditionSetLastIndex + 1;
      this.getConditionSetFormArray().push(conditionSetForm);
    } else {
      this.getConditionSetFormArray().insert(conditionSetIndex, conditionSetForm);
    }
  }

  deleteConditionSet(conditionSetIndex: number): void {
    if (this.getConditionSetFormArray().length > 1) {
      this.getConditionSetFormArray().removeAt(conditionSetIndex);
    }
  }

  getConditionSet(conditionSetIndex: number): UntypedFormGroup {
    return this.getConditionSetFormArray().at(conditionSetIndex) as UntypedFormGroup;
  }

  // condition methods.
  createCondition(conditionSetIndex: number): UntypedFormGroup {
    const conditionForm = new UntypedFormGroup({});
    this.conditionLastIndexMap.set(conditionSetIndex, this.conditionLastIndexMap.get(conditionSetIndex) + 1);
    conditionForm.addControl('conditionIndex', new UntypedFormControl(this.conditionLastIndexMap.get(conditionSetIndex)));
    conditionForm.addControl('entity1', new UntypedFormControl(constants.SelectEntityOrVariable, this.ValidateSelection));
    conditionForm.addControl('entityColumn1', new UntypedFormControl(constants.SelectColumn, this.ValidateSelection));
    conditionForm.addControl('operator', new UntypedFormControl(constants.SelectOperator, this.ValidateSelection));
    conditionForm.addControl('entity2', new UntypedFormControl(''));
    conditionForm.addControl('entityColumn2', new UntypedFormControl(''));
    conditionForm.addControl('variableName', new UntypedFormControl(''));
    conditionForm.addControl('variableType', new UntypedFormControl(''));

    // subscribing to changes.
    conditionForm.get('operator').valueChanges.subscribe((value) => {
      const operator: string = value;
      if (!operator.includes('Join') && !operator.includes('By')) {
        conditionForm.get('entity2').setValue('');
        conditionForm.get('entityColumn2').setValue('');
      } else {
        conditionForm.get('entity2').setValue(constants.SelectEntity);
        conditionForm.get('entityColumn2').setValue(constants.SelectColumn);
      }
      this.addValidations(conditionForm as UntypedFormGroup);
      // if (operator === 'Count()') {
      //   conditionForm.get('entity1').disable();
      //   conditionForm.get('entityColumn1').disable();
      // } else {
      //   conditionForm.get('entity1').enable();
      //   conditionForm.get('entityColumn1').enable();
      // }
    });

    return conditionForm;
  }

  ValidateSelection(control: AbstractControl) {
    const controlValue: string = control.value;
    if (controlValue.includes('Select ')) {
      return { selection: true, selectionError: "Please select valid entry" };
    }
    return null;
  }

  // Only distinct aggregation operators and single group by are allowed in a conditionset
  distinctAggregations(ruleOperators: Array<RuleOperator>) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // map operators to type
      const operatorMap = new Map<string, string>();
      for (let i = 0; i < this.ruleOperators.length; i++) {
        operatorMap.set(this.ruleOperators[i].operatorName, this.ruleOperators[i].operatorType);
      }
      // iterate over conditions and return error if aggregations are not distinct
      const conditionArray = control.get('conditions') as UntypedFormArray;
      if (conditionArray.length > 0) {
        let hasGroupByOperator = false;
        const operatorList = new Array<string>();
        for (let i = 0; i < conditionArray.length; i++) {
          const operator = conditionArray.at(i).get('operator').value;
          if (operator !== constants.SelectOperator && operatorMap.get(operator) === constants.Aggregation) {
            // check for distinct aggregations
            if (operatorList.includes(operator)) {
              return { hasAggregationError: true, aggregationError: "Please select distinct aggregations" };
            } else {
              operatorList.push(operator);
            }
            // check if group by already exists
            if (operator.includes('By')) {
              if (hasGroupByOperator) {
                return { hasAggregationError: true, aggregationError: "Only one Group By is allowed" };
              } else {
                hasGroupByOperator = true;
              }
            }
          }
        }
      }
    };
  }

  addValidations(formGroup: AbstractControl) {
    const operator: string = formGroup.get('operator').value;
    const entity1Control = formGroup.get('entity1');
    const column1Control = formGroup.get('entityColumn1');
    const entity2Control = formGroup.get('entity2');
    const column2Control = formGroup.get('entityColumn2');
    if (this.getOperatorType(operator) === 'Join') {
      formGroup.setValidators(this.checkType(this.entityMap));
      formGroup.get('entity2').setValidators(this.ValidateSelection);
      formGroup.get('entityColumn2').setValidators(this.ValidateSelection);
    } else if (operator === 'Between') {
      const dataType = this.entityMap.get(entity1Control.value).get(column1Control.value);
      if (dataType === 'string') {
        entity2Control.setValidators(Validators.pattern('^[a-zA-Z0-9_]*$'));
        column2Control.setValidators(Validators.pattern('^[a-zA-Z0-9_]*$'));
      } else if (dataType === 'int') {
        entity2Control.setValidators(Validators.pattern('^[0-9]*$'));
        column2Control.setValidators(Validators.pattern('^[0-9]*$'));
        formGroup.setValidators(this.checkBetweenValues(dataType));
      } else if (dataType === 'real') {
        entity2Control.setValidators(Validators.pattern('[-+]?[0-9]*\.?[0-9]+'));
        column2Control.setValidators(Validators.pattern('[-+]?[0-9]*\.?[0-9]+'));
        formGroup.setValidators(this.checkBetweenValues(dataType));
      } else if (dataType === 'datetime') {
        entity2Control.setValidators(this.validateDatetime);
        column2Control.setValidators(this.validateDatetime);
        formGroup.setValidators(this.checkBetweenValues(dataType));
      }
    } else if (this.getOperatorType(operator) === constants.Comparison && operator !== 'Between') {
      const dataType = this.entityMap.get(entity1Control.value).get(column1Control.value);
      if (dataType === 'string') {
        entity2Control.setValidators(Validators.pattern('^[a-zA-Z0-9_]*$'));
      } else if (dataType === 'int') {
        entity2Control.setValidators(Validators.pattern('^[0-9]*$'));
      } else if (dataType === 'real') {
        entity2Control.setValidators(Validators.pattern('[-+]?[0-9]*\.?[0-9]+'));
      } else if (dataType === 'datetime') {
        entity2Control.clearValidators();
        column2Control.clearValidators();
      }
    }
  }

  checkBetweenValues(dataType: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const entity2 = control.get('entity2').value;
      const entityColumn2 = control.get('entityColumn2').value;
      if (entity2 !== '' && entityColumn2 !== '') {
        if (dataType === 'datetime') {
          if (new Date(entity2) > new Date(entityColumn2)) {
            return { isRangeError: true, rangeError: "Please enter a valid range of values" };
          }
        } else if (dataType === 'int') {
          if (Number(entity2) > Number(entityColumn2)) {
            return { isRangeError: true, rangeError: "Please enter a valid range of values" };
          }
        }
      }
      return null;
    };
  }

  checkType(entityMap: Map<string, Map<string, string>>): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const entity1Control = control.get('entity1');
      const column1Control = control.get('entityColumn1');
      const dataType1 = entityMap.get(entity1Control.value).get(column1Control.value);
      const entity2Control = control.get('entity2');
      const column2Control = control.get('entityColumn2');
      if (entityMap.has(entity2Control.value) && entityMap.get(entity2Control.value).has(column2Control.value)) {
        const dataType2 = entityMap.get(entity2Control.value).get(column2Control.value);
        if (dataType1 !== dataType2) {
          return { isDataTypeError: true, dataTypeError: "Data Type on LHS and RHS should match" };
        }
      }
      return null;
    };
  }

  validateDate(): ValidatorFn {
    return (dateControl: AbstractControl): { [key: string]: any } | null => {
      const dateValue = moment(dateControl.value, "MM-DD-YYYY");
      if (dateValue.isValid()) {
        return null;
      } else {
        return {
          isDateInvalid: true,
          dateInvalidMessage: "Entered date is invalid. Enter Date in MM/DD/YYYY format."
        };
      }
    };
  }

  validateTime(): ValidatorFn {
    return (timeControl: AbstractControl): { [key: string]: any } | null => {
      const timeValue = moment(timeControl.value, "hh:MM a");
      if (timeValue.isValid()) {
        return null;
      } else {
        return {
          isTimeInvalid: true,
          timeInvalidMessage: "Time value is invalid. Enter time in HH:MM AM/PM format."
        };
      }
    };
  }

  validateDatetime(): ValidatorFn {
    return (datetimeControl: AbstractControl): {[key: string]: any} | null => {
      const datetimeValue = moment(datetimeControl.value, "MM/DD/YYYY, hh:MM a");
      if (datetimeValue.isValid()) {
        return null;
      } else {
        return {
          isdatetimeInvalid: true,
          datetimeInvalidMessage: "Datetime value is invalid. Enter time in MM/DD/YYYY, HH:MM AM/PM format."
        };
      }
    };
  }

  getConditionFormArray(conditionSetIndex: number): UntypedFormArray {
    return this.getConditionSet(conditionSetIndex).get('conditions') as UntypedFormArray;
  }

  getCondition(conditionSetIndex: number, conditionIndex: number): UntypedFormGroup {
    return this.getConditionFormArray(conditionSetIndex).at(conditionIndex) as UntypedFormGroup;
  }

  addCondition(conditionSetIndex: number, conditionIndex?: number): void {
    const condition = this.createCondition(conditionSetIndex);
    if (conditionIndex == null) {
      this.getConditionFormArray(conditionSetIndex).push(condition);
    } else {
      this.getConditionFormArray(conditionSetIndex).insert(conditionIndex, condition);
    }
  }

  deleteCondition(conditionSetIndex: number, conditionIndex?: number): void {
    if (this.getConditionFormArray(conditionSetIndex).length > 1) {
      this.getConditionFormArray(conditionSetIndex).removeAt(conditionIndex);
    } else {
      const entity1 = this.getEntity1(conditionSetIndex, conditionIndex);
      if (entity1 === constants.ComputedVariables) {
        this.addCondition(conditionSetIndex);
        this.deleteCondition(conditionSetIndex, conditionIndex);
      }
    }
  }

  // getters methods for condition.
  getConditionIndex(conditionSetIndex: number, conditionIndex: number): string {
    return this.getCondition(conditionSetIndex, conditionIndex).get('conditionIndex').value;
  }

  getEntity1(conditionSetIndex: number, conditionIndex: number): string {
    return this.getCondition(conditionSetIndex, conditionIndex).get('entity1').value;
  }

  getEntityColumn1(conditionSetIndex: number, conditionIndex: number): string {
    return this.getCondition(conditionSetIndex, conditionIndex).get('entityColumn1').value;
  }

  getOperator(conditionSetIndex: number, conditionIndex: number): string {
    return this.getCondition(conditionSetIndex, conditionIndex).get('operator').value;
  }

  getEntity2(conditionSetIndex: number, conditionIndex: number): string {
    return this.getCondition(conditionSetIndex, conditionIndex).get('entity2').value;
  }

  getEntityColumn2(conditionSetIndex: number, conditionIndex: number): string {
    return this.getCondition(conditionSetIndex, conditionIndex).get('entityColumn2').value;
  }

  getVariable(conditionSetIndex: number, conditionIndex: number): string {
    return this.getCondition(conditionSetIndex, conditionIndex).get('variableName').value;
  }

  getVariableType(conditionSetIndex: number, conditionIndex: number): string {
    return this.getCondition(conditionSetIndex, conditionIndex).get('variableType').value;
  }

  // set methods for populating form.
  setjoiningOperator(conditionSetIndex: number, joiningOperator: string): void {
    this.getConditionSet(conditionSetIndex).get('joiningOperator').setValue(joiningOperator);
  }

  setEntity1(conditionSetIndex: number, conditionIndex: number, entity1: string): void {
    this.getCondition(conditionSetIndex, conditionIndex).get('entity1').setValue(entity1);
  }

  setEntityColumn1(conditionSetIndex: number, conditionIndex: number, entityColumn1: string): void {
    this.getCondition(conditionSetIndex, conditionIndex).get('entityColumn1').setValue(entityColumn1);
  }

  setOperator(conditionSetIndex: number, conditionIndex: number, operator: string): void {
    this.getCondition(conditionSetIndex, conditionIndex).get('operator').setValue(operator);
  }

  setEntity2(conditionSetIndex: number, conditionIndex: number, entity2: string): void {
    this.getCondition(conditionSetIndex, conditionIndex).get('entity2').setValue(entity2);
  }

  setEntityColumn2(conditionSetIndex: number, conditionIndex: number, entityColumn2: string): void {
    this.getCondition(conditionSetIndex, conditionIndex).get('entityColumn2').setValue(entityColumn2);
  }

  setVariable(conditionSetIndex: number, conditionIndex: number, variableName: string): void {
    this.getCondition(conditionSetIndex, conditionIndex).get('variableName').setValue(variableName);
  }

  setVariableType(conditionSetIndex: number, conditionIndex: number, variableName: string): void {
    this.getCondition(conditionSetIndex, conditionIndex).get('variableType').setValue(variableName);
  }

  setCondition(conditionSetIndex: number, conditionIndex: number, condition: Condition) {
    this.setEntity1(conditionSetIndex, conditionIndex, condition.entity1);
    this.setEntityColumn1(conditionSetIndex, conditionIndex, condition.entityColumn1);
    this.setOperator(conditionSetIndex, conditionIndex, condition.operator);
    this.setEntity2(conditionSetIndex, conditionIndex, condition.entity2);
    this.setEntityColumn2(conditionSetIndex, conditionIndex, condition.entityColumn2);
    this.setVariable(conditionSetIndex, conditionIndex, condition.variableName);
    this.setVariableType(conditionSetIndex, conditionIndex, condition.variableType);
  }

  // convert to ruleset for further processing.
  convertToRuleset(): Array<ConditionSet> {
    return this.ruleConfigForm.get('conditionSets').value;
  }

  getEntityMetadata(response: EntityMetadata[]): Array<string> {
    const entityListByOrg = new Array<string>();
    for (const entity of response) {
      entityListByOrg.push(entity.id.toString());
    }
    return entityListByOrg;
  }

  getEntities(ruleMasterData: RuleMasterData[]) {
    this.entityMap = new Map<string, Map<string, string>>();
    for (const entity of ruleMasterData) {
      if (this.entityMap.has(entity.entityName)) {
        const innerMap = this.entityMap.get(entity.entityName);
        innerMap.set(entity.columnName, entity.columnType);
        this.entityMap.set(entity.entityName, innerMap);
      } else {
        const innerMap = new Map<string, string>();
        innerMap.set(entity.columnName, entity.columnType);
        this.entityMap.set(entity.entityName, innerMap);
      }
    }
  }

  getRuleOperators() {
    const dataTypeMap = new Map<number, string>();
    const dataTypes = this.masterDataService.getDataTypes();
    const ruleOperators = this.masterDataService.getRuleOperators();
    forkJoin([dataTypes, ruleOperators]).subscribe((response) => {
      for (const row of response[0]) {
        dataTypeMap.set(row.id, row.type);
      }

      for (const row of response[1]) {
        this.ruleOperators.push(row);
        const applicableIds = row.dataType.split(',');
        for (const id of applicableIds) {
          const dataType = dataTypeMap.get(Number(id));
          if (this.operatorTypeMap.has(dataType)) {
            const operators = this.operatorTypeMap.get(dataType);
            operators.push(row.operatorName);
            this.operatorTypeMap.set(dataType, operators);
          } else {
            const operators = new Array<string>();
            operators.push(row.operatorName);
            this.operatorTypeMap.set(dataType, operators);
          }
        }
      }
    });
  }

  fetchOperatorList(conditionSetIndex: number, conditionIndex: number, computedVars?: Map<string, string>) {
    // check if map has column data loaded.
    let innerOperatorMap: Map<number, Array<string>>;
    if (this.operatorMap.has(conditionSetIndex)) {
      innerOperatorMap = this.operatorMap.get(conditionSetIndex);
    } else {
      innerOperatorMap = new Map<number, Array<string>>();
      this.operatorMap.set(conditionSetIndex, innerOperatorMap);
    }

    let operatorList: Array<string>;
    operatorList = this.getOperatorListFromMap(conditionSetIndex, conditionIndex, computedVars);
    innerOperatorMap.set(conditionIndex, operatorList);
    this.operatorMap.set(conditionSetIndex, innerOperatorMap);

    return operatorList;
  }

  getColumnDataType(conditionSetIndex: number, conditionIndex: number, computedVars?: Map<string, string>): string {
    const formGroup = this.getCondition(conditionSetIndex, conditionIndex);
    const entityControl = formGroup.get('entity1') as UntypedFormControl;
    const columnControl = formGroup.get('entityColumn1') as UntypedFormControl;
    if (entityControl.value === constants.ComputedVariables) {
      return computedVars.get(columnControl.value);
    } else {
      return this.entityMap.get(entityControl.value).get(columnControl.value);
    }
  }

  getOperatorListFromMap(conditionSetIndex: number, conditionIndex: number, computedVars?: Map<string, string>): Array<string> {
    const dataType = this.getColumnDataType(conditionSetIndex, conditionIndex, computedVars);
    return this.operatorTypeMap.get(dataType);
  }

  getColumnList(conditionSetIndex: number, conditionIndex: number, entityNumber: number) {
    // check if map has column data loaded.
    let innerColumnListMap: Map<number, Array<Array<string>>>;
    if (this.columnListMap.has(conditionSetIndex)) {
      innerColumnListMap = this.columnListMap.get(conditionSetIndex);
    } else {
      innerColumnListMap = new Map<number, Array<Array<string>>>();
      this.columnListMap.set(conditionSetIndex, innerColumnListMap);
    }

    let columnListArray: Array<Array<string>>;
    if (innerColumnListMap.has(conditionIndex)) {
      columnListArray = innerColumnListMap.get(conditionIndex);
    } else {
      columnListArray = [null, null];
      innerColumnListMap.set(conditionIndex, columnListArray);
    }

    // check if array has the list of columns.
    if (columnListArray[entityNumber] == null) {
      columnListArray[entityNumber] = this.getColumnListFromMap(conditionSetIndex, conditionIndex, entityNumber);
    }
    return columnListArray[entityNumber];
  }

  updateColumnList(conditionSetIndex: number, conditionIndex: number, entityNumber: number): void {
    let innerColumnListMap: Map<number, Array<Array<string>>>;
    if (this.columnListMap.has(conditionSetIndex)) {
      innerColumnListMap = this.columnListMap.get(conditionSetIndex);
    } else {
      innerColumnListMap = new Map<number, Array<Array<string>>>();
      this.columnListMap.set(conditionSetIndex, innerColumnListMap);
    }

    let columnListArray: Array<Array<string>>;
    if (innerColumnListMap.has(conditionIndex)) {
      columnListArray = innerColumnListMap.get(conditionIndex);
    } else {
      columnListArray = [null, null];
      innerColumnListMap.set(conditionIndex, columnListArray);
    }
    columnListArray[entityNumber] = this.getColumnListFromMap(conditionSetIndex, conditionIndex, entityNumber);
  }


  getColumnListFromMap(conditionSetIndex: number, conditionIndex: number, entityNumber: number): Array<string> {
    const columnGroupControl = this.getCondition(conditionSetIndex, conditionIndex);
    let columnControl: UntypedFormControl;
    if (entityNumber === 0) {
      columnControl = columnGroupControl.get('entity1') as UntypedFormControl;
    } else {
      columnControl = columnGroupControl.get('entity2') as UntypedFormControl;
    }
    return Array.from<string>(this.entityMap.get(columnControl.value).keys());
  }

  getEntityValues(): Array<string> {
    const entities = Array.from<string>(this.entityMap.keys());
    if (entities.includes(constants.ComputedVariables)) {
      entities.splice(entities.indexOf(constants.ComputedVariables), 1);
    }
    return entities;
  }

  getOperatorType(operator: string): string {
    for (let i = 0; i < this.ruleOperators.length; i++) {
      if (this.ruleOperators[i].operatorName === operator) {
        return this.ruleOperators[i].operatorType;
      }
    }
    return null;
  }
}

