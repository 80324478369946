import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONFIG } from '../../common/constants';
import { DataSource, DataSourceRequest } from '../model/data-source.model';

@Injectable()
export class DataSourceService {
  private baseBAMUrl: string;
  constructor(
    @Inject(APP_CONFIG) appConfig: any,
    private httpClient: HttpClient,
  ) {
    this.baseBAMUrl = appConfig.baseUrlWithVersionBAMDashboard;
   }

   public addDataSource(dataSourceRequest: DataSourceRequest): Observable<DataSource> {
    const url = this.baseBAMUrl + 'DataSource';
    return this.httpClient.post<DataSource>(url, dataSourceRequest);
  }

  public updateDataSource(id: number, dataSourceRequest: DataSourceRequest): Observable<DataSource> {
    const url = this.baseBAMUrl + 'DataSource(' + id + ')';
    return this.httpClient.put<DataSource>(url, dataSourceRequest);
  }
}
