import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { TableInput } from '../../../model/page-configuration.model';
import { Observable, Subscription } from 'rxjs';
import { TableOptions, TableInputV2, RowEditEvent, ColumnInterface, RowInterface, RowNavigateEvent } from '../../../model/common.model';
import { cloneDeep } from 'lodash-es';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { constants } from '../../constants';

@Component({
  selector: 'app-display-table-v2',
  templateUrl: './display-table-v2.component.html',
  styleUrls: ['./display-table-v2.component.scss']
})
export class DisplayTableV2Component implements OnInit, OnDestroy {
  // table updates everytime value is published by the observable.
  @Input() tableInput: Observable<TableInput>;
  @Input() searchTable: Observable<string>;
  @Input() tableOptions: TableOptions;

  @Output() rowEditEvent = new EventEmitter<RowEditEvent>();
  @Output() rowNavEvent = new EventEmitter<RowNavigateEvent>();

  @ViewChild('ngxDatatable') ngxDatatable: DatatableComponent;

  columns: Array<ColumnInterface>;
  rows: Array<any>;
  filteredRows: Array<any>;

  customColumns: Array<string>;

  searchColumns: Array<string>;

  subscriptions: Subscription;

  displayTable: boolean;

  constructor(
    private changeDetector: ChangeDetectorRef
  ) {
    this.subscriptions = new Subscription();
    this.searchColumns = new Array<string>();
    this.displayTable = false;

    this.customColumns = ['Status', 'Status Message'];
  }

  ngOnInit(): void {
    const tableDataSubscription = this.tableInput.subscribe(
      (tableInput: TableInputV2) => {
        this.processTableInput(tableInput);
        this.displayTable = true;
      }
    );
    this.subscriptions.add(tableDataSubscription);

    if (this.tableOptions.enableSearch) {
      const searchSubscription = this.searchTable.subscribe(
        (searchString: string) => {
          searchString = searchString.toLowerCase();
          const rows = this.rows.filter(x => {
            for (const key in x) {
              if (x.hasOwnProperty(key) && (this.searchColumns.indexOf(key) !== -1) && x[key] != null) {
                const element: string = x[key].toLowerCase();
                if (element.indexOf(searchString) > -1) {
                  return true;
                }
              }
            }
            return false;
          });
          this.filteredRows = rows;
        }
      );
      this.subscriptions.add(searchSubscription);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  processTableInput(tableInput: TableInputV2): void {
    const columns = new Array<ColumnInterface>();
    let columnIndex = 0;
    let wrapTextColumnIndex = 0;
    let editColumnIndex = 0;
    let navColumnIndex = 0;

    tableInput.columns.forEach((columnName: string, index: number) => {
      const column = {} as ColumnInterface;
      let generatedColumnName: string;
      if (this.tableOptions.wrapTextColumns?.includes(columnName)) {
        generatedColumnName = `wrapText${wrapTextColumnIndex}`;
        this.customColumns.push(columnName);
        wrapTextColumnIndex += 1;
      } else if (this.tableOptions.editColumns?.includes(columnName)) {
        generatedColumnName = `edit${editColumnIndex}`;
        this.customColumns.push(columnName);
        editColumnIndex += 1;
      } else if (this.tableOptions.navColumns?.includes(columnName)) {
        generatedColumnName = `navigate${navColumnIndex}`;
        this.customColumns.push(columnName);
        navColumnIndex += 1;
      } else {
        generatedColumnName = `column${columnIndex}`;
        columnIndex += 1;
      }
      column['prop'] = generatedColumnName;
      column['name'] = columnName;
      column['flexGrow'] = this.tableOptions.columnWidths[index];
      columns.push(column);

      if (this.tableOptions.enableSearch && this.tableOptions.searchColumns?.length > 0) {
        if (this.tableOptions.searchColumns.indexOf(columnName) !== -1) {
          this.searchColumns.push(generatedColumnName);
        }
      }
    });

    if (this.tableOptions.enableSearch && this.tableOptions.searchColumns?.length === 0) {
      this.searchColumns = tableInput.columns as string[];
    }

    const rows = Array<RowInterface>();
    tableInput.rows.forEach((row: Array<string>) => {
      const transformedRow: RowInterface = {};
      row.forEach((rowValue: string, index: number) => {
        transformedRow[columns[index].prop] = rowValue;
      });
      rows.push(transformedRow);
    });

    this.rows = rows;
    this.filteredRows = cloneDeep(this.rows);
    this.columns = columns;
  }

  editRow(value: string, column: string): void {
    if (this.tableOptions.addEditColumn) {
      const event: RowEditEvent = {
        value: value,
        column: column
      };
      this.rowEditEvent.emit(event);
    }
  }

  navigateRow(value: string, column: string): void {
    if (this.tableOptions.addNavColumn) {
      const event: RowNavigateEvent = {
        value: value,
        column: column
      };
      this.rowNavEvent.emit(event);
    }
  }

  redrawTable(): void {
    this.ngxDatatable.recalculate();
    this.changeDetector.detectChanges();
  }

  getCellClass(columnName: string): string {
    let cellClass = '';
    if (this.tableOptions.centeredColumns && this.tableOptions.centeredColumns.indexOf(columnName) !== -1) {
      cellClass += 'text-center';
    }
    return cellClass;
  }

  getStatusClass({ value }: {value: string}): string {
    let cellClass = '';
    if (value === constants.SuccessStatus) {
      cellClass = 'text-center fa fa-check-circle text-success';
    } else if (value === constants.FailureStatus) {
      cellClass = 'text-center fa fa-times-circle-o danger-text';
    } else if (value === constants.RunningStatus) {
      cellClass = 'text-center text-primary';
    } else if (value === constants.ReadyStatus) {
      cellClass = 'text-center fa fa-check-circle text-success';
    } else if (value === constants.PendingStatus) {
      cellClass = 'text-center text-primary';
    } else if (value === constants.CreatingStatus) {
      cellClass = 'text-center text-primary';
    } else if (value === constants.UpdatingStatus) {
      cellClass = 'text-center text-primary';
    } else if (value === constants.DeletingStatus) {
      cellClass = 'text-center text-primary';
    }
    return cellClass;
  }

  isStatusSpinner(value: string): boolean {
    if ([
      constants.RunningStatus, constants.PendingStatus, constants.CreatingStatus, constants.UpdatingStatus, constants.DeletingStatus
    ].includes(value)) {
      return true;
    }
    return false;
  }

  emitError(value: string, column: string): void {
    const event: RowEditEvent = {
      value: value,
      column: column
    };
    this.rowEditEvent.emit(event);
  }

}
