import { Component, Inject, OnInit } from '@angular/core';
import { APP_CONFIG } from '../common/constants';
import { AppConfig } from '../model/app-config.model';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

  constructor(@Inject(APP_CONFIG) public appConfig: AppConfig) { }

  ngOnInit() {
  }

}
