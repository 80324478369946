import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BlobUploadCommonResponse, BlockBlobClient } from '@azure/storage-blob';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { APP_CONFIG } from '../common/constants';
import { AppConfig } from '../model/app-config.model';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private baseBAMUrl: string;

  constructor(
    @Inject(APP_CONFIG) appConfig: AppConfig,
    private httpClient: HttpClient,
  ) {
    this.baseBAMUrl = appConfig.baseUrlWithVersionBAMDashboard;
  }

  public importFile(groupId: string, file: File): Observable<string> {
    let urlObs = this.getBlobUrlWithSasToken(groupId);
    
    let uploadObs = urlObs.pipe(
      switchMap((url: string) => {
        return this.uploadFileToBlob(url, file);
      }));

    let mapObs = uploadObs.pipe(
      map((response: BlobUploadCommonResponse) => response._response.request.url)
    )

    return mapObs;
  }

  private getBlobUrlWithSasToken(groupId: string): Observable<string> {
    const url = this.baseBAMUrl + 'Import/' + groupId;
    return this.httpClient.get(url, { responseType: 'text' });
  }

  private uploadFileToBlob(url: string, file: File): Observable<BlobUploadCommonResponse> {
    const blockBlobClient = new BlockBlobClient(url);
    return from(blockBlobClient.uploadData(file,
      {
        blobHTTPHeaders: {
          blobContentType: file.type
        }
      }).catch((error: HttpErrorResponse) => {
        console.error("Error occured while uploading file: ", error);
        const message = "Error occured while uploading a file. Please Try again after sometime or contact Administrator";
        throw new HttpErrorResponse({ error: message });
      }));
  }
}
