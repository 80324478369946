<div class="container-fluid pt-3">
  <div class="jumbotron bg-transparent">
    <h1>Monitoring 360</h1>
    <hr class="my-4" />
    <div class="accordian" id="appAccordian">
      <div *ngIf="this.appConfig.featureFlag.enableEventBroker" class="card bg-transparent">
        <div class="card-header">
          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse2">
            <span class="h4">Event Broker</span>
          </button>
        </div>
        <div id="collapse2" class="collapse show">
          <div class="card-body">
            The Event Broker enables onboarding, integration, visibility, and best practices for the Supply Chain event
            driven architecture.
          </div>
        </div>
      </div>
      <div *ngIf="this.appConfig.featureFlag.enableDashboardCatalog" class="card bg-transparent">
        <div class="card-header">
          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse1">
            <span class="h4">Dashboard Catalog</span>
          </button>
        </div>
        <div id="collapse1" class="collapse show">
          <div class="card-body">
            The SupplyChain Dashboard Catalog is your one stop location to get information on solutions and dasboards
            built across the organization in a standard template. Add entry for your dashboard, assign owners to ensure
            Role Based Access. The solutions are classified based on the Levels and searchable in the Browsing catalog.
            Add, Manage, Browse, Search!
          </div>
        </div>
      </div>
      <div *ngIf="this.appConfig.featureFlag.enableQoS" class="card bg-transparent">
        <div class="card-header">
          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse2">
            <span class="h4"> Quality Of Service</span>
          </button>
        </div>
        <div id="collapse2" class="collapse show">
          <div class="card-body">
            The Quality Of Service capability enables easy onboarding to Supply Chain QoS Platform.
          </div>
        </div>
      </div>
      <div *ngIf="this.appConfig.featureFlag.enableCT" class="card bg-transparent">
        <div class="card-header">
          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse7">
            <span class="h4"> Control Tower</span>
          </button>
        </div>
        <div id="collapse7" class="collapse show">
          <div class="card-body">
            The Control Tower is a platform that creates E2E visibility, share info/collaborate real time, drive early
            alerts/exceptions, use predictive/prescriptive models to drive decision support. M360 platform provides the
            Business Rule Engine to the Control Tower solution as a core capability to create Business Rules, Monitor
            scenarios and raise business alerts and as well violation information to customer reports.
          </div>
        </div>
      </div>
      <div *ngIf="this.appConfig.featureFlag.enableBAM" class="card bg-transparent">
        <div class="card-header">
          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse3">
            <span class="h4">Business Activity Monitoring Dashboard</span>
          </button>
        </div>
        <div id="collapse3" class="collapse show">
          <div class="card-body">
            The BAM dashboard gives the ability to create rules through configuration driven data, create business
            alerts for BAM Monitoring. Additionally, the dashboard enables viewing of End to End Business process and
            drill down into the Critical Service Offering architecture workflows and be able to view real time telemetry
            represented via graph visualization.
            <br />For Onboarding & Access, please visit:
            <a class="link-text" href="https://aka.ms/m360wiki" target="_blank">
              https://aka.ms/m360wiki
              <span class="fa fa-link" aria-hidden="true"></span>
            </a>
          </div>
        </div>
      </div>
      <div *ngIf="this.appConfig.featureFlag.enableScoreCard" class="card bg-transparent">
        <div class="card-header">
          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse6">
            <span class="h4">Score Card</span>
          </button>
        </div>
        <div id="collapse6" class="collapse show">
          <div class="card-body">
            This Score card is meant to give a visibility to the health of critical scenarios across the Organization
            and can be used in Live Site meetings and reporting metrics to leadership both across Engineering and
            Business. This report has ability to manually update the status and necessary authorization to allow read
            permissions only.
            <br />For Onboarding & Access, please visit:
            <a class="link-text" href="https://aka.ms/m360wiki" target="_blank">
              https://aka.ms/m360wiki
              <span class="fa fa-link" aria-hidden="true"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
