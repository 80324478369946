<div class="container-fluid pt-2">
  <div class="row">
    <div class="col-9">
      <ul
        ngbNav
        #nav="ngbNav"
        class="nav-pills"
        role="tablist"
        aria-label="tablist"
        [(activeId)]="activeTab"
      >
        <li
          #pillsAllTab
          [ngbNavItem]="'ALLTab'"
          ngbNavLink
          [routerLink]="[]"
          class="nav-item nav-link text-light border border-dark rounded tab-click"
          role="tab"
          [ngClass]="{ selected: activeTab === 'ALLTab' }"
          (click)="loadTabData('ALL_DATA')"
          (keyup.enter)="loadTabData('ALL_DATA')"
        >
          All Catalogs
        </li>
        <li
          [ngbNavItem]="'L1Tab'"
          ngbNavLink
          [routerLink]="[]"
          class="nav-item nav-link text-light border border-dark rounded tab-click"
          role="tab"
          [ngClass]="{ selected: activeTab === 'L1Tab' }"
          (click)="loadTabData('L1_DASHBOARDS')"
          (keyup.enter)="loadTabData('L1_DASHBOARDS')"
        >
          L1 Solution
        </li>
        <li
          [ngbNavItem]="'L2Tab'"
          ngbNavLink
          [routerLink]="[]"
          class="nav-item nav-link text-light border border-dark rounded tab-click"
          role="tab"
          [ngClass]="{ selected: activeTab === 'L2Tab' }"
          (click)="loadTabData('L2_DASHBOARDS')"
          (keyup.enter)="loadTabData('L2_DASHBOARDS')"
        >
          L2 Solution
        </li>
        <li
          [ngbNavItem]="'L3Tab'"
          ngbNavLink
          [routerLink]="[]"
          class="nav-item nav-link text-light border border-dark rounded tab-click"
          role="tab"
          [ngClass]="{ selected: activeTab === 'L3Tab' }"
          (click)="loadTabData('L3_DASHBOARDS')"
          (keyup.enter)="loadTabData('L3_DASHBOARDS')"
        >
          L3 Solution
        </li>
        <li
          [ngbNavItem]="'L4Tab'"
          ngbNavLink
          [routerLink]="[]"
          class="nav-item nav-link text-light border border-dark rounded tab-click"
          role="tab"
          [ngClass]="{ selected: activeTab === 'L4Tab' }"
          (click)="loadTabData('L4_DASHBOARDS')"
          (keyup.enter)="loadTabData('L4_DASHBOARDS')"
        >
          L4 Solution
        </li>
        <li
          id="pills-search-tab"
          ngbNavItem="SearchTab"
          ngbNavLink
          [routerLink]="[]"
          class="nav-item nav-link text-light border border-dark rounded tab-click"
          role="tab"
          [ngClass]="{ selected: activeTab === 'SearchTab' }"
          (click)="getSearchData(SearchKeyword.value)"
          (keyup.enter)="getSearchData(SearchKeyword.value)"
        >
          Search Result
        </li>
      </ul>
    </div>
    <div class="mr-1">
      <div class="input-group mb-3">
        <input
          #SearchKeyword
          (keyup.enter)="getSearchClick()"
          type="text"
          class="form-control"
          placeholder="Search Catalog"
          aria-label="Search Catalog"
        />
        <div class="input-group-append">
          <button
            type="button"
            class="btn btn-secondary border-dark rounded"
            (click)="getSearchClick()"
          >
            Search
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="row justify-content-center align-items-center"
    style="height: 85vh"
  >
    <div class="col-3" *ngIf="!isResponseReceived">
      <div class="progress">
        <div
          class="progress-bar progress-bar-striped progress-bar-animated bg-success"
          role="progressbar"
          [style.width]="progress"
        ></div>
      </div>
    </div>
    <div class="col-12" *ngIf="isResponseReceived">
      <div class="vertical-scroll table-responsive">
        <table class="table table-striped table-bordered">
          <thead class="thead-dark">
            <tr id="header-sort">
              <th id="h1" scope="col" width="13%">
                Name
                <a
                  class="fa fa-sort sort-align"
                  [routerLink]="[]"
                  (click)="getSortData('name')"
                  aria-label="Sort by name"
                ></a>
              </th>
              <th
                id="h2"
                scope="col"
                width="12%"
                *ngIf="activeTab === 'ALLTab' || activeTab === 'SearchTab'"
              >
                Type
                <a
                  class="fa fa-sort sort-align"
                  [routerLink]="[]"
                  (click)="getSortData('type')"
                  aria-label="Sort by type"
                ></a>
              </th>
              <th id="h3" scope="col" width="20%">
                Purpose
                <a
                  class="fa fa-sort sort-align"
                  [routerLink]="[]"
                  (click)="getSortData('purpose')"
                  aria-label="Sort by purpose"
                ></a>
              </th>
              <th id="h4" scope="col" width="9%">
                Environment
                <a
                  class="fa fa-sort sort-align"
                  [routerLink]="[]"
                  (click)="getSortData('environment')"
                  aria-label="Sort by environment"
                ></a>
              </th>
              <th id="h5" scope="col" width="9%">
                Data Classification
                <a
                  class="fa fa-sort sort-align"
                  [routerLink]="[]"
                  (click)="getSortData('dataClassification')"
                  aria-label="Sort by data classification"
                ></a>
              </th>
              <th id="h6" scope="col" width="7%">
                Access Method
                <a
                  class="fa fa-sort sort-align"
                  [routerLink]="[]"
                  (click)="getSortData('accessMethod')"
                  aria-label="Sort by access method"
                ></a>
              </th>
              <th id="h7" scope="col" width="7%">
                Security Group
                <a
                  class="fa fa-sort sort-align"
                  [routerLink]="[]"
                  (click)="getSortData('securityGroup')"
                  aria-label="Sort by security group"
                ></a>
              </th>
              <th id="h8" scope="col" width="7%">
                Owners
                <a
                  class="fa fa-sort sort-align"
                  [routerLink]="[]"
                  (click)="getSortData('owners')"
                  aria-label="Sort by owners"
                ></a>
              </th>
              <th id="h9" scope="col" width="6%">
                Data Source
                <a
                  class="fa fa-sort sort-align"
                  [routerLink]="[]"
                  (click)="getSortData('dataSource')"
                  aria-label="Sort by data source link"
                ></a>
              </th>
              <th id="h10" scope="col" width="6%">Alerting</th>
              <th id="h11" scope="col" width="4%">Edit</th>
            </tr>
          </thead>
          <tbody style="font-size: smaller">
            <tr *ngFor="let data of displayData">
              <td headers="h1">
                <a
                  class="link-text"
                  (click)="$event.stopPropagation()"
                  href="{{ data.url }}"
                  target="_blank"
                  >{{ data.name }}</a
                >
              </td>
              <ng-container
                *ngIf="activeTab === 'ALLTab' || activeTab === 'SearchTab'"
              >
                <td headers="h2">{{ data.type }}</td>
              </ng-container>
              <td headers="h3">{{ data.purpose }}</td>
              <td headers="h4">{{ data.environment }}</td>
              <td headers="h5">{{ data.dataClassification }}</td>
              <td headers="h6">{{ data.accessMethod }}</td>
              <td headers="h7">{{ data.securityGroup }}</td>
              <td headers="h8">{{ data.owners }}</td>
              <td headers="h9">{{ data.dataSource }}</td>
              <td headers="h10">{{ data.alerting ? "Yes" : "No" }}</td>
              <td headers="h11">
                <a
                  class="tab-click"
                  [routerLink]="[]"
                  attr.aria-label="Edit Solution {{ data.name }}"
                  (click)="tableRowClickNavigation(data.id)"
                  ><span class="fa fa-edit"></span
                ></a>
              </td>
            </tr>
            <tr *ngFor="let n of extraRows" class="m-0">
              <td></td>
              <td
                *ngIf="activeTab === 'ALLTab' || activeTab === 'SearchTab'"
              ></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
