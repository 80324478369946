import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AdministrationComponent } from './administration/administration.component';
import { QosViewComponent } from './qos-view/qos-view.component';
import { PowerBIModule } from '../power-bi/power-bi.module';
import { BamDashboardModule } from '../bam-dashboard/bam-dashboard.module';
import { AuthRoleGuard } from '../auth-role-guard';
import { UpdateComponent } from './update-component/update-component.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { AddComponent } from './add-component/add-component.component';
import { ManageIkeysComponent } from './manage-ikeys/manage-ikeys.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FeatureFlagGuard } from '../feature-flag-guard';

const qosRoutes: Routes = [
  {
    path: 'quality-of-service',
    component: HomeComponent,
    canActivate: [MsalGuard, FeatureFlagGuard],
    data: { featureFlag: 'quality-of-service'}
  },
  {
    path: 'quality-of-service/admin',
    component: AdministrationComponent,
    canActivate: [AuthRoleGuard, FeatureFlagGuard],
    data: { featureFlag: 'quality-of-service/admin' }
  },
  {
    path: 'quality-of-service/view',
    component: QosViewComponent,
    canActivate: [MsalGuard, FeatureFlagGuard],
    data: { featureFlag: 'quality-of-service/view'}
  },
  {
    path: 'quality-of-service/add-component',
    component: AddComponent,
    canActivate: [AuthRoleGuard, FeatureFlagGuard],
    data: { featureFlag: 'quality-of-service/admin' }
  },
  {
    path: 'quality-of-service/manage-ikeys',
    component: ManageIkeysComponent,
    canActivate: [AuthRoleGuard, FeatureFlagGuard],
    data: { featureFlag: 'quality-of-service/admin' }
  },
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PowerBIModule,
        NgbNavModule,
        RouterModule.forChild(qosRoutes),
        BamDashboardModule,
        NgbModule
    ],
    declarations: [
        HomeComponent,
        AdministrationComponent,
        QosViewComponent,
        UpdateComponent,
        AddComponent,
        ManageIkeysComponent
    ]
})
export class QualityOfServiceModule { }
