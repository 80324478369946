import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '../../common/constants';
import { AppConfig } from '../../model/app-config.model';
import { PageConfig, PageName, PageComponentConfig,
  ConfigType, PropertyTag, PagePropertyTagConfig } from '../model/page-configuration.model';

@Injectable({
  providedIn: 'root'
})
export class PageConfigService {
  private pageConfiguration: Array<PageConfig>;
  // private pagePropertyMap: Map<PageName, Array<PagePropertyMapping>>;
  private pageConfigMap: Map<PageName, Array<PageComponentConfig>>;
  private pagePropertyMap: Map<PageName, Map<PropertyTag, string>>;
  private propertyTags: Array<PagePropertyTagConfig>;

  constructor(
    @Inject(APP_CONFIG) appConfig: AppConfig
  ) {
    this.pageConfiguration = appConfig.pageConfig;
    this.propertyTags = appConfig.pagePropertyTag;
    this.processPageDisplayConfig();
    this.processPropertyTags();
    // this.processPagePropertyMapping();
  }

  public getPageDisplayConfig(pageName: PageName): Array<PageComponentConfig> {
    return this.pageConfigMap.get(pageName);
  }

  private processPageDisplayConfig(): void {
    this.pageConfigMap = new Map<PageName, Array<PageComponentConfig>>();
    for (const pageConfig of this.pageConfiguration) {
      let pageDisplayConfig = pageConfig.displayConfig;
      // return the component config in order of sequence number.
      pageDisplayConfig = pageDisplayConfig.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1);
      // return the component config if the element is enabled.
      pageDisplayConfig = pageDisplayConfig.filter(x => x.enabled);
      // filter display configs to include Display tab configs.
      pageDisplayConfig = pageDisplayConfig.filter(x => x.configType === ConfigType.DisplayTab);
      // for configs of type display tab, return tab name and component type.
      const compConfig = pageDisplayConfig.map(x => {
        const pageComponent = new PageComponentConfig();
        pageComponent.id = x.id;
        pageComponent.path = x.path;
        pageComponent.tabName = x.configName;
        pageComponent.componentType = x.componentType;
        return pageComponent;
      });
      this.pageConfigMap.set(pageConfig.pageName, compConfig);
    }
  }

  /**
   * This method parses the PagePropertyTag from appConfig and stores the
   * Property Tag and its corresponding display configs for each page.
   */
  private processPropertyTags(): void {
    this.pagePropertyMap = new Map<PageName, Map<PropertyTag, string>>();
    for (const pagePropertyTag of this.propertyTags) {
      const propertyComponentMap = new Map<PropertyTag, string>();
      for (const propertyTag of pagePropertyTag.propertyTags) {
        propertyComponentMap.set(propertyTag.propertyTag, propertyTag.propertyConfigs);
      }
      this.pagePropertyMap.set(pagePropertyTag.pageName, propertyComponentMap);
    }
  }

  /**
   * This method returns the ids of the components associated with the property tag.
   * @param pageName Name of the page on which the property exists.
   * @param propertyTag Property Tag to retrieve config ids for.
   * @returns ids of components associated with the property tag.
   */
  public getConfigByPropertyTag(pageName: PageName, propertyTag: PropertyTag): string {
    return this.pagePropertyMap.get(pageName).get(propertyTag);
  }

  public getConfigById(pageName: PageName, configId: string): PageComponentConfig {
    const displayConfig = this.getPageDisplayConfig(pageName);
    const componentConfig = displayConfig.find(x => x.id === configId);
    return componentConfig;
  }
}
