import { Inject, Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HierarchyTreeNode, Process } from '../../bam-dashboard/model/process.model';
import { MasterDataService } from '../../bam-dashboard/service/master-data.service';
import { AppConfig } from '../../model/app-config.model';
import { HierarchyTreeService } from '../../service/hierarchy-tree.service';
import { LocalCacheService } from '../../service/local-cache.service';
import { APP_CONFIG, constants } from '../constants';
import { TreeDataService } from '../interface/tree-data-service';
import { TreeLevelData, TreeNode } from '../model/tree-view.model';

@Injectable()
export class ServicetreeDataService implements TreeDataService {

  rootNode: TreeNode;
  levelsToLoad: number;

  constructor(
    private hierarchyTreeService: HierarchyTreeService,
    private masterDataService: MasterDataService,
    private localCacheService: LocalCacheService,
    @Inject(APP_CONFIG) appConfig: AppConfig,
  ) { 
    this.rootNode = new TreeNode();
    this.rootNode.id = appConfig.rootNodeId;
    this.rootNode.type = constants.ROOT;
    this.rootNode.level = 0;

    this.levelsToLoad = appConfig.hierarchyLevelsToLoad;
  }

  // This method backtracks the hierarchy from business process id and then creates the loading tree and return it.
  getHierarchyByProcessId(businessProcessId: string): Observable<any> {
    return this.masterDataService.getBusinessProcessById(businessProcessId).pipe(
      switchMap((process: Process) => {
        const mappingNodeId = process.teamGroupId;
        return forkJoin([this.getNode(mappingNodeId)]);
      }),
      switchMap((node: [TreeNode]) => {
        return forkJoin([this.getParentsById(node[0].id)]);
      }),
      switchMap((x: [TreeNode[]]) => {
        const parentNodes = x[0];
        // set tree values in cache
        // set business process in cache
        let treeLevelDataList = new Array<TreeLevelData>();
        const processData = new TreeLevelData();
        processData.id = businessProcessId;
        processData.type = constants.BUSINESS_PROCESS;
        treeLevelDataList.push(processData);
        let count=1;
        parentNodes.forEach((node: TreeNode) => {
          if (count <= this.levelsToLoad) {
            // set node in cache
            const data = new TreeLevelData();
            data.id = node.id;
            data.type = node.type;
            treeLevelDataList.push(data);
            count++;
          }
        }); 
        this.localCacheService.setItem("SELECTED_ITEM", treeLevelDataList);
        
        // get observables of children for each parent and return
        let obsArr = new Array<Observable<TreeNode[]>>();
        obsArr.push(of(parentNodes));
        parentNodes.forEach((node: TreeNode) => {
          obsArr.push(this.getChildren(node));
        });
        return forkJoin(obsArr);
      }),  
      map((x: Array<TreeNode[]>) => {
        const parentNodes = x[0];
        // assign children to all parent nodes
        for(let i=0; i<parentNodes.length;i++) {
          parentNodes[i].children = x[i+1];
        }
        // map all the children to root node
        for(let i=0; i<parentNodes.length-1;i++) {
          const selectedNode = parentNodes[i+1].children.find(y => y.id == parentNodes[i].id);
          selectedNode.children = parentNodes[i].children;
          selectedNode.isCollapsed = false;
          // select the process
          const selectedProcess = parentNodes[i].children.find(y => y.id == businessProcessId && y.type == constants.BUSINESS_PROCESS);
          if (selectedProcess != null) {
            selectedProcess.isSelected = true;
          }
        }
        // determine the root elements to display and return
        const sourceNodes = parentNodes[parentNodes.length-1].children;
        let sourceNodeArray = new Array<TreeNode>();
        sourceNodeArray = Object.assign([], sourceNodes);
        return sourceNodeArray;
      })
    )
  }

  getChildren(item: TreeNode): Observable<TreeNode[]> {
    if (item.level == this.levelsToLoad) {
      return this.getBusinessProcessList(item.id).pipe(
        map(
          (processes: Process[]) => {
            const processList = new Array<TreeNode>();
            processes.forEach((element: Process) => {
              const node = new TreeNode();
              node.type = constants.BUSINESS_PROCESS;
              node.id = element.id;
              node.displayName = element.name;
              node.hasChildren = false;
              node.parent = item;
              node.isSelected = false;
              node.isCollapsed = true;
              processList.push(node);
            });
            return processList;
          }
        )
      );
    } else {
      return this.hierarchyTreeService.getTreeNodeChildren(item.id).pipe(
        map((node: HierarchyTreeNode[]) => {
          const childrenList = new Array<TreeNode>();
          node.forEach((element: HierarchyTreeNode) => {
            const childNode = new TreeNode();
            childNode.type = element.nodeType;
            childNode.id = element.internalId;
            childNode.displayName = element.nodeName;
            childNode.hasChildren = true;     
            childNode.parent = null;
            childNode.level =  (element.id.split("/").length - 2);
            childNode.isSelected = false;
            childNode.isCollapsed = true;
            childrenList.push(childNode);
          });
          return childrenList;
        })
      );
    }
  }

  getParentsById(nodeId: string): Observable<TreeNode[]> {
    return this.hierarchyTreeService.getTreeNodeParents(nodeId).pipe(
      map((nodes: HierarchyTreeNode[]) => {
        let parentList = new Array<TreeNode>();
        nodes.forEach((element: HierarchyTreeNode) => {
          const parentNode = new TreeNode();
          parentNode.type = element.nodeType;
          parentNode.id = element.internalId;
          parentNode.displayName = element.nodeName;
          parentNode.hasChildren = true;     
          parentNode.parent = null;
          parentNode.level =  (element.id.split("/").length - 2);
          parentNode.isSelected = false;
          parentNode.isCollapsed = false;
          parentList.push(parentNode);
        });
        // trim the list to load only n.of levels configured in configuration
        const temp = parentList.reverse();
        const response= temp.slice(0, this.levelsToLoad+1).reverse();
        return response;
      })
    );
  }

  getNode(mappingNodeId: string): Observable<TreeNode> {
    return this.hierarchyTreeService.getTree(mappingNodeId).pipe(
      map((x : HierarchyTreeNode[]) => {
        let node = new TreeNode();
        node.type = x[0].nodeType;
        node.id = x[0].internalId;
        node.displayName = x[0].nodeName;
        return node;
      })
    );
  }

  getBusinessProcessList(internalId: string): Observable<Process[]> {
    return this.hierarchyTreeService.getTreeNodeById(internalId).pipe(
      switchMap((node: HierarchyTreeNode) => {
        const mappingNodeId = node.nodeId;
        return this.masterDataService.getBusinessProcessList(mappingNodeId);
      })
    );
  }
}
