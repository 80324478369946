import {
  Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit,
  ViewChild, ElementRef, ChangeDetectorRef, Input
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TreeviewItem, TreeviewConfig, DefaultTreeviewI18n, TreeviewComponent } from 'ngx-treeview';
import { Subscription } from 'rxjs';
import { TreeConfig, TreeLevelData, TreeSelection, TreeviewItemValue } from '../model/tree-view.model';
import { TreeviewService } from '../service/treeview.service';

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss'],
})
export class TreeViewComponent extends DefaultTreeviewI18n implements OnInit, OnDestroy, AfterViewInit {
  @Input() loadProcess: boolean;
  @Output() valueChange = new EventEmitter<TreeSelection>();

  @ViewChild('headerDiv') headerElement: ElementRef<HTMLElement>;
  @ViewChild('footerElement') footerElement: ElementRef<HTMLElement>;
  @ViewChild('treeViewDivElement') treeViewDivElement: ElementRef<HTMLElement>;
  @ViewChild('titleLabel') titleLabelElement: ElementRef<HTMLElement>;
  @ViewChild('selectItem') selectItemElement: ElementRef<HTMLInputElement>;
  @ViewChild('treeView') treeViewElement: TreeviewComponent;

  subscriptions: Subscription;

  filterText = new UntypedFormControl('');
  title: string;
  config: TreeviewConfig;
  items: TreeviewItem[];

  private selectedItem: TreeviewItem;
  private lastSelectedItem: TreeviewItem;

  isTreeViewCollapsed: boolean;
  isTreeLoaded: boolean;
  isLoadingChildren: boolean;
  loadingItem: string;
  lastFocusElementId: string;

  constructor(
    private treeviewService: TreeviewService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
    this.filterText.setValue("");
    this.config = new TreeviewConfig();
    this.items = [];
    this.isTreeLoaded = false;
    this.isLoadingChildren = false;
    this.loadingItem = "";
    this.isTreeViewCollapsed = false;
    this.subscriptions = new Subscription();
  }

  ngOnInit(): void {

    const configSubscription = this.treeviewService.getTreeConfigSubject().subscribe(
      (config: TreeConfig) => {
        this.setConfig(config);
      }
    );
    this.subscriptions.add(configSubscription);

    const nodesSubscription = this.treeviewService.getTreeNodesSubject().subscribe(
      (items: TreeviewItem[]) => {
        this.setTreeViewItems(items);
        setTimeout(() => {
          this.setFocus();
        }, 1);
      }
    );
    this.subscriptions.add(nodesSubscription);

    const filterTextChangeSubscription = this.filterText.valueChanges.subscribe(
      (value: string) => {
        this.treeViewElement.onFilterTextChange(value);
      });
    this.subscriptions.add(filterTextChangeSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    this.titleLabelElement.nativeElement.focus();
  }

  setFocus(): void {
    if (this.lastFocusElementId) {
      const felement: HTMLElement = document.getElementById(this.lastFocusElementId) as HTMLElement;
      if (felement !== null) {
        felement.focus();
      }
    }
  }

  setTreeViewItems(nodes: TreeviewItem[]): void {
    this.setNodeProperties(nodes);
    this.items = nodes;

    this.isTreeLoaded = true;
    if (this.isLoadingChildren) {
      this.isLoadingChildren = false;
      this.loadingItem = "";
    }
  }

  setNodeProperties(nodes: TreeviewItem[]) {
    for (let index = 0; index < nodes.length; index++) {
      const node = nodes[index] as TreeviewItem;
      const value = node.value as TreeviewItemValue;

      // Need to set last selected item.
      if (value.isSelected === true) {
        this.lastSelectedItem = node;
        this.selectItem(node);
        break;
      }

      if (node.children) {
        this.setNodeProperties(node.children);
      }
    }
  }

  setConfig(treeConfig: TreeConfig): void {
    this.title = treeConfig.title;
    this.config = treeConfig;
  }

  clickItem(item: TreeviewItem): void {
    const value = item.value as TreeviewItemValue;
    if (item.children === undefined && !value.hasChildren) {
      // When children is undefined and hasChildren is false (Meaning: Leaf level of tree)
      this.lastSelectedItem = item;
      this.selectItemElement.nativeElement.disabled = false;
      this.selectItemElement.nativeElement.focus();
    } else {
      // When children property is null and hasChildren is false
      // (Mean: Service don't have children and Not on leaf level), disable the "Select Item" button.
      this.selectItemElement.nativeElement.disabled = true;
    }
  }

  okClick() {
    if (!this.lastSelectedItem.children) {
      this.selectItem(this.lastSelectedItem);
    }
  }

  // This method is for loading children from service
  getChildrens(item: TreeviewItem) {
    const val = item.value as TreeviewItemValue;

    // Set loading parameters
    this.loadingItem = val.id;
    this.isLoadingChildren = true;

    // Emit the value of item to load children from service and change isCollapsed value
    this.treeviewService.expandTreeViewChildrenFromService(val.id, this.loadProcess);

    this.lastFocusElementId = val.id;
  }

  private selectItem(item: TreeviewItem): void {
    const itemValue = item?.value as TreeviewItemValue;
    const selectedValue = this.selectedItem?.value as TreeviewItemValue;
    if (item !== undefined && selectedValue?.id !== itemValue?.id) {
      this.selectedItem = item;
      const emitValue = this.getSelectionHierarchy(item);
      this.treeviewService.selectItemEvent(emitValue);
      this.valueChange.emit(emitValue);
    }
  }

  getSelectionHierarchy(selecteditem: TreeviewItem): TreeSelection {
    const treeLevelDataList = new Array<TreeLevelData>();
    let itemText = selecteditem.text;
    let value = selecteditem.value as TreeviewItemValue;
    while (value != null) {
      const levelData = new TreeLevelData();
      levelData.id = value.id;
      levelData.name = itemText;
      levelData.type = value.type;
      treeLevelDataList.push(levelData);
      itemText = value?.parent?.text;
      value = value?.parent?.value;
    }
    const treeSelection = new TreeSelection();
    treeSelection.selectionHierarchy = treeLevelDataList;
    return treeSelection;
  }

  getSelectedText(): string {
    return this.selectedItem ? this.selectedItem.text : "Select " + this.title;
  }

  toggleTreeView(): void {
    this.isTreeViewCollapsed = !this.isTreeViewCollapsed;
  }

  resizeTree() {
    const newHeight = this.treeViewDivElement.nativeElement.clientHeight;
    if (!this.isTreeViewCollapsed) {
      const adjustedHeight = this.getActualHeight(newHeight);
      this.changeDetectorRef.detectChanges();
      this.config.maxHeight = adjustedHeight;
    }
  }

  getActualHeight(height: number): number {
    let subtractHeight = 0;
    if (this.headerElement?.nativeElement !== undefined) {
      subtractHeight += this.headerElement.nativeElement.clientHeight;
    }

    if (this.footerElement?.nativeElement !== undefined) {
      subtractHeight += this.footerElement.nativeElement.clientHeight;
    }

    return height - subtractHeight;
  }
}
