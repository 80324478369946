import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserDataService } from './service/user-data.service';
import { NgbModalErrorComponent } from './modal/ngb-modal-error.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Injectable()
export class AuthRoleGuard implements CanActivate {

  constructor(private userDataService: UserDataService, private modalService: NgbModal, private appInsightService: ApplicationInsights) { }

  canActivate(route: ActivatedRouteSnapshot) {
    return this.userDataService.checkUserAccess(route.routeConfig.path, 'Read').pipe(map((response: boolean) => {
      if (response) {
        return true;
      } else {
        const errorModalForUnauth = this.modalService.open(NgbModalErrorComponent);
        errorModalForUnauth.componentInstance.message = "Unauthorized to access";
        const unAuthorizedException
        = new Error(`user ${this.userDataService.name} does not have read access for path: ${route.routeConfig.path}`);
        unAuthorizedException.name = 'UnAuthorizedReadException';
        this.appInsightService.trackException({exception: unAuthorizedException});
        return false;
      }
    }));
  }
}
