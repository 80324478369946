import { Component, OnInit } from '@angular/core';
import { constants } from '../common/constants';

@Component({
  selector: 'app-scenario-health',
  templateUrl: './scenario-health.component.html',
  styleUrls: ['./scenario-health.component.css', '../../../style/common-styles.scss']
})
export class ScenarioHealthComponent implements OnInit {

  dashboardId: number;

  constructor() {
    this.dashboardId = constants.ScenarioHealthDashboard;
  }

  ngOnInit() {
  }
}
