import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appOwnerValidation]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: OwnerValidatorDirective,
      multi: true,
    }
  ]
})
export class OwnerValidatorDirective implements Validator {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('appOwnerValidation') isOwnerValid: string;

  constructor() { }

  validate(control: AbstractControl): {[key: string]: any} | null {
    // console.log('value received', this.isOwnerValid);
    return (this.isOwnerValid === 'valid') ? null : {'controlValue': {value: control.value}};
  }
}
