import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { EventCatalogComponent } from './event-catalog/event-catalog.component';
import { AuthRoleGuard } from '../auth-role-guard';
import { FeatureFlagGuard } from '../feature-flag-guard';
import { SharedModule } from '../common/shared.module';
import { EventDetailsComponent } from './event-details/event-details.component';
import { SubscriptionDetailsComponent } from './subscription-details/subscription-details.component';
import { SaveEventComponent } from './save-event/save-event.component';
import { SaveSubscriptionComponent } from './save-subscription/save-subscription.component';
import { HomeComponent } from '../event-broker/home/home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EventActivityComponent } from './event-activity/event-activity.component';
import { PowerBIModule } from '../power-bi/power-bi.module';


// routes for event broker.
const eventBrokerRoutes: Routes = [
  {
    path: 'event-broker',
    component: HomeComponent,
    canActivate: [FeatureFlagGuard],
    data: { featureFlag: 'event-broker'}
  },
  {
    path: 'event-broker/event-activity',
    component: EventActivityComponent,
    canActivate: [AuthRoleGuard, FeatureFlagGuard],
    data: { featureFlag: 'event-broker/event-activity'}
  },
  {
    path: 'event-broker/event-catalog',
    component: EventCatalogComponent,
    canActivate: [AuthRoleGuard, FeatureFlagGuard],
    data: { featureFlag: 'event-broker/event-catalog'}
  },
  {
    path: 'event-broker/event/:id',
    component: EventDetailsComponent,
    canActivate: [AuthRoleGuard, FeatureFlagGuard],
    data: { featureFlag: 'event-broker/event-catalog'}
  },
  {
    path: 'event-broker/event/:id/:action',
    component: EventDetailsComponent,
    canActivate: [AuthRoleGuard, FeatureFlagGuard],
    data: { featureFlag: 'event-broker/event-catalog'}
  },
  {
    path: 'event-broker/event/:eventId/subscription/:id',
    component: SubscriptionDetailsComponent,
    canActivate: [AuthRoleGuard, FeatureFlagGuard],
    data: { featureFlag: 'event-broker/event-catalog'}
  },
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
    PowerBIModule,
    RouterModule.forChild(eventBrokerRoutes),
  ],
  declarations: [
    EventCatalogComponent,
    EventDetailsComponent,
    SubscriptionDetailsComponent,
    SaveEventComponent,
    SaveSubscriptionComponent,
    EventActivityComponent
  ],
})
export class EventBrokerModule { }
