import { Subscription, timer } from 'rxjs';
import { Graph } from './graph';
import { InstancePath } from '../../model/graph.model';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export class GraphInstance extends Graph {
  protected instancePath: InstancePath;

  private animateNetworkPathSubscription$: Subscription;
  private animateEdgeIndex: number;
  private animateEdgePath: Array<string>;
  private demoPath: Array<string>;
  private pathExpand: boolean;
  private expandTick: boolean;

  private currentPathDescription: string;
  private graphDescription: string;

  constructor(appInsightsService: ApplicationInsights) {
    super(appInsightsService);

    this.instancePath = {} as InstancePath;

    this.animateEdgeIndex = 0;
    this.animateEdgePath = new Array<string>();

    this.demoPath = new Array<string>();
    this.pathExpand = true;
    this.expandTick = false;
  }

  enablePathAnimation(): void {
    this.animateNetworkPathSubscription$ = timer(0, 600).subscribe(
      () => {
        if (!this.expandTick) {
          if (this.pathExpand) {
            this.changeArrowType(this.animateEdgePath[this.animateEdgeIndex], false);
            this.demoPath.push(this.animateEdgePath[this.animateEdgeIndex]);
            this.animateEdgeIndex += 1;
            if (this.animateEdgeIndex === this.animateEdgePath.length) {
              this.pathExpand = false;
            }
          }
          this.expandTick = true;
        } else {
          this.expandTick = false;
        }
        this.animateEdges(this.demoPath);
      });
  }

  disablePathAnimation(): void {
    if (this.animateNetworkPathSubscription$) {
      this.animateNetworkPathSubscription$.unsubscribe();

      this.animateEdgeIndex = 0;
      this.demoPath.forEach(edgeId => {
        this.changeArrowType(edgeId, true);
      });
      this.demoPath = new Array<string>();
      this.pathExpand = true;
      this.expandTick = false;
    }
  }

  private setAnimationEdgePath(edgePath: Array<string>): void {
    this.animateEdgePath = edgePath;
  }

  getAnimationEdgePath(): Array<string> {
    return this.animateEdgePath;
  }

  setInstancePath(instancePath: InstancePath) {
    this.instancePath = instancePath;
    this.setAnimationEdgePath(instancePath.edgeIdPath);
    this.appInsightsService.trackTrace({ message: `instance path set as ${JSON.stringify(instancePath)}`});
  }

  getInstancePath() {
    return this.instancePath;
  }

  /**
   * generates accessible alt text for process flow graph.
   */
  generateGraphDescription() {
    const numberNodes = this.nodes.length;
    const numberEdges = this.edges.length;
    this.graphDescription = `the process flow graph contains ${numberNodes} nodes and ${numberEdges} edges`;
  }

  getGraphDescription() {
    return this.graphDescription;
  }

  /**
   * generates alt text for process flow current path.
   */
  generateCurrentPathDescription() {
    this.currentPathDescription = 'The transaction has completed the following lifecycle events: ';
    for (const nodes of this.instancePath.nodeEventPath) {
      this.currentPathDescription += nodes + ', ';
    }
  }

  getCurrentPathDescription() {
    return this.currentPathDescription;
  }
}
