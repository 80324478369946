import { Component, OnInit, Input } from '@angular/core';
import { RuleFormService } from '../../../service/rule-form.service';
import { UntypedFormGroup } from '@angular/forms';
import { InstanceFilters } from '../../../model/process.model';

@Component({
  selector: 'app-labelled-input',
  templateUrl: './labelled-input.component.html',
  styleUrls: ['./labelled-input.component.css']
})
export class LabelledInputComponent implements OnInit {
  // label for displaying input.
  @Input() instanceFilter: InstanceFilters;
  @Input() formGroupIndex: number;

  formGroup: UntypedFormGroup;

  constructor(
    private formService: RuleFormService,
    ) {}

  ngOnInit() {
    this.formGroup = this.formService.getConditionSet(this.formGroupIndex);
  }

}
