import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-display-object',
  templateUrl: './display-object.component.html',
  styleUrls: ['./display-object.component.scss']
})
export class DisplayObjectComponent implements OnInit {
  private _displayObject: object;
  @Input() set displayObject(value: object) {
    this._displayObject = value;
  }
  get displayObject(): object {
    if (this._displayObject != null) {
      return this._displayObject;
    } else {
      return {};
    }
  }
  @Input() isObjectExpanded: boolean;

  constructor() {
    this.displayObject = {};
  }

  ngOnInit() {
    this.isObjectExpanded = true;
  }

}
