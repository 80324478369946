<h5 #pageTitle class="display-5 mt-3 ml-3">
    <ng-container *ngIf="!isUpdate">Add Template Variable</ng-container>
    <ng-container *ngIf="isUpdate">Update Template Variable</ng-container>
</h5>
<hr>

<!-- loader -->
<div *ngIf="isTemplateVariableLoading">
    <span class="fa fa-spinner fa-spin fa-2x ml-2" role="progressbar" aria-label="Loading"></span>
</div>

<!-- main content. -->
<ng-container *ngIf="!isTemplateVariableLoading">
    <form class="ml-3" [formGroup]="templateVariableForm">
        <!-- name -->
        <div class="input-group input-group-sm">
            <div class="input-group-prepend">
                <label class="input-group-text" id="nameLabel" style="width: 11rem;">
                    Name
                    <span class="danger-text">*</span>
                </label>
            </div>
            <input type="text" class="form-control required input-width" formControlName="name"
                aria-label="Template Variable Name" aria-describedby="nameLabel">
        </div>
        <div *ngIf="templateVariableForm.controls.name.invalid && (templateVariableForm.controls.name.dirty || templateVariableForm.controls.name.touched)"
            class="danger-text" role="alert" aria-live="assertive">
            <small>Template Variable name is required.</small>
        </div>
        <!-- Description -->
        <div class="input-group input-group-sm mt-2">
            <div class="input-group-prepend">
                <label class="input-group-text" id="componentNameLabel" style="width: 11rem;">Description</label>
            </div>
            <textarea class="form-control input-width-wide" formControlName="description"
                aria-label="Template Variable Value" aria-describedby="valueLabel"></textarea>
        </div>
        <!-- language type. -->
        <div class="input-group input-group-sm mt-2">
            <div class="input-group-prepend">
                <label class="input-group-text" id="languageTypeLabel" style="width: 11rem;">
                    Language Type
                    <span class="danger-text">*</span>
                    <span class="fa fa-info-circle ml-2"
                        ngbPopover="Template variables can only be used in datasources with compatible language types.">
                    </span>
                </label>
            </div>
            <div class="form-check form-check-inline ml-2">
                <input class="form-check-input" type="radio" id="kustoRadioButton" value="KUSTO" formControlName="languageType"
                    aria-label="kusto">
                <label class="form-check-label" for="kustoRadioButton">Kusto</label>

                <input class="form-check-input ml-2" type="radio" id="sqlRadioButton" value="SQL" formControlName="languageType"
                    aria-label="SQL">
                <label class="form-check-label" for="sqlRadioButton">SQL</label>

                <input class="form-check-input ml-2" type="radio" id="constantRadioButton" value="CONSTANT" formControlName="languageType"
                    aria-label="constant">
                <label class="form-check-label" for="constantRadioButton">Constant</label>
            </div>
        </div>
        <div *ngIf="templateVariableForm.controls.languageType.invalid && (templateVariableForm.controls.languageType.dirty || templateVariableForm.controls.languageType.touched)"
            class="danger-text mt-0" role="alert" aria-live="assertive">
            <small>Atleast one language type is required.</small>
        </div>
        <!-- Value. -->
        <div class="input-group input-group-sm mt-2">
            <div class="input-group-prepend">
                <label class="input-group-text" id="valueLabel" style="width: 11rem;">
                    Value
                    <span class="danger-text">*</span>
                </label>
            </div>
            <textarea class="form-control required input-width-wide" aria-label="Template Variable Value"
                aria-describedby="valueLabel" formControlName="variableValue"></textarea>
        </div>
        <div *ngIf="templateVariableForm.controls.variableValue.invalid && (templateVariableForm.controls.variableValue.dirty || templateVariableForm.controls.variableValue.touched)"
            class="danger-text mt-0" role="alert" aria-live="assertive">
            <small>Template Variable value is required.</small>
        </div>

        <ng-container *ngIf="isUpdate">
            <!-- last modified by. -->
            <div class="input-group input-group-sm mt-2">
                <div class="input-group-prepend">
                    <label class="input-group-text" id="modifiedByLabel" style="width: 11rem;">
                        Last Modified By
                    </label>
                </div>
                <input type="text" class="form-control input-width" formControlName="updatedBy"
                    aria-label="Last Modified By" aria-describedby="modifiedByLabel" readonly>
            </div>

            <!-- last modified on. -->
            <div class="input-group input-group-sm mt-2">
                <div class="input-group-prepend">
                    <label class="input-group-text" id="modifiedOnLabel" style="width: 11rem;">
                        Last Modified On
                        <span class="fa fa-info-circle ml-2" ngbPopover="Last Modified Time in UTC.">
                        </span>
                    </label>
                </div>
                <input type="text" class="form-control input-width" formControlName="validFrom"
                    aria-label="Last Modified On" aria-describedby="modifiedOnLabel" readonly>
            </div>
        </ng-container>
    </form>

    <!-- Save buttons. -->
    <div class="d-flex flex-row mt-3 ml-1">
        <button type="button" class="btn btn-sm primary-button" (click)="saveTemplateVariable(isUpdate)"
            aria-label="Save Template Variable" [disabled]="templateVariableForm.invalid || isOperationLoading">
            <ng-container *ngIf="!isUpdate">Save</ng-container>
            <ng-container *ngIf="isUpdate">Update</ng-container>
        </button>
        <ng-container *ngIf="isUpdate">
            <button type="button" class="btn btn-sm danger-button ml-1" (click)="delete()"
                aria-label="Delete Template Variable" [disabled]="isOperationLoading">Delete</button>
        </ng-container>
        <button type="button" class="btn btn-sm secondary-button ml-2" (click)="cancel()"
            aria-label="Navigate back to list." [disabled]="isOperationLoading">Back</button>
        <div *ngIf="isOperationLoading">
            <span class="fa fa-spinner fa-spin fa-2x ml-2" role="progressbar" aria-label="Loading"></span>
        </div>
    </div>

    <div class="ml-2" *ngIf="isUpdate">
        <h6 class="display-6 mt-2">Used by Rules:</h6>
        <!-- used template variable rules. -->
        <div class="mt-1" style="width: 52rem;">
            <app-display-table-v2 [tableInput]="tableData.asObservable()" [tableOptions]="tableOptions"
                (rowEditEvent)="showError($event)">
            </app-display-table-v2>
        </div>
    </div>
</ng-container>