import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Dashboard } from '../model/dashboard.model';
import { CatalogService } from '../service/catalog.service';
import { Router } from '@angular/router';
import { NgbModalErrorComponent } from '../../modal/ngb-modal-error.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-browse-catalog',
  templateUrl: './browse-catalog.component.html',
  styleUrls: ['./browse-catalog.component.css', '../../../style/common-styles.scss']
})
export class BrowseCatalogComponent implements AfterViewInit {
  @ViewChild('pillsAllTab') pillsFirstTab: ElementRef<HTMLElement>;

  // Properties
  activeTab: string;
  extraRows: number[];
  needRows: number;
  allData: Dashboard[];
  displayData: Dashboard[];
  lastSortedColumn = "NONE";
  lastSortDirection = "NONE";
  progress: string;

  // checking if table data is received.
  isResponseReceived: boolean;

  constructor(
    private router: Router,
    private catalogService: CatalogService,
    private modalService: NgbModal
  ) {
    this.isResponseReceived = false;
    this.progress = "95%";
    this.getAllData();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.pillsFirstTab.nativeElement.focus();
    }, 1);
  }

  calculateExtraRows(listData: Dashboard[]): void {
    this.extraRows = new Array();
    if (listData.length < 30) {
      this.needRows = 29 - listData.length;
      this.extraRows.length = this.needRows;
    } else {
      this.extraRows.length = 0;
    }
  }

  loadTabData(tabData: string): void {
    this.displayData = [];
    // Switch case statements to fetch the respective tab data.
    switch (tabData) {
      case "ALL_DATA":
        this.getAllData();
        break;
      case "L1_DASHBOARDS":
        this.activeTab = "L1Tab";
        this.displayData = this.getDashboardData("L1: Infrastructure Availability & Resources Monitoring");
        this.getSortData('name');
        this.calculateExtraRows(this.displayData);
        break;
      case "L2_DASHBOARDS":
        this.activeTab = "L2Tab";
        this.displayData = this.getDashboardData("L2: Service Component Health Monitoring (QoS)");
        this.getSortData('name');
        this.calculateExtraRows(this.displayData);
        break;
      case "L3_DASHBOARDS":
        this.activeTab = "L3Tab";
        this.displayData = this.getDashboardData("L3: Business Activity / Process Monitoring (BAM/BPM)");
        this.getSortData('name');
        this.calculateExtraRows(this.displayData);
        break;
      case "L4_DASHBOARDS":
        this.activeTab = "L4Tab";
        this.displayData = this.getDashboardData("L4: KPIs Monitoring (BI)");
        this.getSortData('name');
        this.calculateExtraRows(this.displayData);
        break;
      default:
        this.getAllData();
    }
  }

  getDashboardData(dashboardType: string): Dashboard[] {
    try {
      // Filtering tab data by type
      this.lastSortedColumn = "NONE";
      this.lastSortDirection = "NONE";

      return this.allData.filter((dashBoard: Dashboard) =>
        dashBoard.type.indexOf(dashboardType) !== -1);
    } catch (e) {
      return null;
    }
  }

  getAllData(): void {
    try {
      this.activeTab = "ALLTab";
      this.allData = [];
      this.lastSortedColumn = "NONE";
      this.lastSortDirection = "NONE";

      // Getting new data for All Tab.
      this.catalogService.getCatalog().subscribe(
        allData => {
          this.allData = allData;
          this.displayData = allData;
        },
        (error) => {
          const errorModal = this.modalService.open(NgbModalErrorComponent);
          if (error.statusCode === 403) {
            errorModal.componentInstance.message = "Access denied";
          }
        },
        // Calculate the extra rows once subriber complete observable loading.
        // Third call back of observable notify the subscription completion.
        () => {
          this.calculateExtraRows(this.allData);
          this.getSortData('name');
          // setting to true, to stop the loader and display the table.
          this.progress = "100%";
          this.isResponseReceived = true;
        }
      );
    } catch (e) {
      console.error("Error:", e);
      this.modalService.open(NgbModalErrorComponent);
      return null;
    }
  }

  getSearchClick() {
    // This method triggers the search tab click to get the focus of search tab.
    const searchElement: HTMLElement = document.getElementById('pills-search-tab') as HTMLElement;
    searchElement.click();
  }

  getSearchData(keyword: string): void {
    try {
      // This method is use to search the data by calling Catalog Service.
      this.activeTab = "SearchTab";
      this.displayData = [];
      this.lastSortedColumn = "NONE";
      this.lastSortDirection = "NONE";

      // Getting new data for searched keyword.
      if (typeof keyword !== "undefined" && keyword) {
        this.catalogService.getCatalogBySearch(keyword).subscribe(
          searchData => {
            this.displayData = searchData;
          },
          (err) => {
            this.modalService.open(NgbModalErrorComponent);
          },
          () => {
            this.calculateExtraRows(this.allData);
            this.getSortData('name');
          },
        );
      } else {
        // If search keyword is null or empty then it will display all the record.
        this.displayData = this.allData;
        this.getSortData('name');
        this.calculateExtraRows(this.displayData);
      }
    } catch (e) {
      console.error("Error:", e);
      this.modalService.open(NgbModalErrorComponent);
    }
  }

  tableRowClickNavigation(dashboardID: number) {
    // Method is taking the dashboard's ID of the clicked row and navigate to the update dashboard page.
    this.router.navigate(['/monitoring-solution/update-dashboard', dashboardID]);
  }

  getSortData(columnName: keyof Dashboard): void {
    // This method gets the column name and sort the table based on the last sorted column and last sort direction.
    if (this.lastSortedColumn === "NONE" || this.lastSortedColumn !== columnName) {
      this.lastSortedColumn = columnName;
      this.lastSortDirection = "ASC";
      this.displayData.sort((v1, v2) => this.sortColumn(v1[columnName], v2[columnName]));
    } else if (this.lastSortedColumn === columnName) {
      if (this.lastSortDirection === "ASC") {
        this.lastSortDirection = "DESC";
        this.displayData.sort((v1, v2) => this.sortColumn(v1[columnName], v2[columnName])).reverse();
      } else {
        this.lastSortDirection = "ASC";
        this.displayData.sort((v1, v2) => this.sortColumn(v1[columnName], v2[columnName]));
      }
    }
  }

  sortColumn(v1: string, v2: string) {
    // This method sort the column data. Comparator method.
    v1 = v1.toLowerCase();
    v2 = v2.toLowerCase();
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }
}

