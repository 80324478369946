import { Directive, Output, EventEmitter, ElementRef, OnDestroy } from '@angular/core';
import { ResizeObserver } from '@juggle/resize-observer';

@Directive({
  selector: '[appDivDimChange]'
})
export class DivDimensionChangesDirective implements OnDestroy {
  @Output() public divDimChange = new EventEmitter<boolean>();
  private divChange: ResizeObserver;

  private elementWidth: number;
  private elementHeight: number;
  private firstRun: boolean;
  private resizeObserverCheck: boolean;

  constructor(private elementRef: ElementRef) {
    const divElement = this.elementRef.nativeElement as HTMLDivElement;
    this.elementHeight = divElement.clientHeight;
    this.elementWidth = divElement.clientWidth;
    this.firstRun = true;

    this.resizeObserverCheck = typeof (ResizeObserver) !== 'undefined';

    if (this.resizeObserverCheck) {
      this.divChange = new ResizeObserver(_ => {
        const dims = divElement.getBoundingClientRect();
        if (dims.height !== 0 && dims.width !== 0) {
          if (this.firstRun) {
            this.elementHeight = dims.height;
            this.elementWidth = dims.width;
            this.firstRun = false;
          } else if (this.elementHeight !== dims.height || this.elementWidth !== dims.width) {
            this.elementWidth = dims.width;
            this.elementHeight = dims.height;
            this.divDimChange.emit(true);
          }
        }
      });

      this.divChange.observe(divElement);
    }
  }

  ngOnDestroy(): void {
    if (this.resizeObserverCheck) {
      this.divChange.disconnect();
    }
  }
}
