<div class="modal-header">
    <h4 class="modal-title">Update Operation</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancelUpdate()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="componentForm">
        <!-- service oid - readonly -->
        <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
                <label class="input-group-text" id="serviceOidLabel" style="width: 9rem;">Service Oid</label>
            </div>
            <input type="text" formControlName="serviceOid" class="form-control" aria-label="Service Oid"
                aria-describedby="telemetryNameLabel" readonly>
        </div>
        <!-- service name - readonly -->
        <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
                <label class="input-group-text" id="serviceNameLabel" style="width: 9rem;">Service Name</label>
            </div>
            <input type="text" formControlName="serviceName" class="form-control" aria-label="Service Name"
                aria-describedby="serviceNameLabel" readonly>
        </div>
        <fieldset [disabled]="isLoading">
          <!-- telemetry name - editable. -->
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <label class="input-group-text" id="telemetryNameLabel" style="width: 9rem;">
                Telemetry Name
                <span class="danger-text ml-1" aria-label="Required Field">*</span>
              </label>
            </div>
            <input type="text" formControlName="telemetryName" class="form-control" aria-label="Telemetry Name"
                   aria-describedby="telemetryNameLabel" aria-required="true">
          </div>
          <!-- latency threshold - editable -->
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <label class="input-group-text" id="latencyThresholdLabel" style="width: 9rem;">
                Latency
                Threshold
                <span class="danger-text ml-1" aria-label="Required Field">*</span>
              </label>
            </div>
            <input type="number" formControlName="latencyThreshold" class="form-control required" aria-required="true"
                   aria-label="Latency Threshold" aria-describedby="latencyThresholdLabel">
            <div class="input-group-append">
              <label class="input-group-text" id="latencyThresholdLabelAppend">ms</label>
            </div>
          </div>
          <!-- critical - editable. -->
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <label class="input-group-text" id="criticalLabel" style="width: 9rem;">Critical</label>
            </div>
            <select class="custom-select" id="criticalOptionSelect" formControlName="critical" aria-label="critical dropdown">
              <option disabled hidden>Select Value</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <!-- customers - editable. -->
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <label class="input-group-text" id="customerLabel" style="width: 9rem;">Customers</label>
            </div>
            <input type="text" formControlName="customers" class="form-control" aria-label="Customers"
                   aria-describedby="customerLabel">
          </div>
          <!-- Last modified by - readonly -->
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <label class="input-group-text" id="lastModifiedByLabel" style="width: 9rem;">Last Updated By</label>
            </div>
            <input type="text" formControlName="lastModifiedBy" class="form-control" aria-label="Last Updated By"
                   aria-describedby="lastModifiedByLabel" readonly>
          </div>
          <!-- Last modified date - readonly -->
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <label class="input-group-text" id="lastModifiedDateLabel" style="width: 9rem;">Last Updated Date</label>
            </div>
            <input type="text" formControlName="lastModifiedDate" class="form-control" aria-label="Last Updated Date"
                   aria-describedby="lastModifiedDateLabel" readonly>
          </div>
        </fieldset>
    </form>
    <ng-container *ngIf="showError">
        <small class="danger-text"> {{ errorMessage }} </small>
    </ng-container>
</div>

<div class="modal-footer">
    <ng-container *ngIf="showDeleteConfirmation === false">
        <button type="button" class="btn danger-button mr-auto" [disabled]="isServiceOidListLoading" aria-label="Delete"
            (click)="toggleDeleteConfirmation(true)">Delete</button>
        <ng-container *ngIf="isLoading">
            <span class="fa fa-spinner fa-spin fa-2x"></span>
        </ng-container>
        <button type="button" class="btn primary-button" aria-label="Save" (click)="updateOperation()" [disabled]="!componentForm.valid">Save</button>
        <button type="button" class="btn secondary-button" aria-label="Cancel" (click)="cancelUpdate()">Cancel</button>
    </ng-container>
    <ng-container *ngIf="showDeleteConfirmation === true">
        <div class="mr-auto">Delete Service ?</div>
        <ng-container *ngIf="isLoading">
            <span class="fa fa-spinner fa-spin fa-2x"></span>
        </ng-container>
        <button type="button" class="btn primary-button" aria-label="Yes" (click)="deleteOperation()">Yes</button>
        <button type="button" class="btn secondary-button" aria-label="No"
            (click)="toggleDeleteConfirmation(false)">No</button>
    </ng-container>
</div>
