<div *ngIf="mapIsEmpty" class="
    d-flex
    flex-row
    border-top border-right border-left border-dark
    mx-1
    mt-1
    px-2
  ">
  <div class="ml-3 mt-1">
    <h6 *ngIf="mapIsEmpty">
      Business Process Model Is Empty Or Not Configured
    </h6>
  </div>
</div>
<div *ngIf="!mapIsEmpty">
  <!-- title bar for Business Process Query. -->
  <div class="
      d-flex
      flex-row
      border-top border-right border-left border-dark
      mx-1
      mt-1
      px-2
    ">
    <div class="ml-3 mt-1">
      <h6>Discovered Business Process Model</h6>
    </div>
    <div class="ml-auto">
      <button *ngIf="enablePathDiv === false" type="button" class="btn btn-small btn-link link-text p-0 mr-2"
        (click)="showEnablePathPanel()" aria-label="Show Paths">
        <span class="fa fa-chevron-left"></span>
        Show Paths
      </button>
      <button *ngIf="enablePathDiv === true" type="button" class="btn btn-small btn-link link-text p-0 mr-2"
        (click)="showEnablePathPanel()" aria-label="Hide Paths">
        Hide Paths
        <span class="fa fa-chevron-right"></span>
      </button>
    </div>
  </div>

  <!-- event sequences div. -->
  <div class="d-flex flex-row border-right border-left border-dark mx-1">
    <div class="ml-auto mr-2" tabindex="0" *ngIf="mapCreatedOn != null">
      <h6 class="display-text">
        <small>
          <span>Created on {{ mapCreatedOn | date: "MMM d, y HH:mm" }} </span>
        </small>
      </h6>
    </div>
  </div>
</div>
<!-- container for graph and event sequences. -->
<div style="position: relative; height: 61vh; flex: 1; overflow-y: auto" class="d-flex flex-row mx-1">
  <!-- network div. -->
  <div #processMiningDiv appDivDimChange (divDimChange)="resizeGraph()" class="border border-dark"
    [attr.aria-label]="graphDescription" tabindex="0" role="img"
    style="position: absolute; height: 61vh; width: 100%; top: 0; left: 0"></div>
  <!-- for popover this div has higher z-index. -->
  <div class="border border-dark" style="
      position: absolute;
      height: 61vh;
      top: 0;
      right: 0;
      z-index: 1;
      background-color: white;
    " [ngClass]="this.enablePathDiv === true ? 'div-visible' : 'div-hidden'">
    <app-event-sequences [distributionPaths]="emitPath.asObservable()"
      (selectedPathIdEvent)="animateSelectedPath($event)" (clearPathEvent)="showDefaultGraphModel()">
    </app-event-sequences>
  </div>
</div>