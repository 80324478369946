import { Component, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { DisplayGraphService } from '../../../service/display-graph.service';
import { GraphMap } from '../../graph/graph-map';
import { DistributionPath, ProcessPath, ProcessProperty } from '../../../model/graph.model';
import { Subject, Subscription } from 'rxjs';
import { NetworkGraphApp, TimeUtility } from '@microsoft/network-graph';
import type { Graph, GraphNode, GraphProperties, GraphEdge } from '@microsoft/network-graph';
import { Input } from '@angular/core';

@Component({
  selector: 'app-display-process-map-v2',
  templateUrl: './display-process-map-v2.component.html',
  styleUrls: ['./display-process-map-v2.component.scss']
})
export class DisplayProcessMapV2Component implements OnDestroy, AfterViewInit {
  @ViewChild('processMiningDiv') processMiningGraphDiv: ElementRef<HTMLElement>;
  @Input() set redrawGraph(value: boolean) {
    if (value) {
      if (this.networkGraph != null) {
        this.networkGraph.redraw();
      } else {
        this.displayProcessMap();
      }
    }
  }

  processMiningGraph: GraphMap;

  // distributions panel.
  distributions: Array<DistributionPath>;
  emitPath: Subject<Array<DistributionPath>>;
  enablePathDiv: boolean;
  mapIsEmpty: boolean;

  mapCreatedOn: Date;

  graphDescription: string;
  distributionDescription: string;
  distributionDescriptionMap: Map<string, string>;

  private allSubscriptions: Subscription;
  private networkGraph: NetworkGraphApp;
  private graphNodes: Array<GraphNode>;
  private graphEdges: Array<GraphEdge>;

  constructor(private processMapService: DisplayGraphService) {
    this.distributions = new Array<DistributionPath>();

    this.enablePathDiv = false;
    this.emitPath = new Subject<Array<DistributionPath>>();
    this.mapIsEmpty = false;

    this.allSubscriptions = new Subscription();
  }

  ngAfterViewInit(): void {
    this.processMiningGraph = this.processMapService.getProcessMap();
    this.mapIsEmpty = this.processMiningGraph.isEmpty();
    this.displayProcessMap();

    // subscribe to an observable to track changes made to process mining graph.
    const graphSubscription = this.processMapService.getGraphMapChanges().subscribe(
      (graphMap: GraphMap) => {
        this.processMiningGraph = graphMap;
        this.mapIsEmpty = this.processMiningGraph.isEmpty();
        if (this.networkGraph != null) {
          const graph = this.getNetworkGraph(this.processMiningGraph);
          this.networkGraph.updateGraph(graph);
          this.setPathDistributions();
          this.networkGraph.clearGraphPath();
          this.enablePathDiv = false;
        } else {
          this.displayProcessMap();
        }
      });

    this.allSubscriptions.add(graphSubscription);
  }

  ngOnDestroy() {
    this.allSubscriptions.unsubscribe();
    this.emitPath.complete();
    if (this.networkGraph) {
      this.networkGraph.destroy();
    }
  }

  animateSelectedPath(pathId: string): void {
    const path = this.processMiningGraph.getGraphPathById(pathId);
    // update node and edge count using path count.
    const graph = this.getPathFilteredNetworkGraph(path);
    this.networkGraph.updateGraph(graph);
    this.networkGraph.setGraphPath(path.nodeIdPath, path.edgeIdPath);
  }

  showDefaultGraphModel(): void {
    const graph = this.getNetworkGraph(this.processMiningGraph);
    this.networkGraph.updateGraph(graph);
    this.networkGraph.clearGraphPath();
  }

  showEnablePathPanel(): void {
    this.enablePathDiv = !this.enablePathDiv;
    if (this.enablePathDiv) {
      this.emitPath.next(this.distributions);
    }
  }

  resizeGraph(): void {
    this.networkGraph?.redraw();
  }

  private setPathDistributions(): void {
    this.distributions = this.processMiningGraph.getPathDistributionList();
    this.distributions.sort((a, b) => (+(a.frequency) > +(b.frequency) ? -1 : 1));
  }

  /**
   * Method converts process mining graph to network graph and initializes the
   * network graph object.
   */
  private displayProcessMap(): void {
    if (this.processMiningGraph == null) {
      return;
    }

    // html elements accessed using ViewRef are available oin afterViewInit lifecycle hook.
    setTimeout(() => {
      if (this.processMiningGraphDiv.nativeElement.offsetHeight !== 0
        && this.processMiningGraphDiv.nativeElement.offsetWidth !== 0) {
        const graph = this.getNetworkGraph(this.processMiningGraph);
        this.networkGraph = new NetworkGraphApp(this.processMiningGraphDiv.nativeElement, graph);
        this.enablePathDiv = false;
        this.graphDescription = this.processMiningGraph.getGraphDescription();

        this.distributionDescriptionMap = this.processMiningGraph.getGraphPathDescription();
        this.mapCreatedOn = this.processMiningGraph.getGraphMapCreatedDate();
        // setting path values.
        this.setPathDistributions();
      }
    }, 1);
  }

  /**
   * Converts process mining graph created for visjs to a format required by network graph.
   * Currently, the graph service receives the model from the API and as part of the request pipeline
   * transforms the backend model to a format requried by visjs. The visjs graph is stored by graph caching
   * service. Once, visjs graph is no longer required, backend graph can be directly converted to network-graph
   * version.
   *
   * @param processMiningGraph graph created for visjs.
   */
  private getNetworkGraph(processMiningGraph: GraphMap): Graph {
    const nodes = processMiningGraph.getNodes();
    this.graphNodes = nodes.map((node) => {
      const g = {} as GraphNode;
      g.nodeId = node.id.toString();
      g.title = node.displayName == null ? node.event : node.displayName;
      if (g.title === 'Start' || g.title === 'End' || g.title === 'Active') {
        g.nodeType = 'TerminalNode';
      } else {
        g.nodeType = 'DefaultNode';
      }
      g.enableOptions = false;
      g.properties = node.properties.map((prop) => {
        const p = {} as GraphProperties;
        p.propertyName = prop.name;
        p.propertyValue = prop.value;
        return p;
      });
      return g;
    });

    // convert edges to graph edge.
    this.graphEdges = processMiningGraph.getEdges().map((edge) => {
      const e = {} as GraphEdge;
      e.edgeId = edge.id.toString();
      e.fromNodeId = edge.from;
      e.toNodeId = edge.to;
      e.properties = edge.properties.map((prop: ProcessProperty) => {
        const p = {} as GraphProperties;
        p.propertyName = prop.name;
        if (p.propertyName === 'Time') {
          p.propertyName = 'Duration';
          p.propertyValue = TimeUtility.pretiffyTimeDuration(prop.value, 3);
        } else if (p.propertyName === 'Count') {
          p.propertyName = 'Event Count';
          p.propertyValue = prop.value;
          e.label = prop.value.toString();
          e.edgeWeight = +prop.value;
        } else {
          p.propertyValue = prop.value;
        }
        return p;
      });
      return e;
    });
    const graph = {} as Graph;
    graph.nodes = this.graphNodes;
    graph.edges = this.graphEdges;
    graph.enableDebug = true;
    return graph;
  }

  private getPathFilteredNetworkGraph(path: ProcessPath): Graph {
    const pathCount = path.properties.find(p => p.name === 'Count')?.value;
    const graphNodes: Array<GraphNode> = this.graphNodes.map((node: GraphNode) => {
      const isNodeIncludedInPath = path.nodeIdPath.findIndex((x) => x.toString() === node.nodeId.toString());
      node.properties = new Array<GraphProperties>();
      if (isNodeIncludedInPath !== -1) {
        const nodeProperty = {} as GraphProperties;
        nodeProperty.propertyName = 'Event Count';
        nodeProperty.propertyValue = pathCount;
        node.properties.push(nodeProperty);
      }
      return node;
    });

    // get edge id map.
    const edgeToNodeIdMap = new Map<string, string>();
    this.graphEdges.forEach((edge) => {
      const nodeIdPath = edge.fromNodeId + '-' + edge.toNodeId;
      edgeToNodeIdMap.set(nodeIdPath, edge.edgeId);
    });

    // get node name to node id path.
    const nodeIdMap = new Map<string, string>();
    this.graphNodes.forEach((node) => {
      nodeIdMap.set(node.title, node.nodeId);
    });

    // parse path edge info.
    const pathEdgeInfo: Array<PathEdgeInfo> = path.properties.find(p => p.name === 'PathEdgeInfo')?.value;
    const edgeDurationMap = new Map<string, number>();
    pathEdgeInfo.forEach((edgeInfo) => {
      const edgeNodeIdPath = nodeIdMap.get(edgeInfo.sourceNode) + '-' + nodeIdMap.get(edgeInfo.targetNode);
      const edgeId = edgeToNodeIdMap.get(edgeNodeIdPath);
      edgeDurationMap.set(edgeId, edgeInfo.averageTime);
    });

    // convert edges to graph edge.
    const graphEdges: Array<GraphEdge> = this.graphEdges.map((edge) => {
      const isEdgeIncludedInPath = path.edgeIdPath.findIndex(x => x.toString() === edge.edgeId);
      if (isEdgeIncludedInPath !== -1) {
        edge.edgeWeight = +pathCount;
        edge.label = pathCount.toString();
        edge.properties = new Array<GraphProperties>();
        const countProperty = {} as GraphProperties;
        countProperty.propertyName = 'Count';
        countProperty.propertyValue = pathCount;
        edge.properties.push(countProperty);

        const edgeDuration = edgeDurationMap.get(edge.edgeId);
        if (edgeDuration != null) {
          const durationProperty = {} as GraphProperties;
          durationProperty.propertyName = 'Average Duration';
          durationProperty.propertyValue = TimeUtility.pretiffyTimeDuration(edgeDurationMap.get(edge.edgeId), 3);
          edge.properties.push(durationProperty);
        }
      } else {
        edge.edgeWeight = 0;
        edge.label = '';
        edge.properties = new Array<GraphProperties>();
      }
      return edge;
    });

    const graph = {} as Graph;
    graph.nodes = graphNodes;
    graph.edges = graphEdges;
    graph.enableDebug = true;
    return graph;
  }
}

interface PathEdgeInfo {
  sourceNode: string;
  targetNode: string;
  count: string;
  averageTime: number;
}
