<div class="container-fluid pt-3">
  <div class="jumbotron bg-transparent">
    <h1 class="display-5">Event Broker</h1>
    <hr class="my-4">
    <p class="lead">The Event Broker is a shared platform used to implement event-driven architecture (EDA) across your organization. 
      The broker transmits events between event producers and consumers in a publish-subscribe pattern.
      It enables decoupled dependencies, scalability, resiliency, improved change management, and more.
      For more details, please visit 
      <a class="link-text" href="https://aka.ms/SCEventBroker" target="_blank" rel="noreferrer noopener" aria-label="SCEventBroker wiki">
        https://aka.ms/SCEventBroker
        <span class="fa fa-link" aria-hidden="true"></span>
      </a>  
    </p>
    <h3 class="display-5 mt-5">Event Catalog</h3>
    <p class="lead">The Event Catalog is the interface to the Event Broker that allows you to onboard events and subscriptions, track event publishers, 
      specify event schemas, correlate events to higher-level business entities and search for all event-related objects within your organization.  
    </p>
    <span class="lead">The Event Catalog
    <ul class ="lead" style="list-style-type:disc;">
      <li>enables easy onboarding and maintenance for devs and PMs</li>
      <li>documents your events, services, domains, schemas, code examples, and more</li>
      <li>integrates across multiple verticals in your organization</li>
      <li>brings visibility to your event-driven architecture</li>
    </ul>
    </span>
    <p class="lead">
      For more details, please visit 
      <a href="https://aka.ms/SCEventCatalogWiki" target="_blank" rel="noreferrer noopener" aria-label="Event Catalog wiki" class="link-text">
        https://aka.ms/SCEventCatalogWiki
        <span class="fa fa-link" aria-hidden="true"></span>
      </a>  
    </p>
    <h3 class="display-5 mt-5">Event Activity Dashboard</h3>
    <p class="lead">The Event Activity Dashboard is a Power BI report that shows event ingestion volume, summarizes event and subscription usage by team, 
      and reports event delivery failures (subscription endpoint did not accept the event delivery).
    </p>
    <h3 class="display-5 mt-5">Architecture</h3>
    <img src="../../../assets/images/EventArchitecture.png" title="Event Architecture Diagram"
    alt="Event Architecture Diagram" aria-label="Event Architecture Diagram">
  </div>
</div>
