export class DataSource {
  public id: number;
  public name: string;
  public type: string;
  public typeDetails: DataSourceTypeDetails;
  public connectionString: string;
  public description: string;
  public updatedBy: string;
  public updatedOn: Date;
  public expiresOn: Date;
  public ownerGroupId: string;
}

export class DataSourceTypeDetails {
  public type: string;
  public isImported: boolean;
  public isSelfServe: boolean;
  public connectionStringParameters: string;
}

export class DataSourceRequest {
    public name: string;
    public type: string;
    public connectionString: string;
    public description: string;
    public ownerGroupId: string;
}
