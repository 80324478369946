<!-- title -->
<div>
  <h4 class="display-5 ml-3 mt-3">Event Catalog
    <a class = "close mr-4" href="https://aka.ms/SCEventBroker" target="_blank" rel="noreferrer noopener" aria-label="SCEventBroker wiki">
      <i class="fa fa-question-circle-o"></i>
    </a>
  </h4>
</div>
<hr>

<div class="mx-2 mt-3">
  <!-- Add loader if event list is not loaded-->
  <div *ngIf="!isEventListLoaded" class="text-center">
    <span class="fa fa-spinner fa-spin fa-2x" style="font-size:5rem;" [attr.aria-label]="Loading"></span>
  </div>
  <div *ngIf="isEventListLoaded">
    <!-- Add New Event Button -->
    <div class="d-flex flex-rows mb-3">
      <!-- Search Event Catalog -->
      <div class="search">
        <span class="fa fa-search"></span>
        <input type="text" [formControl]="search" placeholder="Search Event Catalog"
          aria-label="Search Event Catalog">
      </div>
      <div class="ml-auto">
        <button class="btn btn-sm info-button border border-secondary" type="button" id="addNewEventButton"
          (click)="addNewEvent()">
          Add New Event
        </button>
      </div>
    </div>
    <!-- Display table with all event data -->
    <app-display-table-v2 [tableInput]="tableData.asObservable()" [tableOptions]="tableOptions"
      [searchTable]="tableSearch.asObservable()" (rowNavEvent)="viewDetails($event)" 
      (rowEditEvent)="addNewSubscription($event)">
    </app-display-table-v2>
  </div>
</div>