<div class="modal-header">
  <h4 class="modal-title">Details</h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="updateForm">
    <h6>Actual Score</h6>
    <div class="input-group input-group-sm mb-2">
      <input type="text" class="form-control" aria-label="Actual Score" readonly>
    </div>
    <hr>
    <h6>Summary</h6>
    <div class="input-group input-group-sm mb-2">
      <textarea type="text" class="form-control" aria-label="Summary" rows=4 readonly>
            </textarea>
    </div>
  </form>
  <hr>
  <h6>IcM Incidents</h6>
  <div class="table-responsive">
    <table class="table table-bordered table-striped" aria-label="Scenario Health details">
      <thead class="thead-dark">
        <tr>
          <th id="h11" class="text-center" style="width:20%" scope="col">Incident</th>
          <th id="h12" class="text-center" style="width:80%" scope="col">Comments</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let incident of icmIncidents; let i=index; let even=even;" [ngClass]="{'even': even}">
          <td headers="h11" class="text-center">
            <a href="{{icmUrlPrefix}}/{{incident.icmNumber}}" 
              target="_blank" rel="noopener noreferrer" class="link-text">
                {{incident.icmNumber}}
              </a>
          </td>
          <td headers="h12" class="text-center">{{incident.comments}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn primary-button" aria-label="Ok" (click)="closeModal()">Ok</button>
</div>