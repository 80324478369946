import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-display-table',
  templateUrl: './display-table.component.html',
  styleUrls: ['./display-table.component.css']
})
export class DisplayTableComponent implements OnInit {
  @Input() columns: Array<string>;
  @Input() rows: Array<Array<string>>;

  constructor() { }

  ngOnInit() { }
}
