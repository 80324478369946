export class EventType {
  id: number;
  name: string;
  description: string;
  environment: string;
  schemaType: string;
  contactAlias: string;
  ownerGroupAlias: string;
  ownerGroupId: string;
  teamName: string;
  serviceName: string;
  serviceId: string;
  updatedBy: string;
  updatedOn: Date;
  numSubscribers: number;
  publishToTelemetry: boolean;

  constructor();

  constructor(id: number,
    name: string,
    description: string,
    environment: string,
    schemaType: string,
    contactAlias: string,
    ownerGroupAlias: string,
    ownerGroupId: string,
    teamName: string,
    serviceName: string,
    serviceId: string,
    updatedBy: string,
    updatedOn: Date,
    numSubscribers: number,
    publishToTelemetry: boolean);

  constructor(id?: number,
    name?: string,
    description?: string,
    environment?: string,
    schemaType?: string,
    contactAlias?: string,
    ownerGroupAlias?: string,
    ownerGroupId?: string,
    teamName?: string,
    serviceName?: string,
    serviceId?: string,
    updatedBy?: string,
    updatedOn?: Date,
    numSubscribers?: number,
    publishToTelemetry?: boolean) {

    this.id = id;
    this.name = name;
    this.description = description;
    this.environment = environment;
    this.schemaType = schemaType;
    this.contactAlias = contactAlias;
    this.ownerGroupAlias = ownerGroupAlias;
    this.ownerGroupId = ownerGroupId;
    this.teamName = teamName;
    this.serviceName = serviceName;
    this.serviceId = serviceId;
    this.updatedBy = updatedBy;
    this.updatedOn = updatedOn;
    this.numSubscribers = numSubscribers;
    this.publishToTelemetry = publishToTelemetry;
  }
}
