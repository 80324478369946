import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '../common/constants';
import { HttpClient } from "@angular/common/http";
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ServiceTreeNode } from '../model/servicetreenode.model';
import { Organization, ServiceGroup } from '../bam-dashboard/model/process.model';
import { CacheService } from './cache.service';
import { AppConfig } from '../model/app-config.model';

@Injectable({
  providedIn: 'root'
})
export class ServiceTreeService {
  private coreServicesEngineeringAndOperationsId = '85dd10f4-5484-48fa-b11f-6e6ab07f8d4c';
  private config: any = {
    root: {
      url: "ServiceTree/Divisions",
      type: "Division"
    },
    Division: {
      url: "ServiceTree/Division/{id}/Organizations",
      type: "Organization"
    },
    Organization: {
      url: "ServiceTree/Organization/{id}/ServiceGroups",
      type: "ServiceGroup"
    },
    ServiceGroup: {
      url: "ServiceTree/ServiceGroup/{id}/Services",
      type: "Service"
    },
    Service: {
      url: "ServiceTree/Service/{id}/Components",
      type: "Component"
    },
    Component: {
      url: "",
      type: ""
    },
  };

  constructor(
    private httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig,
    private cacheService: CacheService
  ) {
    this.baseUrlWithVersion = appConfig.baseUrlWithVersion;
  }

  private baseUrlWithVersion: string;

  getNodes(type: string, id: string): Observable<ServiceTreeNode[]> {
    return this.httpClient.get<any[]>(this.baseUrlWithVersion + this.config[type].url.replace("{id}", id))
      .pipe(map((response) => {
        return response.map(data => {
          return new ServiceTreeNode(
            { "name": data.Name, "id": data.Id, "type": this.config[type].type, isChildrensLoaded: type === "Service" }
          );
        });
      }));
  }

  getServiceTreeInfo(type: string, id: string): Observable<any[]> {
    const serviceTreeUrl = this.baseUrlWithVersion + this.config[type].url.replace("{id}", id);
    return this.cacheService.getCached<any[]>(
      serviceTreeUrl,
      (url) => this.httpClient.get<any[]>(url)
    );
  }

  getServiceGroups(organizationId: string): Observable<Array<ServiceGroup>> {
    return this.getServiceTreeInfo('root', '').pipe(
      mergeMap(x => {
        return this.getServiceTreeInfo('Organization', organizationId);
      }),
      map(x => {
        x = x.map(ele => new ServiceGroup(ele.Id, ele.Name, ele.OrganizationId));
        return x;
      }),
    );
  }

  getOrganizations(): Observable<Array<Organization>> {
    return this.getServiceTreeInfo('root', '').pipe(
      mergeMap(x => {
        return this.getServiceTreeInfo('Division', this.coreServicesEngineeringAndOperationsId);
      }),
      map(x => {
        x = x.map(ele => new Organization(ele.Id, ele.Name));
        return x;
      }),
    );
  }
}
