// This file stores all the constants in the application.
export const constants = {

  // Data Source Constants
  KUSTO: "KUSTO",
  SQL: "SQL",
  AI: "AI",
  CONSTANT: 'CONSTANT',

  // Local Storage Constants
  ORGANIZATION: "Organization",
  SERVICE_GROUP: "ServiceGroup",
  TEAM_GROUP: "TeamGroup",
  BUSINESS_PROCESS: "BusinessProcess",

  // Toggle options
  RuleEngine: "Rule Engine",
  DirectQuery: "Direct Query",

  // Add new rule option
  NewRule: "+Add New Rule",

  // dropdown select's
  SelectRule: "Select Rule",
  SelectEntity: "Select Entity",
  SelectEntityOrVariable: "Select Entity/Var",
  SelectColumn: "Select Column",
  SelectVariable: "Select Variable",
  SelectOperator: "Select Operator",
  SelectDataSource: "Select DataSource",

  // operator types
  Aggregation: "Aggregation",
  Comparison: "Comparison",

  ComputedVariables: "Computed Variables",

  // power bi service resource.
  PowerBIResource: 'https://analysis.windows.net/powerbi/api',

  // status for display-table-v2 component
  SuccessStatus: "Success",
  FailureStatus: "Failed",
  RunningStatus: "Running",
  PendingStatus: "Pending",
  ReadyStatus: "Ready",
  CreatingStatus: "Creating",
  UpdatingStatus: "Updating",
  DeletingStatus: "Deleting",
};

// Datatypes for BAM.
export enum DataTypes {
  String,
  Int,
  Real,
  Date,
  Time,
  DateTime,
}

export enum FormComponentTypes {
  Condition,
  ConditionSet,
  LabelledIntInput,
  LabelledRealInput,
  LabelledStringInput,
  LabelledDateInput,
  LabelledTimeInput,
  LabelledDatetimeInput,
}

export enum GraphSystemProperties {
  isReference = 'IsReference',
  Status = 'Status'
}

export class NodeColor {
  static GREEN = '#32CD32';
  static RED = '#FF0000';
  static DEFAULT = '#F4883A';
  static GREY = '#C0C0C0';
  static PROCESS_HEALTH_RED = '#f8412c';
  static PROCESS_HEALTH_DEFAULT = '#f7a468';
  static PROCESS_HEALTH_GREEN = '#1bb343';
}
