import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild, ViewChildren, QueryList, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormArray, ValidatorFn, AbstractControl } from '@angular/forms';
import { ServiceInput, Service, OperationType, Operation, QosRoutingState } from '../model/qos.model';
import { QoSService } from '../service/qos.service';
import { ServiceTreeService } from '../../bam-dashboard/model/process.model';
import { HttpErrorResponse } from '@angular/common/http';
import { showError } from '../common/utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { mergeMap, filter } from 'rxjs/operators';
import { NgbModalErrorComponent } from '../../modal/ngb-modal-error.component';
import { NgbModalSuccessComponent } from '../../modal/ngb-modal-success.component';
import { QoSRouterPage } from '../common/constants';
import { APP_CONFIG, ngbModalOptions } from '../../common/constants';
import { AppConfig } from '../../model/app-config.model';

@Component({
  selector: 'app-add-component',
  templateUrl: './add-component.component.html',
  styleUrls: ['./add-component.component.css']
})
export class AddComponent implements OnInit, OnDestroy, AfterViewInit {
  // input.
  serviceInput: ServiceInput;
  teamgroupName: string;

  serviceTreeServiceList: Array<ServiceTreeService>;
  iKeyList: Array<string>;

  // form details.
  telemetryInputForm = new UntypedFormGroup({
    serviceName: new UntypedFormControl('', this.validateServiceName()),
    telemetrySource: new UntypedFormControl('', this.validateTelemetrySource()),
    serviceOid: new UntypedFormControl(''),
    customers: new UntypedFormControl(''),
    serviceList: new UntypedFormArray([], Validators.required)
});

// template variables.
isServiceTreeServiceLoading: boolean;
isiKeyListLoading: boolean;
isTelemetryOnboardingSupported: boolean;
hasValidiKeysConfigured: boolean;
isSaveInProgress: boolean;
addOperation: boolean;

// all subscriptions.
subscriptions: Subscription;
saveSubscription: Subscription;

componentState: QosRoutingState;
qosOnboardingWikiUrl: string;

// view child elements for set the focus
@ViewChild('telemetrySourceOptionSelectName') telemetrySourceElement: ElementRef;
@ViewChild('AddRequestButton') addRequestButtonElement: ElementRef;
@ViewChildren('formRowRequestType') formRowRequestTypeElement: QueryList<ElementRef>;

constructor(
  private router: Router,
  private qosService: QoSService,
  private modalService: NgbModal,
  @Inject(APP_CONFIG) appConfig: AppConfig
) {
  this.subscriptions = new Subscription();

  this.componentState = this.router.getCurrentNavigation().extras.state as QosRoutingState;
  this.serviceInput = this.componentState.serviceInput;
  this.teamgroupName = this.componentState.teamGroupName;

  this.isTelemetryOnboardingSupported = null;
  this.telemetryInputForm.controls.telemetrySource.setValue('Select Telemetry Source');
  this.checkTelemetrySource();

  if (this.componentState.previousUrl === QoSRouterPage.ManageIKey) {
    this.telemetryInputForm.controls.telemetrySource.setValue('Application Insights');
  }

  // get ikey list.
  this.iKeyList = new Array<string>();
  this.hasValidiKeysConfigured = null;
  this.getIkeyList();
  this.isiKeyListLoading = false;

  this.isServiceTreeServiceLoading = false;
  this.telemetryInputForm.controls.serviceName.setValue('Loading...');
  this.getServiceNames(this.serviceInput.teamGroupOid);

  this.isSaveInProgress = false;
  this.addOperation = false;

  this.qosOnboardingWikiUrl = appConfig.qosOnboardingWikiUrl;
}

ngOnInit() {
}

ngOnDestroy() {
  this.subscriptions.unsubscribe();
}

ngAfterViewInit() {
  this.focusElement();
}

focusElement() {
  this.telemetrySourceElement.nativeElement.focus();
}

getServiceNames(teamGroupId: string): void {
  this.isServiceTreeServiceLoading = true;
  const serviceTreeSubscription = this.qosService.getServiceTreeServicesByTeamGroup(teamGroupId).subscribe(
    (serviceList: ServiceTreeService[]) => {
      this.serviceTreeServiceList = serviceList;
      this.isServiceTreeServiceLoading = false;
      if (this.componentState.previousUrl === QoSRouterPage.ManageIKey) {
        const serviceTreeService = this.serviceTreeServiceList.find(x => x.id === this.componentState.selectedServiceId);
        this.telemetryInputForm.controls.serviceName.setValue(serviceTreeService);
      } else {
        this.telemetryInputForm.controls.serviceName.setValue('Select Service Name');
      }
    },
    (error: HttpErrorResponse) => {
      this.isServiceTreeServiceLoading = false;
      this.telemetryInputForm.controls.serviceName.setValue('Select Service Name');
      showError(this.modalService, error, error.error);
    }
  );

  this.subscriptions.add(serviceTreeSubscription);
}

getIkeyList(): void {
  const valueChanges = this.telemetryInputForm.controls.serviceName.valueChanges.pipe(
    filter(x => !['Select Service Name', 'Loading...'].includes(x)),
    mergeMap((selectedServiceName: ServiceTreeService) => {
      this.isServiceTreeServiceLoading = true;
      this.isiKeyListLoading = true;
      this.hasValidiKeysConfigured = null;
      this.telemetryInputForm.controls.serviceOid.setValue(selectedServiceName.id);
      return this.qosService.getIkeyListByServiceId(selectedServiceName.id);
    })
  ).subscribe(
    (iKeyList: Array<string>) => {
      this.isServiceTreeServiceLoading = false;
      this.isiKeyListLoading = false;
      if (iKeyList.length > 0) {
        this.hasValidiKeysConfigured = true;
        this.iKeyList = iKeyList;
      } else {
        this.hasValidiKeysConfigured = false;
      }
    },
    (error: HttpErrorResponse) => {
      this.isServiceTreeServiceLoading = false;
      showError(this.modalService, error, error.error);
    }
  );

  this.subscriptions.add(valueChanges);
}

getServiceListForm(): UntypedFormArray {
  return this.telemetryInputForm.controls.serviceList as UntypedFormArray;
}

addNewOperation(type: string): void {
  this.addOperation = true;
  const newService = new UntypedFormGroup({
    requestType: new UntypedFormControl('', Validators.required),
    operationName: new UntypedFormControl('', Validators.required),
    latencyThreshold: new UntypedFormControl('', [Validators.required, Validators.pattern(/^[1-9]\d*$/)]),
    critical: new UntypedFormControl('')
  }, this.validateOperation()
  );

  if (type === OperationType.Request) {
    newService.get('requestType').setValue(OperationType.Request);
  } else if (type === OperationType.RemoteDependency) {
    newService.get('requestType').setValue('Remote Dependency');
  }
  this.getServiceListForm().push(newService);
}

deleteOperation(operationIndex: number): void {
  // if operationIndex is 0 and total elements are 1 then focus to "Add Request" button
  if (operationIndex === 0 && this.telemetryInputForm.get("serviceList").value.length === 1) {
    this.addRequestButtonElement.nativeElement.focus();
  } else if (operationIndex === 0 && this.telemetryInputForm.get("serviceList").value.length > 1) {
    this.formRowRequestTypeElement.toArray()[operationIndex + 1].nativeElement.focus();
  } else {
    this.formRowRequestTypeElement.toArray()[operationIndex - 1].nativeElement.focus();
  }
  this.getServiceListForm().removeAt(operationIndex);
}

checkTelemetrySource(): void {
  const telemetrySourceChanges = this.telemetryInputForm.controls.telemetrySource.valueChanges.subscribe(
    (telemetrySource: string) => {
      if (telemetrySource === 'Application Insights') {
        this.isTelemetryOnboardingSupported = true;
      } else {
        this.isTelemetryOnboardingSupported = false;
      }
    }
  );
  this.subscriptions.add(telemetrySourceChanges);
}

saveOperations(): void {
  this.isSaveInProgress = true;
  const serviceInput = cloneDeep(this.serviceInput);
  // start reading values from Form and update in service or raise error.
  const service = new Service();
  const serviceTreeService: ServiceTreeService = this.telemetryInputForm.controls.serviceName.value;
  service.serviceName = serviceTreeService.name;
  service.serviceOid = serviceTreeService.id;
  const operations = new Array<Operation>();

  const requestNameList = new Array<string>();
  const dependencyNameList = new Array<string>();
  const operationsForm = this.telemetryInputForm.controls.serviceList.value;
  for (const operationControl of operationsForm) {
    const operation = new Operation();
    operation.name = operationControl.operationName.trim();
    if (operationControl.requestType === OperationType.Request) {
      operation.type = OperationType.Request;
      // check for name uniqueness
      if (requestNameList.includes(operation.name.toLowerCase())) {
        this.isSaveInProgress = false;
        const errorModal = this.modalService.open(NgbModalErrorComponent);
        errorModal.componentInstance.message = "Duplicate Request Names; Please correct it before saving";
        return;
      }
      requestNameList.push(operation.name.toLowerCase());
    } else if (operationControl.requestType === 'Remote Dependency') {
      operation.type = OperationType.RemoteDependency;
      // check for name uniqueness
      if (dependencyNameList.includes(operation.name.toLowerCase())) {
        this.isSaveInProgress = false;
        const errorModal = this.modalService.open(NgbModalErrorComponent);
        errorModal.componentInstance.message = "Duplicate Remote Dependency Names; Please correct it before saving";
        return;
      }
      dependencyNameList.push(operation.name.toLowerCase());
    }
    operation.customers = this.telemetryInputForm.controls.customers.value;
    if (operationControl.critical) {
      operation.critical = 'Yes';
    } else {
      operation.critical = 'No';
    }
    // converting into ticks.
    operation.latencyThreshold = operationControl.latencyThreshold * 10000;
    operation.process = this.teamgroupName;

    operations.push(operation);
  }

  service.operations = operations;
  serviceInput.services = new Array<Service>();
  serviceInput.services.push(service);
  this.saveSubscription = this.qosService.addOperations(serviceInput).subscribe(
    () => {
      this.isSaveInProgress = false;
      // add loader till save is finished, then popup with save finished, if completed then navigate to admin page.
      const saveConfirmationModal = this.modalService.open(NgbModalSuccessComponent, ngbModalOptions);
      saveConfirmationModal.componentInstance.message = 'Operations successfully saved.';

      if (saveConfirmationModal) {
        saveConfirmationModal.result
          .then(() => {
            this.router.navigate(['quality-of-service/admin']);
          });
      }
    },
    (error: HttpErrorResponse) => {
      this.isSaveInProgress = false;
      console.log(error);
      showError(this.modalService, error, error.error);
    }
  );

  this.subscriptions.add(this.saveSubscription);
}

cancel(): void {
  this.router.navigate(['quality-of-service/admin']);
}

manageIkey(): void {
  const ikeyState: QosRoutingState = {
    previousUrl: QoSRouterPage.AddOperation,
    teamGroupName: this.teamgroupName,
    selectedServiceId: this.telemetryInputForm.controls.serviceOid.value,
    serviceInput: this.serviceInput
  };

  this.router.navigate(['quality-of-service/manage-ikeys'], { state: ikeyState });
}

validateServiceName(): ValidatorFn {
  return (serviceName: AbstractControl): { [key: string]: any } | null => {
    const serviceNameValue = serviceName.value;
    if (serviceNameValue === '' || serviceNameValue === 'Select Service Name') {
      return {
        validationError: true,
        errorMessage: 'Please select a Service Name to proceed.'
      };
    } else {
      return null;
    }
  };
}

validateTelemetrySource(): ValidatorFn {
  return (telemetrySource: AbstractControl): { [key: string]: any } | null => {
    const telemetrySourceValue = telemetrySource.value;
    if (telemetrySourceValue === '' || telemetrySourceValue === 'Select Telemetry Source') {
      return {
        validationError: true,
        errorMessage: 'Please select a telemetry source to proceed.'
      };
    } else {
      return null;
    }
  };
}

validateOperation(): ValidatorFn {
  return (operation: AbstractControl): { [key: string]: any } | null => {
    const serviceFormGroup = operation as UntypedFormGroup;
    const operationNameValue = serviceFormGroup.controls.operationName.value;
    let validationError = false;
    let errorMessage = 'Following Fields are required: ';
    if (operationNameValue === '') {
      validationError = true;
      errorMessage += 'Operation Name';
    }

    const latencyThresholdValue = serviceFormGroup.controls.latencyThreshold.value;
    if (latencyThresholdValue === '') {
      if (validationError) {
        errorMessage += ', Latency';
      } else {
        validationError = true;
        errorMessage = 'Latency';
      }
    }

    if (validationError) {
      return {
        validationError: validationError,
        errorMessage: errorMessage
      };
    } else {
      return null;
    }
  };
}
}
