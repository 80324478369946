<div class="modal-header">
  <ng-container *ngIf="!isUpdate">
    <h4 class="modal-title">Add New Event</h4>
  </ng-container>
  <ng-container *ngIf="isUpdate">
    <h4 class="modal-title">Update Event</h4>
  </ng-container>
  <div>
    <button type="button" class="close" aria-label="Close" (click)="cancel()" [disabled]="isLoadingMasterData">
      <span aria-hidden="true">&times;</span>
    </button>
    <a class = "close" href="https://aka.ms/SCEventBroker" target="_blank" rel="noreferrer noopener" aria-label="SCEventBroker wiki">
      <i class="fa fa-question-circle-o"></i>
    </a>
  </div>
</div>

<div class="modal-body">
  <div *ngIf="isLoadingMasterData" class="ml-auto">
    <span role="progressbar" class="info-text fa fa-spinner fa-spin fa-3x" aria-label="Loading"></span>
  </div>
  <ng-container *ngIf="!isLoadingMasterData">
    <form [formGroup]="eventForm">
      <!--Event Name-->
      <div class="input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <label class="input-group-text" id="eventNameLabel" style="width: 9rem;">
            Event Name
            <span class="danger-text ml-1" aria-label="Required Field">*</span>
          </label>
        </div>
        <input type="text" formControlName="eventName" class="form-control required" aria-label="Event Name"
          aria-describedby="eventNameLabel">
      </div>
      <div *ngIf="shouldShowValidationError(eventForm.controls.eventName)" class="mb-3">
        <small class="danger-text">Event Name must be PascalCase format with alphanumeric characters or periods.</small>
      </div>
      <!--Description-->
      <div class="input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <label class="input-group-text" id="descriptionLabel" style="width: 9rem;">
            Description
            <span class="danger-text ml-1" aria-label="Required Field">*</span>
          </label>
        </div>
        <textarea type="text" formControlName="description" class="form-control required" aria-label="Description"
          aria-describedby="descriptionLabel">
          </textarea>
      </div>
      <!--Contact Alias-->
      <div class="input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <label class="input-group-text" id="contactAliasLabel" style="width: 9rem;">
            Contact Alias
            <span class="danger-text ml-1" aria-label="Required Field">*</span>
            <span class="fa fa-info-circle ml-auto" ngbPopover="For notifications or inquries" popoverTitle="Contact Alias"
                    container="body"></span>
          </label>
        </div>
        <input type="text" formControlName="contact" class="form-control required" aria-label="contactAlias"
          aria-describedby="contactAliasLabel">
      </div>
      <div *ngIf="shouldShowValidationError(eventForm.controls.contact)" class="mb-3">
        <small class="danger-text">Contact alias must be provided with domain (alias@microsoft.com)</small>
      </div>
      <!--Owner SG-->
      <div class="input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <label class="input-group-text" id="ownerGroupAliasLabel" style="width: 9rem;">
            Admin SG
            <span class="danger-text ml-1" aria-label="Required Field">*</span>
            <span class="fa fa-info-circle ml-auto" ngbPopover="For edit/delete access to this event" popoverTitle="Admin SG"
                    container="body"></span>
          </label>
        </div>
        <input type="text" formControlName="ownerGroupAlias" class="form-control required" aria-label="ownerGroupAlias"
          aria-describedby="ownerGroupAliasLabel">
      </div>
      <div *ngIf="shouldShowValidationError(eventForm.controls.ownerGroupAlias)" class="mb-3">
        <small class="danger-text">Admin SG must be provided with domain (sg@microsoft.com)</small>
      </div>
      <!--Schema-->
      <div class="input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <label class="input-group-text" id="schemaLabel" style="width: 9rem;">
            Schema
            <span class="danger-text ml-1" aria-label="Required Field">*</span>
            <ng-template #popContent>Cloud Event Schema is preferred. Visit the 
              <a href="https://aka.ms/SCEventBroker" target="_blank" rel="noreferrer noopener">wiki</a>
              for more information.
            </ng-template>
            <span class="fa fa-info-circle ml-auto" [ngbPopover]="popContent" popoverTitle="Schema"
                  container="body"></span>
          </label>
        </div>
        <select class="custom-select-sm form-control required" id="schema" style="width: 20rem;"
          aria-label="Schema" formControlName="schema">
          <option *ngFor="let sch of masterData.schema" [ngValue]="sch">{{ sch }}</option>
        </select>
      </div>
      <!--Team-->
      <div class="input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <label class="input-group-text" id="teamLabel" style="width: 9rem;">
            Team
            <span class="danger-text ml-1" aria-label="Required Field">*</span>
            <span class="fa fa-info-circle ml-auto" ngbPopover="Service Tree Team Name" popoverTitle="Team"
                    container="body"></span>
          </label>
        </div>
        <input type="text" formControlName="team" class="form-control required" aria-label="team"
          aria-describedby="teamLabel">
      </div>
      <!--Service-->
      <div class="input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <label class="input-group-text" id="serviceLabel" style="width: 9rem;">
            Service
            <span class="danger-text ml-1" aria-label="Required Field">*</span>
            <span class="fa fa-info-circle ml-auto" ngbPopover="Service Tree Service Name" popoverTitle="Service"
                    container="body"></span>
          </label>
        </div>
        <input type="text" formControlName="service" class="form-control required" aria-label="service"
          aria-describedby="serviceLabel">
      </div>
      <!--ServiceId-->
      <div class="input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <label class="input-group-text" id="serviceIdLabel" style="width: 9rem;">
            Service GUID
            <span class="danger-text ml-1" aria-label="Required Field">*</span>
            <span class="fa fa-info-circle ml-auto" ngbPopover="Service Tree Service GUID" popoverTitle="Service GUID"
                  container="body"></span>
          </label>
        </div>
        <input type="text" formControlName="serviceId" class="form-control required" aria-label="serviceId"
          aria-describedby="serviceIdLabel">
      </div>
      <div *ngIf="shouldShowValidationError(eventForm.controls.serviceId)" class="mb-3">
        <small class="danger-text">ServiceId must be a valid GUID.</small>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="showError">
    <small class="danger-text"> {{ errorMessage }} </small>
  </ng-container>
</div>

<div class="modal-footer">
  <div *ngIf="isSaving" class="ml-2">
    <span role="progressbar" class="info-text fa fa-spinner fa-spin fa-2x" aria-label="saving"></span>
  </div>
  <div *ngIf="isLoadingMasterData" class="ml-2">
    <span role="progressbar" class="info-text fa fa-spinner fa-spin fa-2x" aria-label="loading"></span>
  </div>
  <ng-container *ngIf="!isUpdate">
    <button type="button" class="btn primary-button" aria-label="Save" (click)="saveEventType()"
      [disabled]="isLoading || !eventForm.valid">Save</button>
  </ng-container>
  <ng-container *ngIf="isUpdate">
    <button type="button" class="btn primary-button" aria-label="Update" (click)="saveEventType()"
      [disabled]="isLoading || !eventForm.valid">Update</button>
  </ng-container>
  <button type="button" class="btn secondary-button" aria-label="Cancel" (click)="cancel()"
    [disabled]="isLoadingMasterData">Cancel</button>
</div>
