<div class="modal-header">
  <h4 class="modal-title">Update Details</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancelUpdate()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form [formGroup]="updateForm">
    <h6>Actual Score</h6>
    <div class="input-group input-group-sm mb-2">
      <input type="number" formControlName="score" class="form-control required" aria-label="Actual Score">
    </div>
    <div class="mb-2">
      <small class="alert-text" role="alert" aria-live="assertive"
        *ngIf="updateForm.get('score').invalid">Score must be between 0 and 100.</small>
    </div>
    <hr>
    <h6>Summary</h6>
    <div class="input-group input-group-sm mb-2">
      <textarea type="text" formControlName="summary" class="form-control" aria-label="Summary" rows=4>
            </textarea>
    </div>
  </form>

  <hr>

  <form [formGroup]="updateForm">
    <div class="input-group input-group-sm">
      <h6>IcM Incidents</h6>
      <div class="input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <label class="input-group-text" id="icmId" style="width: 5.5rem;">
            <span id="info_icmId" class="ml-2" container="body">
              IcM Id
            </span>
          </label>
        </div>
        <input type="text" formControlName="icmId" class="form-control required" aria-label="IcM Id">
      </div>
      <div class="mb-2">
        <small class="alert-text" role="alert" aria-live="assertive"
          *ngIf="updateForm.get('icmId').errors?.pattern ||
           (updateForm.get('icmId').invalid && updateForm.get('icmId').dirty && updateForm.get('icmId').touched)">
          IcM Id must be numeric and exactly 9 characters long.</small>
      </div>
      <div class="input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <label class="input-group-text" id="icmComments" style="width: 5.5rem;">
            <span id="info_icmComments" class="ml-2" container="body">
              Comments
            </span>
          </label>
        </div>
        <input type="text" formControlName="icmComments" class="form-control" aria-label="IcM Comments" rows=3>
      </div>
      <div>
        <button type="button" class="btn btn-sm btn-outline-dark" (click)="addIcM()"
          [disabled]="updateForm.get('icmId').invalid || updateForm.get('icmComments').invalid"
          aria-label="Add IcM Incident">
          <div class="fa-stack" style="cursor: pointer; font-size:0.75em;">
            <i class="text-success fa fa-circle fa-stack-2x"></i>
            <i class="fa fa-plus fa-stack-1x fa-inverse"></i>
          </div>
          Add IcM Incident
        </button>
        <button type="button" class="btn btn-sm btn-outline-dark ml-2" (click)="resetDirtyIcMForms()"
          aria-label="Clear IcM Incident forms">
          Clear
        </button>
      </div>
      <div class="mb-2">
        <small class="alert-text" role="alert" aria-live="assertive" 
          *ngIf="showDirtyIcMError">Please add or clear IcM entry in progress before saving.</small>
      </div>
    </div>
  </form>
  <br>
  <div class="table-responsive">
    <table class="table table-bordered table-striped" aria-label="Scenario Health details">
      <thead class="thead-dark">
        <tr>
          <th id="h11" class="text-center" style="width:15vh;" scope="col">Incident</th>
          <th id="h12" class="text-center" style="width:25vh;" scope="col">Comments</th>
          <th id="h13" class="text-center" style="width:5vh;" scope="col">Delete</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let incident of icmIncidents; let i=index; let even=even;" [ngClass]="{'even': even}">
          <td headers="h11" class="text-center">
            <a href="{{icmUrlPrefix}}/{{incident.icmNumber}}" target="_blank" rel="noopener noreferrer" class="link-text">
              {{incident.icmNumber}}
              <span class="fa fa-link" aria-hidden="true"></span>
            </a>
          </td>
          <td headers="h12" class="text-center">{{incident.comments}}</td>
          <td headers="h13" class="text-center">
            <a class="fa-stack" style="cursor: pointer; font-size:0.75em;" aria-label="Delete entry" [routerLink]="[]"
              (click)="deleteIcM(incident.icmNumber, incident.comments)">
              <i class="text-danger fa fa-circle fa-stack-2x"></i>
              <i class="fa fa-minus fa-stack-1x fa-inverse"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="mb-2">
    <small class="alert-text" role="alert" aria-live="assertive" 
      *ngIf="updateForm.errors?.duplicateIcMError">Duplicate IcM incident found - please delete before saving.</small>
  </div>
</div>

<div class="modal-footer">
  <div class="mb-2">
    <small class="alert-text" role="alert" aria-live="assertive"
      *ngIf="showError">{{errorMessage}}</small>
  </div>
  <div>
    <ng-container *ngIf="isLoading">
      <span class="fa fa-spinner fa-spin fa-2x"></span>
    </ng-container>
    <button type="button" class="btn btn-sm primary-button" aria-label="Save" (click)="updateDetails()"
      [disabled]="updateForm.errors?.duplicateIcMError || !updateForm.get('score').valid">Save</button>
    <button type="button" class="btn btn-sm secondary-button ml-2" aria-label="Cancel" (click)="cancelUpdate()">Cancel</button>
  </div>
</div>