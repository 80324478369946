import { Inject, Injectable } from '@angular/core';
import { RuleConfig, QueryParam } from '../model/rule.model';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from "@angular/common/http";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Guid } from "guid-typescript";
import { TemplateVariableRule, TemplateVariable } from '../model/template-variable.model';
import { TemplateVariableService } from './template-variable.service';
import { APP_CONFIG } from '../../common/constants';
import { ODataResponse } from '../../model/common.model';
import { AppConfig } from '../../model/app-config.model';

@Injectable({
  providedIn: 'root'
})
export class RuleService {

  private baseUrlWithVersion;

  constructor(private httpClient: HttpClient, private aiService: ApplicationInsights, @Inject(APP_CONFIG) appConfig: AppConfig
    , private templateVariableService: TemplateVariableService
  ) {
    this.baseUrlWithVersion = appConfig.baseUrlWithVersionBAMDashboard;
  }

  getRules(): Observable<RuleConfig[]> {
    return this.httpClient.get<ODataResponse<RuleConfig[]>>(this.baseUrlWithVersion + "Rule" + "?$select=Id,RuleName").pipe(
      catchError((x) => this.handleError(x)), map(res => {
        return res["value"];
      })
    );
  }

  getRulesByBusinessProcess(businessProcessId: string): Observable<RuleConfig[]> {
    return this.httpClient.get<ODataResponse<RuleConfig[]>>(this.baseUrlWithVersion +
      "Rule" + "?$filter=MappingId eq '" + businessProcessId + "'&$select=Id,RuleName").pipe(
        map(res => res.value)
      );
  }

  getAllRulesByProcess(businessProcessId: string): Observable<RuleConfig[]> {
    return this.httpClient.get<ODataResponse<RuleConfig[]>>(this.baseUrlWithVersion + "Rule?$filter=MappingId eq '" + businessProcessId + "'&$expand=Alert").pipe(
      map(res => res.value)
    );
  }

  getRuleById(id: number): Observable<RuleConfig> {
    return this.httpClient.get<RuleConfig>(this.baseUrlWithVersion + "Rule(" + id + ")?$expand=Alert");
  }

  addRule(ruleConfig: RuleConfig) {
    return this.httpClient.post<RuleConfig>(this.baseUrlWithVersion + "Rule", ruleConfig, { observe: 'response' });
  }

  updateRule(ruleConfig: RuleConfig) {
    return this.httpClient.put(this.baseUrlWithVersion + "Rule(" + ruleConfig.id + ")", ruleConfig, { observe: 'response' });
  }

  updateAlert(ruleConfig: RuleConfig) {
    return this.httpClient.put(this.baseUrlWithVersion + "Rule(" + ruleConfig.id + ")/Alert", ruleConfig.alert, { observe: 'response' });
  }

  executeSavedRule(id: number) {
    const queryString = "Result?id=" + id;
    return this.httpClient.get(this.baseUrlWithVersion + queryString, { observe: 'response' });
  }

  deleteRule(id: number) {
    return this.httpClient.delete(this.baseUrlWithVersion + "Rule(" + id + ")", { observe: 'response' });
  }

  executeRuleQuery(queryParams: QueryParam) {
    const queryString = "Result/DirectQuery";
    return this.templateVariableService.getTemplateVariableLookUpValue(+queryParams.mappingId).pipe(
      map((templateVariableLookupMap: Map<string, TemplateVariable>) => {
        queryParams.query = this.templateVariableService.getParsedQuery(queryParams.query, templateVariableLookupMap);
        return queryParams;
      }),
      switchMap(updatedQueryParams =>
        this.httpClient.post(this.baseUrlWithVersion + queryString, updatedQueryParams, { observe: 'response' }))
    );
  }

  getRuleByIdList(ruleIdList: string): Observable<Array<TemplateVariableRule>> {
    if (ruleIdList == null || ruleIdList === undefined) {
      ruleIdList = '';
    }
    ruleIdList = '(' + ruleIdList + ')';
    const url = this.baseUrlWithVersion + "Rule" + "?$select=Id,RuleName" + "&$filter=Id in" + ruleIdList + "&$orderby=Id";
    return this.httpClient.get<ODataResponse<Array<TemplateVariableRule>>>(url).pipe(
      map(response => {
        const ruleList = new Array<TemplateVariableRule>();
        // const rules: Array<> = response['value'];
        response.value.forEach((obj: {id: number, ruleName: string}) => {
          const templateVariableRule = new TemplateVariableRule();
          templateVariableRule.id = obj.id;
          templateVariableRule.ruleName = obj.ruleName;
          ruleList.push(templateVariableRule);
        });
        return ruleList;
      })
    );
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    let statusCode = 0;
    console.log(this.aiService);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred.
      errorMessage = `An error occurred: ${error.error.message}`;

      this.aiService.trackException(error);
    } else {
      // The backend returned an unsuccessful response code
      errorMessage = `Server returned code: ${error.status}, error message is: ${error.message}`;
      this.aiService.trackException(error);
      statusCode = error.status;
    }
    console.log(errorMessage);
    return throwError({ errorMessage, statusCode });
  }

  // Result Export and Download
  exportTableToCSV(columns: any, rows: any) {
    const csv = [];
    const filename = Guid.create().toString() + ".csv";

    // add column labels
    const columLabels = [];
    for (const col of columns) {
      columLabels.push(col.fieldNm.charAt(0).toUpperCase() + col.fieldNm.slice(1));
    }
    csv.push(columLabels.join(","));
    // add rows
    for (const row of rows) {
      const line = [];
      for (const col of columns) {
        if (String(row[col.fieldNm]).indexOf(',') > -1) {
          let tempStr: string = row[col.fieldNm];
          // to replace all occurrences of \" with " in nested json
          tempStr = tempStr.replace(/\\\"/g, '\"');
          // according to the CSV specs, to include double quotes within a string that is already quoted, you need to use two double quotes
          tempStr = tempStr.replace(/"/g, '""');
          // according to the CSV specs, to include comma separated values, should add external quotes
          line.push('\"' + tempStr + '\"');
        } else {
          // prefix with =" and suffix with " to stop converting number like 034565 -> 345665
          line.push('=\"' + row[col.fieldNm] + '\"');
        }
      }
      csv.push(line.join(","));
    }
    this.downloadCSV(csv.join("\n"), filename);
  }

  // Download CSV File
  downloadCSV(csv: BlobPart, filename: string) {
    let csvFile: Blob;
    let downloadLink: HTMLAnchorElement;

    csvFile = new Blob([csv], { type: "text/csv" });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  getColumnsFromRows(rows: string | any[]): Object {
    const cols = new Array<{}>();
    if (rows === undefined || rows.length === 0) { return cols; }
    const sampleRow = rows[0] as {};
    for (const key in sampleRow) {
      if (sampleRow.hasOwnProperty(key)) {
        cols.push({ label: key, fieldNm: key });
      }
    }
    return cols;
  }
}
