import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from '../common/constants';
import { AppConfig } from '../model/app-config.model';
import { CacheService } from './cache.service';

@Injectable({
    providedIn: 'root'
})
export class OneAuthZService {
    private RoleLookup: {[key: string]: string } = {
        "c9f430bf-454f-6232-d364-dc0002165ab4": "FinanceScoreCardReadInternal",
        "67eea5b6-cb15-2dd1-2e4a-d0cf27955415": "FinanceScoreCardRead",
        "c48cb997-e12d-b80c-8f57-3dc46a291347": "FinanceScoreCardWrite",
        "5c28e20b-931d-6ab0-a650-32072788f0cf": "ScoreCardRead",
        "a04e39ad-a942-4d8b-e892-be8726ebcf94": "ScoreCardReadInternal",
        "5fe2c11a-ee0e-2f73-9c23-973823e5f9e8": "ScoreCardWrite",
        "4a068549-00e1-adae-23b3-4524b115237c": "ManualMonitoringWrite",
        "2d9b9194-4ea9-5264-d552-42848627f239": "ManualMonitoringRead",
        "e12a4765-2341-8ce6-1432-d08a23f2702c": "BAMReader",
        "a0c2ed71-3029-8c61-b161-3525efb09648": "BAMWriter",
        "788627f2-5beb-7a30-3a4b-f49638e15c57": "BAMAdmin",
        "2407dc69-d9fe-4781-7466-637ce13e47f4": "CrewScoreCardRead",
        "71ca00b1-5d7b-1272-b175-5a29399c26dc": "CrewScoreCardWrite",
        "f2f74409-131a-9445-82fc-8f0c4515de56": "QoSReader",
        "cfa88914-eed8-c7b5-7f7b-c2295433dc07": "QoSWriter",
        "71d9bbfc-0fda-433b-7321-92475979faf3": "QoSAdmin",
        "a9f5f721-13e9-f3c9-8eaa-9d8f6742a749": "MCReader",
        "16b2cd08-35b6-3590-3075-ada630b3869f": "MCWriter",
        "1a308147-048e-3e12-727c-bea8abc6a7b9": "MCAdmin"
    };
    public userRoles: string[];

    constructor(private msalService: MsalService, private httpClient: HttpClient, @Inject(APP_CONFIG) private appConfig: AppConfig,
    private cacheService: CacheService) {
    }

    public LoadRoles() {
        const payload = {
            "searchParams": {
                "scope": "/",
                "direction": "ScopeAndBelow",
                "principalId": this.msalService.instance.getActiveAccount().localAccountId,
                "expandPrincipalGroups": true
            }
        };
        return new Promise<void>((resolve, reject) => {
            this.httpClient.post<any>(this.appConfig.oneAuthZPAPUrl + "roleAssignments/search?api-version=2017-02-01-preview",
                payload).pipe(map((response) => {
                    return response.value.map((data: DataInterface) => {
                        return this.RoleLookup[data.roleDefinitionId];
                    });
                })).subscribe(x => {
                    this.userRoles = x;
                    resolve();
                });
        });
    }

    public AllowedManualScenarios() {
        const payload = {
            "searchParams": {
                "scope": "/manualMonitoring",
                "direction": "ScopeAndBelow",
                "principalId": this.msalService.instance.getActiveAccount().localAccountId,
                "expandPrincipalGroups": true
            }
        };
        return this.httpClient.post<any>(this.appConfig.oneAuthZPAPUrl +
            "roleAssignments/search?api-version=2017-02-01-preview",
            payload).pipe(map((response) => {
                return response.value.map((data: DataInterface) => {
                    return data.condition.replace('@Resource.ScenerioId Any_of {', '').replace('}', '');
                }).join();
            }));
    }

    public AllowedTeamGroups() {
        const payload = {
            "searchParams": {
                "scope": "/qosDashboard",
                "direction": "ScopeAndBelow",
                "principalId": this.msalService.instance.getActiveAccount().localAccountId,
                "expandPrincipalGroups": true
            }
        };
        return this.httpClient.post<any>(this.appConfig.oneAuthZPAPUrl +
            "roleAssignments/search?api-version=2017-02-01-preview", payload)
            .pipe(map((response) => {
                return response.value.map((data: DataInterface) => {
                    return data.condition.replace('@Resource.TeamGroupId Any_of {', '').replace('}', '')
                        .replace(new RegExp('\'', 'g'), '').toLowerCase()
                        .split(',').map((tg: string) => tg.trim());
                }).join();
            }));
    }

    public CheckAccess(scope: string, action: string, userId: string, groupId: string) {
        const payload = {
            "Resource": {
                "Attributes": [{
                    "GroupId": groupId
                }],
                "Id": scope
            },
            "Subject": {
                "Attributes": [{
                    "ObjectId": userId
                }]
            },
            "Action": {
                "Attributes": [{}],
                "Id": action
            },
            "GetMemberGroups" : true,
            "ReturnMissingAttributes" : true
        };
        const pdpUrl = this.appConfig.oneAuthZPDPUrl + "CheckAccess?api-version=2017-07-01-preview";
        return this.cacheService.getCachedWithPayload<PDPResponse>(pdpUrl, scope + '_' + action + '_' + groupId,
            (url) => this.httpClient.post<PDPResponse>(url, payload)
        );
    }
}

interface DataInterface {
    condition: string;
    roleDefinitionId: string;
}

export interface PDPResponse {
    isAccessGranted: boolean;
}


