import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  RuleMasterData, RuleOperator, DataType, EntityMetadata,
  ServiceGroupResponse, TeamGroupResponse, TeamGroupAdminResponse
} from '../model/rule.model';
import { InstanceFilters, Process, TeamGroup, ServiceGroup } from '../model/process.model';
import { CacheService } from '../../service/cache.service';
import { APP_CONFIG } from '../../common/constants';
import { ODataResponse } from '../../model/common.model';
import { AppConfig } from '../../model/app-config.model';
import { DataSource, DataSourceTypeDetails } from '../model/data-source.model';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {
  private baseUrlWithVersion: string;
  private baseUrlWithVersionMonitoringCatalog: string;
  private businessProcessInstanceMap = new Map<string, InstanceFilters[]>();

  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) appConfig: AppConfig,
    private cacheService: CacheService
  ) {
    this.baseUrlWithVersion = appConfig.baseUrlWithVersionBAMDashboard;
    this.baseUrlWithVersionMonitoringCatalog = appConfig.baseUrlWithVersion;
  }

  getEntityMetadata(organizationId: string): Observable<EntityMetadata[]> {
    return this.cacheService.getCached<any>(this.baseUrlWithVersion +
      "EntityMetadata" + "?$filter=MappingId eq '" + organizationId + "'&$select=Id,MappingId",
      (url) => this.httpClient.get<ODataResponse<EntityMetadata[]>>(url).pipe(
        map((x) => x.value)
      )
    );
  }

  getMasterData(entityList: Array<string>): Observable<RuleMasterData[]> {
    return this.cacheService.getCached<any>(this.baseUrlWithVersion +
      "RuleMasterData" + "?$filter=EntityMetaData/Id in (" + entityList + ")",
      (url) => this.httpClient.get<ODataResponse<RuleMasterData[]>>(url).pipe(
        map((x) => x.value)
      )
    );
  }

  getRuleOperators(): Observable<RuleOperator[]> {
    return this.cacheService.getCached<any>(this.baseUrlWithVersion + "RuleOperator",
      (url) => this.httpClient.get<ODataResponse<RuleOperator[]>>(url).pipe(
        map((x) => x.value)
      )
    );
  }

  getDataTypes(): Observable<DataType[]> {
    return this.cacheService.getCached<any>(this.baseUrlWithVersion + "DataType",
      (url) => this.httpClient.get<ODataResponse<DataType[]>>(url).pipe(
        map((x) => x.value)
      )
    );
  }

  getDataSources(): Observable<DataSource[]> {
    return this.cacheService.getCached<DataSource[]>(this.baseUrlWithVersion + "DataSource?$expand=TypeDetails",
      (url) => this.httpClient.get<ODataResponse<DataSource[]>>(url).pipe(
        map((x) => x.value)
      )
    );
  }

  getDataSourcesByGroupId(groupId: string): Observable<DataSource[]> {
    return this.cacheService.getCached<DataSource[]>(this.baseUrlWithVersion + "DataSource?$filter=tolower(OwnerGroupId) eq tolower('" + groupId + "')&$expand=TypeDetails",
      (url) => this.httpClient.get<ODataResponse<DataSource[]>>(url).pipe(
        map((x) => x.value)
      )
    );
  }

  getDataSourceById(dataSourceId: number): Observable<DataSource> {
    const dataSourceUrl = this.baseUrlWithVersion + "DataSource(" + dataSourceId + ")?$expand=TypeDetails";
    return this.cacheService.getCached<DataSource>(dataSourceUrl,
      (url) => this.httpClient.get<DataSource>(url)
    );
  }

  getCommonAndSpecificGroupDataSources(groupId: string): Observable<DataSource[]> {
    return this.cacheService.getCached<DataSource[]>(this.baseUrlWithVersion + "DataSource?$filter=tolower(OwnerGroupId) eq tolower('" + groupId + "') or OwnerGroupId eq '00000000-0000-0000-0000-000000000000' &$expand=TypeDetails",
      (url) => this.httpClient.get<ODataResponse<DataSource[]>>(url).pipe(
        map((x) => x.value)
      )
    );
  }

  getNonImportedDataSources(groupId = ""): Observable<DataSource[]> {
    let groupFilterCondition = "";
    if (groupId !== "") {
      groupFilterCondition += "tolower(OwnerGroupId) eq tolower('" + groupId + "') or ";
    }
    groupFilterCondition += "OwnerGroupId eq '00000000-0000-0000-0000-000000000000'";
    return this.cacheService.getCached<DataSource[]>(this.baseUrlWithVersion + "DataSource?$filter=(" + groupFilterCondition + ") and TypeDetails/IsImported eq false &$expand=TypeDetails",
      (url) => this.httpClient.get<ODataResponse<DataSource[]>>(url).pipe(
        map((x) => x.value)
      )
    );
  }

  getDataSourceTypes(isSelfServe: boolean): Observable<DataSourceTypeDetails[]> {
    let dataSourceUrl = this.baseUrlWithVersion + "DataSourceTypeDetails";
    if (isSelfServe) {
      dataSourceUrl = dataSourceUrl + "?$filter=IsSelfServe eq " + isSelfServe;
    }
    return this.cacheService.getCached<DataSourceTypeDetails[]>(dataSourceUrl,
      (url) => this.httpClient.get<ODataResponse<DataSourceTypeDetails[]>>(url).pipe(
        map((x) => x.value)
      )
    );
  }

  clearDataSourceCache() {
    let regex = new RegExp(this.baseUrlWithVersion + 'DataSource*');
    this.cacheService.clearCacheByRegex(regex);
  }

  // todo: not used anywhere, to be deleted.
  // getComboBoxData(): Observable<any> {
  //   return this.httpClient.get<ODataResponse<{name: string, value: string}>>(this.baseUrlWithVersion +
  //     "BAMMasterData?$filter=GroupName eq 'CreateScreen' and SubGroupName  eq 'ComboBox'&$select=name,value").pipe(
  //       map(res => {
  //         return res.value
  //         .reduce((rv, x) => {
  //           (rv[x['name']] = rv[x['name']] || []).push(x.value);
  //           return rv;
  //         }, {});
  //       })
  //     );
  // }

  // TODO: replace with call to service tree for Team Group.
  getTeamGroups(organizationId: string): Observable<Array<TeamGroup>> {
    // Call service tree to get the list of Team Groups
    const teamGroupUrl = this.baseUrlWithVersionMonitoringCatalog + `ServiceTree/ServiceGroup/${organizationId}/TeamGroup`;
    // .replace("{serviceGroupId}", organizationId);
    return this.cacheService.getCached<any[]>(teamGroupUrl,
      (url) => this.httpClient.get<TeamGroupResponse[]>(url).pipe(
        map(x => x.map(ele => new TeamGroup(ele.Id, ele.Name, ele.ServiceGroupId)))
      ));
  }

  // Get the list of admins by team group id.
  getTeamGroupAdminsById(teamGroupId: string): Observable<Array<string>> {
    const teamGroupAdminUrl = this.baseUrlWithVersionMonitoringCatalog + `ServiceTree/TeamGroup/${teamGroupId}/admins`;

    return this.cacheService.getCached<any[]>(teamGroupAdminUrl,
      (url) => this.httpClient.get<TeamGroupAdminResponse[]>(url).pipe(
        map(x => x.map(ele => ele.Id))
      ));
  }

  getTeamGroupById(teamGroupId: string): Observable<TeamGroup> {
    const teamGroupUrl = this.baseUrlWithVersionMonitoringCatalog + `ServiceTree/TeamGroup/${teamGroupId}`;
    return this.cacheService.getCached<any>(teamGroupUrl,
      (url) => this.httpClient.get<TeamGroupResponse>(url).pipe(
        map(x => new TeamGroup(x.Id, x.Name, x.ServiceGroupId))
      )
    );
  }

  getServiceGroupById(serviceGroupId: string): Observable<ServiceGroup> {
    const serviceGroupUrl = this.baseUrlWithVersionMonitoringCatalog + `ServiceTree/ServiceGroup/${serviceGroupId}`;
    return this.cacheService.getCached<any>(serviceGroupUrl,
      (url) => this.httpClient.get<ServiceGroupResponse>(url).pipe(
        map(x => new ServiceGroup(x.Id, x.Name, x.OrganizationId))
      )
    );
  }

  getBusinessProcessById(businessProcessId: string): Observable<Process> {
    const businessProcessUrl = this.baseUrlWithVersion + 'Process/' + businessProcessId;
    return this.cacheService.getCached<Process>(businessProcessUrl,
      (url) => this.httpClient.get<Process>(url)
    );
  }

  getBusinessProcessList(teamGroupId: string): Observable<Array<Process>> {
    const businessProcessListUrl = this.baseUrlWithVersion + 'Process/' + teamGroupId + '/processlist';
    return this.cacheService.getCached<Process[]>(businessProcessListUrl,
      (url) => this.httpClient.get<Process[]>(url).pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 404) {
            return of(new Array<Process>());
          } else {
            return throwError(err);
          }
        }),
        map((x: Array<Process>) => {
          for (const process of x) {
            this.businessProcessInstanceMap.set(process.id, process.instanceFilters);
          }
          return x;
        })
      ));
  }

  getProcessInstanceFilters(businessProcessId: string): InstanceFilters[] {
    return this.businessProcessInstanceMap.get(businessProcessId);
  }

  getCurrentlyUsedTeamGroupIcMRoutingIds(ruleId: string): Observable<string[]> {
    return this.cacheService.getCached<string[]>(this.baseUrlWithVersion + "Rule(" + ruleId + ")/TeamGroupRoutingIds",
      (url) => this.httpClient.get<ODataResponse<string[]>>(url).pipe(
        map((x: ODataResponse<string[]>) => x.value)
      )
    );
  }
}
