import { OnInit, Input, Component } from '@angular/core';
import { Operation, Service } from '../model/qos.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { QoSService } from '../service/qos.service';
import { HttpErrorResponse } from '@angular/common/http';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'app-update-component',
  templateUrl: './update-component.component.html',
  styleUrls: ['./update-component.component.css']
})
export class UpdateComponent implements OnInit {
  @Input() service: Service;
  operation: Operation;

  componentForm = new UntypedFormGroup({
    serviceOid: new UntypedFormControl(''),
    serviceName: new UntypedFormControl(''),
    telemetryName: new UntypedFormControl(''),
    latencyThreshold: new UntypedFormControl('', [Validators.required, Validators.pattern(/^[1-9]\d*$/)]),
    critical: new UntypedFormControl(''),
    customers: new UntypedFormControl(''),
    lastModifiedBy: new UntypedFormControl(''),
    lastModifiedDate: new UntypedFormControl('')
  });

  showDeleteConfirmation: boolean;
  isLoading: boolean;
  showError: boolean;
  errorMessage: string;

  constructor(
    private activeModal: NgbActiveModal,
    private qosService: QoSService
  ) {
    this.showDeleteConfirmation = false;
    this.isLoading = false;
    this.showError = false;
    this.errorMessage = '';
  }

  ngOnInit() {
    this.operation = cloneDeep(this.service.operations[0]);
    this.componentForm.get('telemetryName').setValue(this.operation.name);
    this.componentForm.get('serviceOid').setValue(this.service.serviceOid);
    this.componentForm.get('serviceName').setValue(this.service.serviceName);
    this.componentForm.get('latencyThreshold').setValue(this.operation.latencyThreshold / 10000);
    this.componentForm.get('critical').setValue(this.operation.critical);
    this.componentForm.get('customers').setValue(this.operation.customers);
    this.componentForm.get('lastModifiedBy').setValue(this.operation.lastModifiedBy);
    this.componentForm.get('lastModifiedDate').setValue(this.operation.lastModifiedDate);
  }

  cancelUpdate(): void {
    this.activeModal.dismiss();
  }

  updateOperation(): void {
    this.operation.name = this.componentForm.get('telemetryName').value.trim();
    this.operation.latencyThreshold = this.componentForm.get('latencyThreshold').value * 10000; // convert ms to ticks
    this.operation.critical = this.componentForm.get('critical').value;
    this.operation.customers = this.componentForm.get('customers').value;
    this.service.operations[0] = this.operation;

    this.isLoading = true;
    this.qosService.updateOperation(this.service).subscribe(
      () => {
        this.isLoading = false;
        this.activeModal.close();
      },
      (error: HttpErrorResponse) => {
        console.error('error is ', error);
        this.isLoading = false;
        this.showError = true;
        this.errorMessage = error.error.value;
      }
    );
  }

  deleteOperation(): void {
    this.isLoading = true;
    this.componentForm.disable();
    this.qosService.deleteOperation(this.operation).subscribe(
      () => {
        this.isLoading = false;
        this.activeModal.close();
      },
      (error: HttpErrorResponse) => {
        console.error('error is ', error);
        this.isLoading = false;
        this.showError = true;
        this.errorMessage = error.error.value;
      }
    );
  }

  toggleDeleteConfirmation(showConfirmation: boolean): void {
    this.showDeleteConfirmation = showConfirmation;
  }
}
