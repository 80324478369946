export class AliasStatus {
  alias: string;
  isValid: boolean;

  constructor(alias: string, status: number) {
    this.alias = alias;
    if (status === 200) {
      this.isValid = true;
    } else {
      this.isValid = false;
    }
  }
}
