<!-- title -->
<h5 class="display-5 ml-3 mt-3">BAM Process Health </h5>
<hr>
<div class="d-flex flex-column border border-dark mx-1 pb-1 px-2">
  <!-- service tree component. -->
  <div class="border border-dark rounded ml-1 mt-2" id="Treeview" style="width: 49rem;">
    <!-- Treeview -->
    <app-tree-view [loadProcess]="true" (valueChange)="serviceTreeEventHandler($event)">
    </app-tree-view>
  </div>
  <div class="d-flex flex-row ml-1 my-1">
    <!-- process query button. -->
    <div *ngIf="enableFilterPanel !== true && isProcessSelected" class="d-flex flex-row my-2 ml-1">
      <button class="btn btn-sm primary-button" type="submit" (click)="getProcessMapWithHealthInfo()">Process</button>
      <button class="btn btn-sm danger-button ml-2" type="button" (click)="cancelExecution()"
        [disabled]="!isProcessHealthLoading">Cancel</button>
      <div *ngIf="isProcessHealthLoading === true">
        <span class="ml-2 text-primary fa fa-spinner fa-spin fa-2x" aria-label="Processing"></span>
      </div>
    </div>
  </div>
</div>
<!-- filter panel -->
<div *ngIf="enableFilterPanel === true" class="border border-dark m-1">
  <app-date-range [isRequestInProgress]="isProcessHealthFilterLoading" (selectedDateRange)="processFilterValues($event)"
    (cancelFilterExecution)="cancelExecution()"></app-date-range>
</div>

<!-- process map with alerts. -->
<div class="d-flex flex-row border-top border-right border-left border-dark mx-1 mt-1 px-2">
  <div class="ml-1 mt-1">
    <h6>Alert Count</h6>
  </div>
</div>

<!-- Business Process Graph. -->
<div class="d-flex flex-row" [attr.aria-label]="graphDescription" tabindex="0">
  <!-- network div. -->
  <div class="border border-dark mx-1" id="processMapWithAlertCount" style="height: 50vh; flex: 1;">
  </div>
</div>
