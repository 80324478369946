import { Network, Node, Edge, Options, Data } from 'vis-network/esnext';
import { DataSet } from'vis-data/esnext';
import { Observable } from 'rxjs';
import { GraphModel, GraphLabelMap, ProcessNode, ProcessEdge, ProcessProperty } from '../../model/graph.model';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { GraphSystemProperties, NodeColor } from '../constants';

export class Graph {
  public visNetwork: Network;

  // process graph variables.
  protected nodes: Array<ProcessNode>;
  protected edges: Array<ProcessEdge>;
  protected properties: Array<ProcessProperty>;
  protected nodeStatusMap: Map<number, string>;

  // graph variables.
  private data: Data;
  private nodeDataset: DataSet<Node>;
  private edgeDataset: DataSet<Edge>;
  private options: Options;

  private graphProperty: Array<ProcessProperty>;
  private nodePropertyMap: Map<number, Array<ProcessProperty>>;
  private edgePropertyMap: Map<number, Array<ProcessProperty>>;

  private htmlDisplayElement: HTMLElement;

  private isMiddleArrowAnimated: boolean;

  constructor(protected appInsightsService: ApplicationInsights) {
    this.nodes = new Array<ProcessNode>();
    this.edges = new Array<ProcessEdge>();
    this.properties = new Array<ProcessProperty>();

    this.data = {} as Data;
    this.nodeDataset = new DataSet<Node>();
    this.edgeDataset = new DataSet<Edge>();

    this.graphProperty = new Array<ProcessProperty>();
    this.nodePropertyMap = new Map<number, any>();
    this.edgePropertyMap = new Map<number, any>();

    this.nodeStatusMap = new Map<number, string>();

    this.htmlDisplayElement = null;
    this.isMiddleArrowAnimated = false;
  }

  public getNodes(): Array<ProcessNode> {
    return this.nodes;
  }

  public getEdges(): Array<ProcessEdge> {
    return this.edges;
  }

  // Visjs methods.
  animateEdges(edgeList: Array<string>) {
    for (const edgeItem of edgeList) {
      const edge: Edge = this.edgeDataset.get(edgeItem);
      // change the arrow positions.
      this.animateEdge(edge);
    }
    this.isMiddleArrowAnimated = !this.isMiddleArrowAnimated;
    this.visNetwork.redraw();
  }

  animateEdge(edge: Edge): void {
    if (this.isMiddleArrowAnimated) {
      edge.arrows = {
        to: {
          type: 'arrow',
          scaleFactor: 1,
          enabled: true
        },
        middle: {
          type: 'arrow',
          scaleFactor: 1,
          enabled: false
        }
      };
      this.edgeDataset.update(edge);
    } else {
      edge.arrows = {
        to: {
          type: 'arrow',
          scaleFactor: 1,
          enabled: false
        },
        middle: {
          type: 'arrow',
          scaleFactor: 1,
          enabled: true
        }
      };
      this.edgeDataset.update(edge);
    }
  }

  changeArrowType(edgeId: string, restore: boolean) {
    if (restore) {
      const edge: Edge = this.edgeDataset.get([edgeId])[0];
      edge.arrows = {
        to: {
          type: 'arrow',
          scaleFactor: 1,
          enabled: true
        },
        middle: {
          type: 'arrow',
          scaleFactor: 1,
          enabled: false
        }
      };
      edge.color = {
        inherit: 'false',
        color: '#000000'
      };
      edge.width = 3;
      this.edgeDataset.update(edge);
    } else {
      const edge: Edge = this.edgeDataset.get([edgeId])[0];
      edge.arrows = {
        to: {
          type: 'arrow',
          scaleFactor: 1,
          enabled: true
        },
        middle: {
          type: 'arrow',
          scaleFactor: 1,
          enabled: false
        }
      };
      edge.color = {
        color: '#129c12',
        inherit: 'false'
      };
      edge.width = 6;
      this.edgeDataset.update(edge);
    }
  }

  setHTMLElement(htmlDivElement: HTMLElement) {
    this.htmlDisplayElement = htmlDivElement;
  }

  redrawGraph() {
    this.visNetwork.redraw();
    this.visNetwork.fit();
  }

  // create dataset from process graph.
  initializeGraph() {
    this.data.nodes = this.nodeDataset;
    this.data.edges = this.edgeDataset;

    // add nodes to graph.
    this.nodes.forEach(processNode => {
      if (processNode.displayName !== 'Start' && processNode.displayName !== 'End' && processNode.displayName !== 'Active') {
        this.nodeDataset.add({
          id: processNode.id,
          label: processNode.displayName,
          shape: 'box',
          color: this.nodeStatusMap.get(processNode.id),
          font: {
            color: '#FFFFFF',
            size: 35
          }
        });
      } else {
        this.nodeDataset.add({
          id: processNode.id,
          label: processNode.displayName,
          fixed: { x: true, y: true },
          font: {
            size: 35
          }
        });
      }
    });

    // add edges to graph.
    this.edges.forEach(processEdge => {
      this.edgeDataset.add({
        id: processEdge.id,
        to: processEdge.to,
        from: processEdge.from,
        arrows: {
          to: {
            enabled: true,
            scaleFactor: 1
          },
          middle: {
            enabled: false,
            scaleFactor: 1
          }
        },
        color: {
          color: '#000000',
          inherit: 'false'
        },
        width: 3
      });
    });

    // set choosen property false. Property does not exist in node types.
    this.edgeDataset.forEach((edge: Edge) => {
      (edge as GraphEdge).chosen = false;
      this.edgeDataset.update(edge);
    });

    this.options = {} as Options;
    this.options.layout = {
      improvedLayout: true,
      hierarchical: {
        direction: 'LR',
        sortMethod: 'directed'
      }
    };

    this.visNetwork = new Network(this.htmlDisplayElement, this.data, this.options);
    this.visNetwork.storePositions();
    let startNode: Node;
    let endNode: Node;
    let activeNode: Node;
    this.data.nodes.forEach(node => {
      if (node.label === 'Start') {
        startNode = node;
      } else if (node.label === 'End') {
        endNode = node;
      } else if (node.label === 'Active') {
        activeNode = node;
        return;
      }
      node.x *= 1.2;
      node.y = 0;
      this.nodeDataset.update(node);
    });

    if (activeNode) {
      if (endNode) {
        activeNode.x = endNode.x;
        activeNode.y = (endNode.x - startNode.x) / 3.0;
      } else {
        activeNode.x *= 1.2;
        activeNode.y = 0;
      }
      this.nodeDataset.update(activeNode);
    }

    this.options = {} as Options;
    this.options.physics = {
      enabled: true,
      barnesHut: {
        gravitationalConstant: -10000,
        springLength: 350,
        springConstant: 0.001
      },
    };
    this.options.interaction = {
      hover: true
    };
    this.visNetwork = new Network(this.htmlDisplayElement, this.data, this.options);
  }

  clearGraph(): void {
    this.nodeDataset.clear();
    this.edgeDataset.clear();
    this.redrawGraph();
  }

  destroyGraph(): void {
    this.visNetwork.destroy();
  }

  addNode(processNode: ProcessNode) {
    this.nodes.push(processNode);
    this.setNodeProperties(processNode.id, processNode.properties);
    this.setNodeStatus(processNode);
  }

  setNodeStatus(processNode: ProcessNode) {
    // set node color to indicate status.
    if (processNode.properties != null && processNode.properties.length !== undefined && processNode.properties.length !== 0) {
      const statusProperty = processNode.properties.find(p => p.name === GraphSystemProperties.Status);
      if (statusProperty != null) {
        if (statusProperty.value === 'Success') {
          this.nodeStatusMap.set(processNode.id, NodeColor.GREEN);
        } else {
          this.nodeStatusMap.set(processNode.id, NodeColor.RED);
        }
      } else {
        const referenceProperty = processNode.properties.find(p => p.name === GraphSystemProperties.isReference);
        if (referenceProperty != null) {
          if (referenceProperty.value) {
            this.nodeStatusMap.set(processNode.id, NodeColor.GREY);
          } else {
            this.nodeStatusMap.set(processNode.id, NodeColor.DEFAULT);
          }
        } else {
          this.nodeStatusMap.set(processNode.id, NodeColor.DEFAULT);
        }
      }
    } else {
      this.nodeStatusMap.set(processNode.id, NodeColor.DEFAULT);
    }
  }

  addEdge(processEdge: ProcessEdge) {
    this.edges.push(processEdge);
    this.setEdgeProperties(processEdge.id, processEdge.properties);
  }

  // graph properties.
  setGraphProperties(properties: Array<ProcessProperty>): void {
    this.graphProperty = properties;
  }

  getGraphProperties(): Array<ProcessProperty> {
    return this.graphProperty;
  }

  getNodeProperties(id: number): Array<ProcessProperty> {
    if (this.nodePropertyMap.has(id)) {
      return this.nodePropertyMap.get(id);
    } else {
      this.appInsightsService.trackTrace({ message: `Graph: Node property not found for ${id}`});
      return null;
    }
  }

  getNodeLabel(id: number): string {
    const node: Node = this.nodeDataset.get(id);
    return node.label;
  }

  getNodeEventName(id: number): string {
    const node: ProcessNode = this.nodes.find(p => p.id === id);
    return node.event;
  }

  getEdgeProperties(id: number): Array<ProcessProperty> {
    if (this.edgePropertyMap.has(id)) {
      return this.edgePropertyMap.get(id);
    } else {
      this.appInsightsService.trackTrace({ message: `Graph: Edge property not found for ${id}`});
      return null;
    }
  }

  // set hierarchical or other layout options.
  registerNodeClick(): Observable<string> {
    return new Observable<string>((observer) => {
      this.visNetwork.on('selectNode', (node: NodeEvent) => {
        if (node.nodes.length === 1) {
          observer.next(node.nodes[0]);
        }
      });
    });
  }

  registerEdgeClick(): Observable<string> {
    return new Observable<string>((observer) => {
      this.visNetwork.on('selectEdge', (edge: EdgeEvent) => {
        if (edge.edges.length === 1) {
          observer.next(edge.edges[0]);
        }
      });
    });
  }

  updateLabels(updateModel: GraphModel) {
    this.updateNodeLabels(updateModel.nodes);
    this.updateEdgeLabels(updateModel.edges);
  }

  updateNodeLabels(nodes: Array<GraphLabelMap>) {
    nodes.forEach(node => {
      this.nodeDataset.update({ id: node.id, label: node.label });
    });
  }

  updateEdgeLabels(edges: Array<GraphLabelMap>) {
    edges.forEach((edge: GraphLabelMap) => {
      if ('title' in edge) {
        this.edgeDataset.update({ id: edge.id, label: edge.label, title: edge.title.toString() });
      } else {
        this.edgeDataset.update({ id: edge.id, label: edge.label.toString(), title: edge.label.toString() });
      }
    });
  }

  clearEdges() {
    this.edgeDataset.forEach((edge: Edge) => {
      this.edgeDataset.update({
        id: edge.id,
        width: 3,
        color: {
          color: '#000000',
          inherit: 'false'
        },
        arrows: {
          to: {
            enabled: true,
            scaleFactor: 1
          },
          middle: {
            enabled: false,
            scaleFactor: 1
          }
        }
      });
    });
  }

  isEmpty(): boolean {
    return this.nodes.length === 0;
  }

  // node property methods.
  private setNodeProperties(id: number, properties: Array<ProcessProperty>): void {
    this.nodePropertyMap.set(id, properties);
  }

  // edge property methods.
  private setEdgeProperties(id: number, properties: Array<ProcessProperty>): void {
    this.edgePropertyMap.set(id, properties);
  }
}

interface GraphEdge extends Edge {
  chosen: boolean;
}

interface NodeEvent {
  nodes: Array<string>;
}

interface EdgeEvent {
  edges: Array<string>;
}


