import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TreeItem, TreeviewItem } from 'ngx-treeview';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { NgbModalErrorComponent } from '../../modal/ngb-modal-error.component';
import { AppConfig } from '../../model/app-config.model';
import { LocalCacheService } from '../../service/local-cache.service';
import { APP_CONFIG, constants } from '../constants';
import { TreeDataService } from '../interface/tree-data-service';
import { TreeConfig, TreeLevelData, TreeNode, TreeSelection, TreeviewItemValue } from '../model/tree-view.model';

@Injectable()
export class TreeviewService implements OnDestroy {

  // Tree Properties
  nodes: Subject<TreeviewItem[]>;
  config: ReplaySubject<TreeConfig>;
  treeStorage: TreeviewItem[];
  height: number;
  title: string;
  selectedValueId: string;

  // Subscription
  treeviewSubscription: Subscription;

  rootNode: TreeNode;

  loadProcess: boolean;
  levelsToLoad : number;
  levelsLoaded = 0;

  constructor(
    private localCacheService: LocalCacheService,
    private treeDataService: TreeDataService,
    private modalService: NgbModal,
    @Inject(APP_CONFIG) appConfig: AppConfig,
  ) {
    this.treeviewSubscription = new Subscription();
    this.config = new ReplaySubject<TreeConfig>();
    this.nodes = new Subject<TreeviewItem[]>();
    this.treeStorage = new Array<TreeviewItem>();
    this.title = "";
    this.height = 200;
    this.selectedValueId = null;

    this.rootNode = new TreeNode();
    this.rootNode.id = appConfig.rootNodeId;
    this.rootNode.type = constants.ROOT;
    this.rootNode.level = 0;
    // no. of tree levels to display
    this.levelsToLoad = appConfig.hierarchyLevelsToLoad;
  }

  ngOnDestroy() {
    this.treeviewSubscription.unsubscribe();
    this.nodes.complete();
    this.config.complete();
  }

  public getTreeConfigSubject(): Observable<TreeConfig> {
    return this.config.asObservable();
  }

  public getTreeNodesSubject(): Observable<TreeviewItem[]> {
    return this.nodes.asObservable();
  }

  public setHeight(height: number): void {
    this.height = height;
  }

  public setTitle(title: string): void {
    this.title = title;
  }

  private CreateTreeViewConfig(): TreeConfig {
    const conf = new TreeConfig();
    conf.title = this.title;
    conf.maxHeight = this.height;
    conf.hasFilter = true;
    conf.hasCollapseExpand = true;
    return conf;
  }

  // Load initial tree view
  loadTreeView(loadProcess: boolean, id: string = null, useLocalCache: boolean = true): void {
    const config = this.CreateTreeViewConfig();
    this.loadProcess = loadProcess;
    this.config.next(config);
      
    // load the tree based on selected item's id (Note: This mostly used for deep linking)
    if (id != null) {
      this.treeviewSubscription = this.treeDataService.getHierarchyByProcessId(id).subscribe(
        (res: TreeNode[]) => {
          const resTreeViewItems = this.mapTreeNodeToTreeItem(res);
          this.convertParentFromTreeNodeToTreeViewRecursion(resTreeViewItems, null);
          this.treeStorage = resTreeViewItems;
          this.nodes.next(this.treeStorage);
        },
        (error: HttpErrorResponse) => {
          const modal = this.modalService.open(NgbModalErrorComponent);
          modal.componentInstance.message = "Error occured while loading service tree, Please contact administrator.";
          modal.result.then(
            () => {
              this.nodes.next(this.treeStorage);
            }
          );
        }
      );
    } else {
      // Get tree data
      this.treeviewSubscription = this.treeDataService.getChildren(this.rootNode).subscribe(
        (res: TreeNode[]) => {
          const resTreeViewItems = this.mapTreeNodeToTreeItem(res);
          this.convertParentFromTreeNodeToTreeViewRecursion(resTreeViewItems, null);
          this.treeStorage = resTreeViewItems;

          // load hierarchy if local storage have selected item
          let selectedItem = this.localCacheService.getItem("SELECTED_ITEM") as TreeLevelData[];
          if (useLocalCache && selectedItem !== null) {
            if (!this.loadProcess && selectedItem[0].type == constants.BUSINESS_PROCESS) {
              selectedItem.shift();
            }
            const item = this.treeStorage.find(x => (x.value as TreeviewItemValue).id === selectedItem[selectedItem.length - 1].id);
            this.loadHierarchyForSelectedItem(item, selectedItem, selectedItem.length - 1);
          } else {
            this.nodes.next(this.treeStorage);
          }
        },
        (error: HttpErrorResponse) => {
          const modal = this.modalService.open(NgbModalErrorComponent);
          modal.componentInstance.message = "Error occured while loading service tree, Please contact administrator.";
          modal.result.then(
            () => {
              this.nodes.next(this.treeStorage);
            }
          );
        }
      );
    }
  }

  getTreeNodeLevel(node: TreeNode, level:number): number {
    if(node.parent != null) {
      return this.getTreeNodeLevel(node.parent, level+1);
    } else {
      return level;
    }
  }

  // Convert TreeviewItem to TreeNode
  mapTreeviewItemToTreeNode(treeItem: TreeviewItem): TreeNode {
    const itemVal = treeItem.value as TreeviewItemValue;
    const node = new TreeNode();
    node.id = itemVal.id;
    node.type = itemVal.type;
    node.isSelected = itemVal.isSelected;
    node.hasChildren = itemVal.hasChildren;
    node.displayName = treeItem.text;
    node.isCollapsed = treeItem.collapsed;
      
    // assign parents
    if (itemVal.parent) {
      node.parent = this.mapTreeviewItemToTreeNode(itemVal.parent);
    } else {
      itemVal.parent = null;
    }
    
    // assign children
    if (treeItem.children) {
      node.children = this.mapTreeviewItemToTreeNodeRecursion(treeItem.children);
    } else {
      node.children = null;
    }

    if (treeItem.children) {
      this.convertParentFromTreeviewItemToTreeNodeRecursion(node.children, node);
    }

    // assign tree node level
    node.level = this.getTreeNodeLevel(node, 1);
    return node;
  }

  mapTreeviewItemToTreeNodeRecursion(items: TreeviewItem[]): TreeNode[] {
    const treeNodes = new Array<TreeNode>();
    items.forEach((treeItem: TreeviewItem) => {
      const itemVal = treeItem.value as TreeviewItemValue;
      const node = new TreeNode();
      node.id = itemVal.id;
      node.type = itemVal.type;
      node.isSelected = itemVal.isSelected;
      node.hasChildren = itemVal.hasChildren;
      node.displayName = treeItem.text;

      if (treeItem.children) {
        node.children = this.mapTreeviewItemToTreeNodeRecursion(treeItem.children);
      } else {
        node.children = null;
      }
      node.isCollapsed = treeItem.collapsed;
      treeNodes.push(node);
    });
    return treeNodes;
  }

  convertParentFromTreeviewItemToTreeNodeRecursion(treeNodes: TreeNode[], parent: TreeNode): void {
    treeNodes.forEach(
      (node: TreeNode) => {
        node.parent = parent;
        if (node.children) {
          this.convertParentFromTreeviewItemToTreeNodeRecursion(node.children, node);
        }
      });
  }

  // Convert TreeNode[] to TreeviewItem[]
  mapTreeNodeToTreeItem(nodes: TreeNode[]): TreeviewItem[] {
    return this.mapTreeNodeToTreeItemRecursion(nodes);
  }

  mapTreeNodeToTreeItemRecursion(nodes: TreeNode[]): Array<TreeviewItem> {
    const treeviewItem = new Array<TreeviewItem>();
    nodes.forEach((node: TreeNode) => {
      const item: TreeItem = {} as TreeItem;
      item.text = node.displayName;

      const val = new TreeviewItemValue();
      val.id = node.id;
      // logic to restict loading process
      if (node.level == this.levelsToLoad && !this.loadProcess) {
        val.hasChildren = false;
      } else {
        val.hasChildren = node.hasChildren;
      } 
      val.isSelected = node.isSelected;
      val.type = node.type;
      item.value = val;

      item.checked = false;
      item.collapsed = node.isCollapsed;
      item.disabled = false;
      
      if (node.children) {
        item.children = this.mapTreeNodeToTreeItemRecursion(node.children);
      } else {
        item.children = null;
      }
      const tItem = new TreeviewItem(item);
      treeviewItem.push(tItem);
    });
    return treeviewItem;    
  }

  convertParentFromTreeNodeToTreeViewRecursion(treeviewItems: TreeviewItem[], parent: TreeviewItem): void {
    treeviewItems.forEach(
      (item: TreeviewItem) => {
        const val = item.value as TreeviewItemValue;
        val.parent = parent;
        if (item.children) {
          this.convertParentFromTreeNodeToTreeViewRecursion(item.children, item);
        }
      });
  }

  // Loading the selected item's hierarchy
  loadHierarchyForSelectedItem(item: TreeviewItem, selectedItem: TreeLevelData[], currentLevel: number): void {
    if (currentLevel === 0) {
      (item.value as TreeviewItemValue).isSelected = true;
      this.nodes.next(this.treeStorage);
      return;
    }
    const dummyTreeNode = this.mapTreeviewItemToTreeNode(item);
    this.treeDataService.getChildren(dummyTreeNode).subscribe(
      (res: TreeNode[]) => {
        const resItem = this.mapTreeNodeToTreeItem(res);
        this.convertParentFromTreeNodeToTreeViewRecursion(resItem, item);
        item.children = resItem;
        item.collapsed = false;
        currentLevel = currentLevel - 1;
        const selectedSubItem = resItem.find(x => String((x.value as TreeviewItemValue).id) === String(selectedItem[currentLevel].id));
        this.loadHierarchyForSelectedItem(selectedSubItem, selectedItem, currentLevel);
      },
      (error: HttpErrorResponse) => {
        const modal = this.modalService.open(NgbModalErrorComponent);
        modal.componentInstance.message = "Error occured while loading tree hierarchy. Please contact administrator. " + error.error;
        modal.result.then(
          () => {
            this.nodes.next(this.treeStorage);
          }
        );
      }
    );
  }

  // Expand children of tree item from service
  expandTreeViewChildrenFromService(id: string, loadProcess: boolean): void {
    this.loadProcess = loadProcess;
    this.expandTreeViewChildrenFromServiceRecursion(this.treeStorage, id);
  }

  expandTreeViewChildrenFromServiceRecursion(treeStorage: TreeviewItem[], id: string): void {
    for (let index = 0; index < treeStorage.length; index++) {
      const item = treeStorage[index] as TreeviewItem;
      const itemVal = item.value as TreeviewItemValue;

      if (itemVal.id === id) {
        const node = this.mapTreeviewItemToTreeNode(item);
        this.treeDataService.getChildren(node).subscribe(
          (res: TreeNode[]) => {
            if (res.length > 0) {
              // convert result to TreeviewItem[]
              const resTreeViewItem = this.mapTreeNodeToTreeItem(res);
              this.convertParentFromTreeNodeToTreeViewRecursion(resTreeViewItem, item);
              item.children = resTreeViewItem;
              item.collapsed = false;
              this.nodes.next(this.treeStorage);
            } else {
              item.children = null;
              itemVal.hasChildren = false;
              this.nodes.next(this.treeStorage);
            }
          },
          () => {
            const modal = this.modalService.open(NgbModalErrorComponent);
            modal.componentInstance.message = "Error occured while loading children in tree, Please contact administrator.";
            modal.result.then(
              () => {
                this.nodes.next(this.treeStorage);
              }
            );
          }
        );
      }
      if (item.children) {
        this.expandTreeViewChildrenFromServiceRecursion(item.children, id);
      }
    }
  }

  // Item selection event
  selectItemEvent(treeSelection: TreeSelection): void {
    const selected = treeSelection.selectionHierarchy[0];
    if (selected.id != null) {
      this.localCacheService.setItem("SELECTED_ITEM", treeSelection.selectionHierarchy);

      // Get previous selection
      const prevSelectionId = this.selectedValueId;
      this.selectedValueId = selected.id;

      // Change property isSelected for local storage
      this.selectionChangeRecursion(this.treeStorage, prevSelectionId, selected);

    }
  }

  selectionChangeRecursion(treeStorage: TreeviewItem[], oldId: string, newSelected: TreeLevelData) {
    for (let index = 0; index < treeStorage.length; index++) {
      const node = treeStorage[index] as TreeviewItem;
      const nodeVal = node.value as TreeviewItemValue;

      if (nodeVal.id === oldId) {
        nodeVal.isSelected = false;
      }

      if (nodeVal.id === newSelected.id && nodeVal.type == newSelected.type) {
        nodeVal.isSelected = true;
      }

      if (node.children) {
        this.selectionChangeRecursion(node.children, oldId, newSelected);
      }
    }
  }
}
