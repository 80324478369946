import { Injectable, OnDestroy } from '@angular/core';
import { GraphMap } from '../common/graph/graph-map';
import { cloneDeep, clone } from 'lodash-es';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class DisplayGraphService implements OnDestroy {

  public processId: string;
  private graphMap: GraphMap;
  private graphChange: Subject<GraphMap>;


  constructor() {
    this.graphMap = null;
    this.graphChange = new Subject<GraphMap>();
    this.processId = "default";
  }

  ngOnDestroy(): void {
    this.graphChange.complete();
  }

  public getProcessMap(): GraphMap {
    // return deep copy of the graph map.
    // once graph from visjs is attached to a div, it cant be used again with
    // display tab component.
    return this.cloneGraphMap(this.graphMap);
  }

  public setProcessMap(graphMap: GraphMap): void {
    this.graphMap = this.cloneGraphMap(graphMap);
  }

  public getGraphMapChanges(): Observable<GraphMap> {
    return this.graphChange.asObservable();
  }

  public publishGraphChange(): void {
    this.graphChange.next(this.graphMap);
  }

  public setProcessId(val: string): void{
    this.processId = val;
  }

  public getProcessId(): string {
    return this.processId;
  }

  private cloneGraphMap(graphMap: GraphMap): GraphMap {
    const clonedGraphMap = clone(graphMap);
    clonedGraphMap.visNetwork = cloneDeep(graphMap.visNetwork);
    return clonedGraphMap;
  }
}
