import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AliasStatus } from '../model/graph-data.model';
import { UserDataService } from './user-data.service';

@Injectable({
  providedIn: 'root'
})
export class GraphDataService {

  private graphUri = "https://graph.microsoft.com/v1.0/";
  graphToken: string;

  constructor(private userDataSrv: UserDataService, private httpClient: HttpClient) {
  }

  public getUserPhoto() {
    // This method is to get the user's profile photo from the Graph API.
    const url = this.graphUri + "users/" + this.userDataSrv.userName + "/photo/$value";

    return this.httpClient.get(
      url,
      { responseType: 'blob' }
    );
  }

  isAliasValid(alias: string): Observable<AliasStatus> {
    let userPrincipleName = alias.toLowerCase();
    // check if user already entered with @microsoft.com
    if (userPrincipleName.search('@microsoft.com') === -1) {
      userPrincipleName += '@microsoft.com';
    }

    const url = this.graphUri + "users/" + userPrincipleName;
    return this.httpClient.get<any>(url, {observe: 'response'}).pipe(
      map(response => new AliasStatus(alias, response.status)
      ),
      catchError(err => of(new AliasStatus(alias, err.status))
      ),
    );
  }

  getGroupId(groupAlias: string): Observable<string> {
    let groupName = groupAlias.toLowerCase();
    // check if user already entered with @microsoft.com
    if (groupName.search('@microsoft.com') === -1) {
      groupName += '@microsoft.com';
    }
    const url = this.graphUri + "groups/?$filter=mail eq '" + groupAlias + "'&$select=id";
    return this.httpClient.get<any>(url, {observe: 'response'}).pipe(
      map(response => {
        if (response.body.value.length === 0) {
          throw Error("Invalid Admin SG");
        } else {
          return response.body.value[0].id;
        }
      })
    );
  }
}
