import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Operation, Service, ServiceInput, IKeyMapping, OperationType, IKeyServiceMapping } from '../model/qos.model';
import { CacheService } from '../../service/cache.service';
import { HierarchyTreeNode, ServiceTreeService } from '../../bam-dashboard/model/process.model';
import { throwError, Observable, forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import { APP_CONFIG } from '../../common/constants';
import { AppConfig } from '../../model/app-config.model';
import { HierarchyTreeService } from '../../service/hierarchy-tree.service';

@Injectable({
  providedIn: 'root'
})
export class QoSService {

  private baseUrlWithVersion: string;
  constructor(private httpClient: HttpClient, private aiService: ApplicationInsights, @Inject(APP_CONFIG) private appConfig: AppConfig,
    private cacheService: CacheService, private hierarchyTreeService: HierarchyTreeService) {
    this.baseUrlWithVersion = this.appConfig.baseUrlQoSDashboard;
  }

  // fetch all the services available in service tree under a team group.
  getServiceTreeServicesByTeamGroup(teamGroupId: string): Observable<Array<ServiceTreeService>> {    
    return this.hierarchyTreeService.getTree(teamGroupId).pipe(
      switchMap((node : HierarchyTreeNode[]) => {
        const teamGroupNodeId = node[0].internalId;
        return this.hierarchyTreeService.getTreeNodeChildren(teamGroupNodeId);
      }),
      map((nodes: HierarchyTreeNode[]) => {
        let serviceList = new Array<ServiceTreeService>(); 
        nodes.forEach((element: HierarchyTreeNode) => { 
          const service = new ServiceTreeService(element.nodeId, element.nodeName, teamGroupId);
          serviceList.push(service);
        })
        return serviceList;
      })
    )
  }

  // get all services i.e Requests and Remote Dependency in QoS DB.
  getAllOperationsByTeamGroup(teamGroupId: string): Observable<
    { serviceInput: ServiceInput, requestsData: Service[], remoteDependencyData: Service[] }> {
    return this.httpClient.get<ServiceInput>(this.baseUrlWithVersion + "QoS?teamGroupOid=" + teamGroupId).pipe(
      map((s: ServiceInput) => {
        const requestsData = this.filterServices(s.services, OperationType.Request);
        const remoteDependencyData = this.filterServices(s.services, OperationType.RemoteDependency);

        return { serviceInput: s, requestsData: requestsData, remoteDependencyData: remoteDependencyData };
      })
    );
  }

  private filterServices(services: Service[], type: OperationType): Service[] {
    let filteredServices = cloneDeep(services);
    filteredServices.forEach(service => {
      service.operations = service.operations.filter(o => o.type === type);
    });
    filteredServices = filteredServices.sort((x, y) => {
      const serviceOidCompare = x.serviceOid.localeCompare(y.serviceOid);
      if (serviceOidCompare === 0) {
        return x.serviceName.localeCompare(y.serviceName);
      } else {
        return serviceOidCompare;
      }
    });
    // sort operations.
    filteredServices.forEach(c => {
      c.operations.sort((o1, o2) => o1.name.localeCompare(o2.name));
      return c;
    });
    return filteredServices;
  }

  // bulk add of Request and Remote Dependency.
  addOperations(serviceInput: ServiceInput) {
    return this.httpClient.post(this.baseUrlWithVersion + "QoS", serviceInput);
  }

  // update Operation in Namewhitelist table in QoS DB
  updateOperation(service: Service): Observable<any> {
    return this.httpClient.put(this.baseUrlWithVersion + "QoS(" + service.operations[0].id + ")", service);
  }

  // delete Operation in Namewhitelist table in QoS DB
  deleteOperation(operation: Operation): Observable<any> {
    return this.httpClient.delete(this.baseUrlWithVersion + "QoS(" + operation.id + ")?type=" + operation.type);
  }

  getIkeyListByServiceId(serviceOid: string): Observable<Array<string>> {
    const url = this.baseUrlWithVersion + 'IKeyMapping/' + serviceOid;
    return this.httpClient.get<Array<string>>(url);
  }

  saveIkeyList(iKeyMapping: IKeyMapping): Observable<any> {
    const url = this.baseUrlWithVersion + 'IKeyMapping';
    return this.httpClient.post(url, iKeyMapping);
  }

  // delete ikey associated to a service id
  deleteIKeyServiceMapping(deleteIKey: IKeyServiceMapping): Observable<any> {
    return this.httpClient.request('delete', this.baseUrlWithVersion + "IKeyMapping", { body: deleteIKey });
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    let statusCode = 0;
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred.
      errorMessage = `An error occurred: ${error.error.message}`;
      this.aiService.trackException(error);
    } else {
      // The backend returned an unsuccessful response code
      errorMessage = `Server returned code: ${error.status}, error message is: ${error.message}`;
      this.aiService.trackException(error);
      statusCode = error.status;
    }
    console.error(errorMessage);
    return throwError({ errorMessage, statusCode });
  }
}
