<div style="min-height: 100vh">
  <!-- top navbar -->
  <div role="banner">
    <nav class="navbar navbar-expand navbar-light text-white" style="background-color: black">
      <img
        class="img-fluid pr-2"
        src="../assets/images/MicrosoftLogo.png"
        title="Microsoft Company Logo"
        alt="Microsoft Company Logo"
        aria-label="Microsoft Company Logo"
        style="width: 2.5rem"
      />
      <div class="navbar-brand">
        <a [routerLink]="[]" (click)="navigateMainApp('Monitoring 360')">
          <span class="text-white">Monitoring 360</span>
        </a>
      </div>
      <button type="button" class="skip-to-content-link mx-auto" (click)="focusMainDiv()">Skip to main content</button>
      <div class="mr-auto"></div>
      <span class="text-white pr-2"> {{ userName }} </span>
      <img
        class="img-fluid"
        style="border-radius: 50%"
        [title]="userName"
        alt="User Photo"
        attr.aria-label="Profile Photo of {{ userName }}"
        [src]="userImage"
        width="40"
      />
    </nav>
  </div>

  <!-- vertical navbar -->
  <nav class="navbar-primary" [ngClass]="isCollapsed ? 'collapsed' : ''" style="background-color: rgb(34, 31, 31)">
    <ul class="navbar-primary-menu" id="navbar-primary-menu">
      <li [ngClass]="currentAppName === 'Monitoring 360' ? 'active' : ''">
        <a
          [routerLink]="[]"
          (click)="navigateMainApp('Monitoring 360'); focusMainDiv()"
          id="HomeMenu"
          aria-label="Home"
          data-target="#collapseAll"
          data-toggle="collapse"
        >
          <span class="text-success pr-1 fa fa-home"></span><span class="text-white nav-label"><b>Home</b></span>
        </a>
      </li>
      <span data-parent="#navbar-primary-menu" id="collapseAll"></span>
      <li
        *ngIf="this.appConfig.featureFlag.enableEventBroker"
        [ngClass]="currentAppName === 'Event Broker' ? 'active' : ''"
      >
        <a
          [routerLink]="[]"
          (click)="navigateMainApp('Event Broker')"
          data-target="#EventBrokerMenu"
          data-toggle="collapse"
          aria-label="Event Broker"
        >
          <span class="text-danger pr-1 fa fa-bullhorn"></span
          ><span class="text-white nav-label">
            <b>Event Broker</b>
          </span>
        </a>
      </li>
      <li>
        <div
          id="EventBrokerMenu"
          [hidden]="isCollapsed"
          class="collapse"
          data-parent="#navbar-primary-menu"
          #EventBrokerMenu
        >
          <ul class="navbar-nav mr-auto">
            <li
              *ngFor="let link of eventBrokerOptions"
              class="nav-item"
              [ngClass]="currentAppSubOption === link ? 'active' : ''"
            >
              <a class="nav-link sub-nav" [routerLink]="[]" (click)="navigateMonitorCatalog(link)">
                <span class="text-white">{{ link }}</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
      <li
        *ngIf="this.appConfig.featureFlag.enableDashboardCatalog"
        [ngClass]="currentAppName === 'Dashboard Catalog' ? 'active' : ''"
      >
        <a
          [routerLink]="[]"
          (click)="navigateMainApp('Dashboard Catalog')"
          data-target="#MonitoringSolutionMenu"
          data-toggle="collapse"
          aria-label="Dashboard Catalog"
        >
          <span class="text-info pr-1 fa fa-book"></span
          ><span class="text-white nav-label">
            <b>Dashboard Catalog</b>
          </span>
        </a>
      </li>
      <li>
        <div
          id="MonitoringSolutionMenu"
          [hidden]="isCollapsed"
          class="collapse"
          data-parent="#navbar-primary-menu"
          #MonitoringSolutionMenu
        >
          <ul class="navbar-nav mr-auto">
            <li
              *ngFor="let link of monitorCatalogOptions"
              class="nav-item"
              [ngClass]="currentAppSubOption === link ? 'active' : ''"
            >
              <a class="nav-link sub-nav" [routerLink]="[]" (click)="navigateMonitorCatalog(link)">
                <span class="text-white">{{ link }}</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
      <li
        *ngIf="this.appConfig.featureFlag.enableQoS"
        [ngClass]="currentAppName === 'Quality Of Service' ? 'active' : ''"
      >
        <a
          [routerLink]="[]"
          (click)="navigateMainApp('Quality Of Service')"
          data-target="#QualityOfServiceMenu"
          data-toggle="collapse"
          aria-label="Quality Of Service"
        >
          <span class="text-danger pr-1 fa fa-tachometer"></span
          ><span class="text-white nav-label">
            <b>Quality Of Service</b>
          </span>
        </a>
      </li>
      <li>
        <div
          id="QualityOfServiceMenu"
          [hidden]="isCollapsed"
          class="collapse"
          data-parent="#navbar-primary-menu"
          #QualityOfServiceMenu
        >
          <ul class="navbar-nav mr-auto">
            <li
              *ngFor="let link of qualityOfServiceOptions"
              class="nav-item"
              [ngClass]="currentAppSubOption === link ? 'active' : ''"
            >
              <a class="nav-link sub-nav" [routerLink]="[]" (click)="navigateMonitorCatalog(link)">
                <span class="text-white">{{ link }}</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
      <li *ngIf="this.appConfig.featureFlag.enableCT" [ngClass]="currentAppName === 'Control Tower' ? 'active' : ''">
        <a [routerLink]="[]" (click)="navigateMainApp('Control Tower')" aria-label="Control Tower">
          <m360_he-icon name="networktower" class="icon-style"></m360_he-icon>
          <span class="text-white nav-label">
            <b>Control Tower</b>
          </span>
        </a>
      </li>
      <li *ngIf="this.appConfig.featureFlag.enableBAM" [ngClass]="currentAppName === 'BAM Dashboard' ? 'active' : ''">
        <a
          [routerLink]="[]"
          (click)="navigateMainApp('BAM Dashboard')"
          data-target="#BAMDashboardMenu"
          data-toggle="collapse"
          aria-label="BAM Dashboard"
        >
          <span class="text-warning pr-1 fa fa-cube"></span
          ><span class="text-white nav-label">
            <b>BAM Dashboard</b>
          </span>
        </a>
      </li>
      <li>
        <div
          id="BAMDashboardMenu"
          [hidden]="isCollapsed"
          class="collapse"
          data-parent="#navbar-primary-menu"
          #BAMDashboardMenu
        >
          <ul class="navbar-nav mr-auto">
            <li
              *ngFor="let link of bamDashboardOptions"
              class="nav-item"
              [ngClass]="currentAppSubOption === link ? 'active' : ''"
            >
              <a class="nav-link sub-nav" [routerLink]="[]" (click)="navigateMonitorCatalog(link)">
                <span class="text-white">{{ link }}</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
      <li
        *ngIf="this.appConfig.featureFlag.enableScoreCard"
        [ngClass]="currentAppName === 'Score Card' ? 'active' : ''"
      >
        <a
          [routerLink]="[]"
          (click)="navigateMainApp('Score Card')"
          data-target="#ScoreCardMenu"
          data-toggle="collapse"
          aria-label="Score Card"
        >
          <span class="text-success pr-1 fa fa-heartbeat"></span
          ><span class="text-white nav-label">
            <b>Score Card</b>
          </span>
        </a>
      </li>
      <li>
        <div
          id="ScoreCardMenu"
          [hidden]="isCollapsed"
          class="collapse"
          data-parent="#navbar-primary-menu"
          #ScoreCardMenu
        >
          <ul class="navbar-nav mr-auto">
            <li
              *ngFor="let link of scoreCardOptions"
              class="nav-item"
              [ngClass]="currentAppSubOption === link ? 'active' : ''"
            >
              <a class="nav-link sub-nav" [routerLink]="[]" (click)="navigateMonitorCatalog(link)">
                <span class="text-white">{{ link }}</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
      <li
        *ngIf="this.appConfig.featureFlag.enableContactUs"
        [ngClass]="currentAppName === 'Contact Us' ? 'active' : ''"
      >
        <a
          [routerLink]="[]"
          (click)="navigateMainApp('Contact Us')"
          id="ContactUs"
          aria-label="Contact Us"
          data-target="#collapseAll"
          data-toggle="collapse"
        >
          <span class="text-success pr-1 fa fa-envelope"></span
          ><span class="text-white nav-label">
            <b>Contact Us</b>
          </span>
        </a>
      </li>
      <li *ngIf="this.appConfig.featureFlag.enableDataProtectionNotice">
        <a
          target="_blank"
          href="https://privacy.microsoft.com/en-US/data-privacy-notice"
          style="padding: 8px 0; text-align: center"
          aria-label="Data Protection Notice"
        >
          <span class="text-success pr-1 fa fa-info-circle"></span>
          <span class="text-white nav-label"><b>Data Protection Notice</b></span>
        </a>
      </li>
    </ul>
    <a class="btn-expand-collapse bg-dark" (click)="collapseNavbar()" [routerLink]="[]">
      <div *ngIf="!isCollapsed" title="Collapse Navigation Bar" [attr.aria-label]="CollapseNavigationBar">
        <span class="text-white fa fa-angle-double-left"></span>
      </div>
      <div *ngIf="isCollapsed" title="Open Navigation Bar" [attr.aria-label]="OpenNavigationBar">
        <span class="text-white fa fa-angle-double-right"></span>
      </div>
    </a>
  </nav>

  <!-- Main Content -->

  <div [ngClass]="isCollapsed ? 'main-collapsed' : 'main-not-collapsed'">
    <div style="overflow-y: auto; height: 93vh">
      <main #mainDiv tabindex="-1">
        <router-outlet></router-outlet>
      </main>
    </div>
  </div>
</div>
