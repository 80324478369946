import { Component, OnInit, OnDestroy } from '@angular/core';
import { GraphService } from '../service/graph.service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { GraphMap } from '../common/graph/graph-map';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalErrorComponent } from '../../modal/ngb-modal-error.component';
import { TreeSelection } from '../../common/model/tree-view.model';
import { TreeviewService } from '../../common/service/treeview.service';
import { ServicetreeDataService } from '../../common/service/servicetree-data.service';
import { TreeDataService } from '../../common/interface/tree-data-service';
import { constants } from '../../common/constants';

@Component({
  selector: 'app-process-health-view',
  templateUrl: './process-health-view.component.html',
  styleUrls: ['./process-health-view.component.css', '../../../style/common-styles.scss'],
  providers: [
    GraphService,
    TreeviewService,
    { provide: TreeDataService, useClass: ServicetreeDataService }
  ]
})
export class ProcessHealthViewComponent implements OnInit, OnDestroy {

  // template variables.
  isProcessHealthLoading: boolean;
  enableFilterPanel: boolean;
  selectedProcessId: string;
  isProcessSelected: boolean;

  // filter variables.
  isProcessHealthFilterLoading: boolean;
  filterDates: Array<string>;

  // accesibility.
  graphDescription: string;

  processMapSubscription: Subscription;


  constructor(
    private graphService: GraphService,
    private appInsightsService: ApplicationInsights,
    private modalService: NgbModal,
    private treeviewService: TreeviewService
  ) {
    this.isProcessHealthLoading = false;
    this.isProcessHealthFilterLoading = false;
    this.enableFilterPanel = false;
    this.graphDescription = '';
    this.processMapSubscription = null;
    this.isProcessSelected = false;
  }

  ngOnInit() {
    // Create treeview configuration
    this.treeviewService.setHeight(200);
    this.treeviewService.setTitle("Business Process");
    this.treeviewService.loadTreeView(true);
  }

  ngOnDestroy() {
    if (this.processMapSubscription != null) {
      this.processMapSubscription.unsubscribe();
    }
  }

  serviceTreeEventHandler(serviceTreeSelection: TreeSelection): void {
    const serviceTreeSelectionId = serviceTreeSelection.selectionHierarchy.find(x => x.type === constants.BUSINESS_PROCESS).id;
    if (serviceTreeSelectionId != null) {
      this.selectedProcessId = serviceTreeSelectionId;
      this.enableFilterPanel = false;
      this.isProcessSelected = true;
    } else {
      this.isProcessSelected = false;
    }
  }

  getProcessMapWithHealthInfo(): void {
    if (!this.isProcessHealthFilterLoading) {
      this.isProcessHealthLoading = true;
    }
    this.appInsightsService.startTrackEvent('Process Health Loading Time');
    this.appInsightsService.trackTrace({ message: `Process Health: Map for ${this.selectedProcessId} fetched`});

    this.processMapSubscription = this.graphService.getProcessMapWithHealthInfo(
      this.selectedProcessId, this.isProcessHealthFilterLoading, this.filterDates).subscribe(
        (processMap: GraphMap) => {
          const processMapElement: HTMLElement = document.getElementById('processMapWithAlertCount');
          processMap.setHTMLElement(processMapElement);
          processMap.initializeGraph();
          processMap.redrawGraph();
          this.graphDescription = processMap.getGraphDescription();
          processMap.updateLabels(processMap.getGraphModel('alert'));
          this.isProcessHealthLoading = false;
          this.isProcessHealthFilterLoading = false;
          this.enableFilterPanel = true;
          this.appInsightsService.stopTrackEvent('Process Health Loading Time');
        },
        (error: HttpErrorResponse) => {
          this.isProcessHealthLoading = false;
          this.isProcessHealthFilterLoading = false;
          this.appInsightsService.stopTrackEvent('Process Health Loading Time');
          console.log('Process Health: error while getting process health graph', error);
          this.appInsightsService.trackTrace({message: 'ProcessHealth: Error while fetching graph'});
          this.appInsightsService.trackException(error);
          const modal = this.modalService.open(NgbModalErrorComponent);
          let message = "Error: " + error.statusText;
          if (error.status === 404) {
            message += ": " + error.error;
          }
          modal.componentInstance.message = message;
        }
      );
  }

  cancelExecution(): void {
    this.isProcessHealthFilterLoading = false;
    this.isProcessHealthLoading = false;
    this.processMapSubscription.unsubscribe();
  }

  processFilterValues(value: Array<string>): void {
    this.isProcessHealthFilterLoading = true;
    this.filterDates = value;
    this.getProcessMapWithHealthInfo();
  }
}
