import { Component, Input, OnInit, OnDestroy, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ProcessService } from '../service/process.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MasterDataService } from '../service/master-data.service';
import { Process } from '../model/process.model';
import { CacheService } from '../../service/cache.service';
import { APP_CONFIG } from '../../common/constants';
import { AppConfig } from '../../model/app-config.model';

@Component({
  selector: 'app-modify-process',
  templateUrl: './modify-process.component.html',
  styleUrls: ['./modify-process.component.scss'],
  providers: [ProcessService]
})
export class ModifyProcessComponent implements OnInit, OnDestroy {
  @Input() teamGroupId: string;
  @Input() processId: number;
  @Input() isUpdate: boolean;

  processForm = new UntypedFormGroup({
    processName: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl('')
  });

  process: Process;

  showError: boolean;
  errorMessage: string;

  isLoading: boolean;
  isProcessLoading: boolean;

  subscription: Subscription;

  constructor(
    private activeModal: NgbActiveModal,
    private processService: ProcessService,
    private masterDataService: MasterDataService,
    private cacheService: CacheService,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
  ) {
    this.subscription = new Subscription();
    this.isLoading = false;
    this.isProcessLoading = false;
  }

  ngOnInit(): void {
    if (this.isUpdate) {
      this.isProcessLoading = true;
      const processSubscription = this.masterDataService.getBusinessProcessById(this.processId.toString()).subscribe(
        (process: Process) => {
          this.process = process;
          this.processForm.controls.processName.setValue(process.name);
          this.processForm.controls.description.setValue(process.description);
          this.isProcessLoading = false;
        },
        (error: HttpErrorResponse) => {
          console.error('Error occurred while fetching new process', error);
          this.isLoading = false;
          this.showError = true;
          this.errorMessage = error.error;
        }
      );

      this.subscription.add(processSubscription);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  cancel(): void {
    this.activeModal.dismiss();
  }

  saveProcess(): void {
    const processName = this.processForm.controls.processName.value as string;
    const description = this.processForm.controls.description.value as string;
    this.isLoading = true;
    const processSubscription = this.processService.addProcess(processName, this.teamGroupId, description).subscribe(
      () => {
        this.isLoading = false;
        const processListUrl = this.appConfig.baseUrlWithVersionBAMDashboard + 'Process/' + this.teamGroupId + '/processlist';
        this.cacheService.removeCached(processListUrl);
        this.activeModal.close();
      },
      (error: HttpErrorResponse) => {
        console.error('Error occurred while adding new process', error);
        this.isLoading = false;
        this.showError = true;
        this.errorMessage = error.error;
      }
    );

    this.subscription.add(processSubscription);
  }

  updateProcess(): void {
    this.isLoading = true;
    this.process.name = this.processForm.controls.processName.value as string;
    this.process.description = this.processForm.controls.description.value as string;
    const processSubscription = this.processService.updateProcess(this.process).subscribe(
      () => {
        this.isLoading = false;
        const processListUrl = this.appConfig.baseUrlWithVersionBAMDashboard + 'Process/' + this.teamGroupId + '/processlist';
        this.cacheService.removeCached(processListUrl);
        this.activeModal.close();
      },
      (error: HttpErrorResponse) => {
        console.error('Error occurred while updating process', error);
        this.isLoading = false;
        this.showError = true;
        this.errorMessage = error.error;
      }
    );

    this.subscription.add(processSubscription);
  }
}
