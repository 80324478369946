import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { EventType } from '../model/event-type.model';
import { EventCatalogService } from '../service/event-catalog.service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalErrorComponent } from '../../modal/ngb-modal-error.component';
import { UntypedFormControl } from '@angular/forms';
import { TableOptions, TableInputV2, RowNavigateEvent, RowEditEvent } from '../../bam-dashboard/model/common.model';
import { SaveEventComponent } from '../save-event/save-event.component';
import { ProblemDetails } from '../../common/model/problem-details.model';
import { ProblemDetailsHttpErrorResponse } from '../../common/model/problem-details-http-error-response.model';

@Component({
  selector: 'app-event-catalog',
  templateUrl: './event-catalog.component.html',
  styleUrls: ['./event-catalog.component.css'],
})
export class EventCatalogComponent implements OnInit, OnDestroy {

  /*
  Description:
  - main page of event catalog. search or add events here, drill in to set up subscriptions, etc
*/

  // Booleans
  isEventListLoaded: boolean;
  loadEventTable = false;

  // display table components.
  tableColumns: Array<string>;
  tableOptions: TableOptions;
  tableData: ReplaySubject<TableInputV2>;
  tableSearch: Subject<string>;

  search = new UntypedFormControl('');

  // Subscription
  private subscriptions: Subscription;

  constructor(
    private router: Router,
    private eventCatalogService: EventCatalogService,
    private appInsightsService: ApplicationInsights,
    private modalService: NgbModal,
  ) {
    this.subscriptions = new Subscription();

    // seting table output columns.
    this.tableColumns = ['Event Name', 'Description', 'Schema', 'Contact', 'Team', 'Service', 'Subscribe'];
    this.tableOptions = {
      addEditColumn: true,
      editColumns: ['Subscribe'],
      enableSearch: true,
      searchColumns: ['Event Name', 'Description', 'Schema', 'Contact', 'Team', 'Service'],
      columnWidths: [0.7, 0.8, 0.6, 0.4, 0.4, 0.4, 0.4],
      centeredColumns: [],
      wrapTextColumns: ['Description'],
      addNavColumn: true,
      navColumns: ['Event Name']
    };
    this.tableData = new ReplaySubject<TableInputV2>(1);
    this.tableSearch = new Subject<string>();

    this.isEventListLoaded = false;
  }

  ngOnInit(): void {
    this.loadEventCatalog();

    // search table
    const searchSubscription = this.search.valueChanges.subscribe(
      (searchValue: string) => {
        this.tableSearch.next(searchValue);
      }
    );
    this.subscriptions.add(searchSubscription);
  }

  ngOnDestroy(): void {
    // all child subscriptions will be unsubscribed to avoid memory leaks
    this.subscriptions.unsubscribe();
    this.tableData.complete();
    this.tableSearch.complete();
  }

  // Add new event
  addNewEvent(): void {
    const createEventModal = this.modalService.open(SaveEventComponent);
    if (createEventModal) {
      createEventModal.result
        .then((newEventName: string) => {
          this.router.navigateByUrl('event-broker/event/' + newEventName.toLowerCase());
        })
        .catch(() => { });
    }
  }

  // Add new subscription
  addNewSubscription(eventTypeEvent: RowEditEvent): void {
    const eventType = JSON.parse(eventTypeEvent.value);
    this.router.navigateByUrl('event-broker/event/'+eventType.name.toLowerCase()+'/subscribe');
  }

  // View event details
  viewDetails(input: RowNavigateEvent): void {
    this.router.navigateByUrl('event-broker/event/' + input.value.toLowerCase());
  }

  // Get events
  loadEventCatalog(): void {
    const getEventsSubscribe = this.eventCatalogService.getEventCatalog().subscribe(
      (eventList: EventType[]) => {
        this.displayEventCatalog(eventList);
      },
      (errResponse: ProblemDetailsHttpErrorResponse) => {
        this.appInsightsService.trackException({ exception: errResponse }, {componentName: 'EventCatalog Component: Error fetching event catalog'});
        const pd: ProblemDetails = errResponse.error;
        if (pd.status !== 404) {
          const errorModal = this.modalService.open(NgbModalErrorComponent);
          errorModal.componentInstance.message = 'Could not load Event Catalog: ' + pd.title;
        }
      }
    );
    this.subscriptions.add(getEventsSubscribe);
  }

  // dispaly event catalog
  displayEventCatalog(eventList: EventType[]): void {
    const tableInput = new TableInputV2();
    tableInput.columns = this.tableColumns;
    tableInput.rows = new Array<Array<string>>();
    eventList.forEach((eventType: EventType) => {

      const row = new Array<string>();
      row.push(eventType.name);
      row.push(eventType.description);
      row.push(eventType.schemaType);
      row.push(eventType.contactAlias?.replace("@microsoft.com",""));
      row.push(eventType.teamName);
      row.push(eventType.serviceName);
      row.push(JSON.stringify(eventType));

      tableInput.rows.push(row);
    });

    this.tableData.next(tableInput);
    this.isEventListLoaded = true;
  }
}
