import { RuleConfig } from './rule.model';

export class TemplateVariable {
    id: number;
    name: string;
    description: string;
    languageType: string;
    value: string;
    processId: number;
    validFrom: string;
    updatedBy: string;
    ruleIdList: string;
}

export class TemplateVariableRule {
    id: number;
    ruleName: string;
}
