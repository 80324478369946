import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { GraphService } from '../service/graph.service';
import { GraphMap } from '../common/graph/graph-map';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalErrorComponent } from '../../modal/ngb-modal-error.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { PageName, PropertyTag } from '../model/page-configuration.model';
import { PropertyTaggingService } from '../service/property-tagging.service';
import { ProcessProperty } from '../model/graph.model';
import { DisplayGraphService } from '../service/display-graph.service';
import { QueryOptionsService } from '../service/query-options.service';
import { APP_CONFIG, constants } from '../../common/constants';
import { AppConfig } from '../../model/app-config.model';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { TreeSelection } from '../../common/model/tree-view.model';
import { TreeviewService } from '../../common/service/treeview.service';
import { TreeDataService } from '../../common/interface/tree-data-service';
import { ServicetreeDataService } from '../../common/service/servicetree-data.service';
import { HierarchyTreeNode } from '../model/process.model';
import { HierarchyTreeService } from '../../service/hierarchy-tree.service';

@Component({
  selector: 'app-process-discovery',
  templateUrl: './process-discovery.component.html',
  styleUrls: ['./process-discovery.component.css', '../../../style/common-styles.scss'],
  providers: [
    GraphService,
    PropertyTaggingService,
    DisplayGraphService,
    QueryOptionsService,
    TreeviewService,
    { provide: TreeDataService, useClass: ServicetreeDataService }
  ]
})
export class ProcessDiscoveryComponent implements OnInit, OnDestroy {
  // display variables.
  isBusinessProcessCollapsed: boolean;
  isGraphLoading: boolean;
  isProcessSelected: boolean;
  isBusinessProcessReset: boolean;

  wikiUrl: string;
  selectedProcessId: string;
  hierarchyLevelsToLoad: number;

  // Subscription
  graphProcessSubscription$: Subscription;
  subscription: Subscription;

  urlProcessId: string;
  urlProcessTab: string;

  // required constant for property tagging service.
  currentPageName: PageName = PageName.ProcessDiscovery;

  constructor(private graphService: GraphService,
    private appInsightsService: ApplicationInsights,
    private modalService: NgbModal,
    private propertyTaggingService: PropertyTaggingService,
    private displayGraphService: DisplayGraphService,
    private queryOptionsService: QueryOptionsService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private treeviewService: TreeviewService,
    private hierarchyTreeService: HierarchyTreeService,
    @Inject(APP_CONFIG) appConfig: AppConfig
  ) {
    // view toggling.
    this.isBusinessProcessCollapsed = false;
    this.isGraphLoading = false;
    this.isProcessSelected = false;
    this.isBusinessProcessReset = false;

    this.wikiUrl = appConfig.processDiscoveryWikiUrl;
    this.hierarchyLevelsToLoad = appConfig.hierarchyLevelsToLoad;
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    // Create treeview configuration
    this.treeviewService.setHeight(200);
    this.treeviewService.setTitle("Business Process");

    // get process id and tab parameters from url
    const routeSubscription = this.route.params.subscribe((params) => {
      this.urlProcessId = params['id'] as string;
      if (this.urlProcessId !== null || this.urlProcessId !== undefined) {
        this.treeviewService.loadTreeView(true, this.urlProcessId);
      } else {
        this.treeviewService.loadTreeView(true);
      }
    });
    this.setTabPath();
    this.subscription.add(routeSubscription);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  processResults() {
    this.isGraphLoading = true;
    this.propertyTaggingService.setCurrentPagename(this.currentPageName);
    this.propertyTaggingService.disableComponent();
    this.appInsightsService.startTrackEvent('Process Discovery Graph Load Time');
    this.graphProcessSubscription$ = this.graphService.getProcessMiningGraph(this.selectedProcessId).subscribe(
      (processMiningGraph: GraphMap) => {
        this.appInsightsService.stopTrackEvent('Process Discovery Graph Load Time');

        // adding process map to property tagging service.
        const propertyTags: Array<PropertyTag> = [PropertyTag.ProcessMiningGraph];
        const processMiningProperty = new ProcessProperty('ProcessMiningProperty', null, propertyTags);
        this.propertyTaggingService.clearInput();
        this.propertyTaggingService.createPageComponentInput(this.currentPageName);
        this.propertyTaggingService.addProperty(this.currentPageName, processMiningProperty);
        // add process mining v2 property for network graph.
        const processMiningPropertyV2 = new ProcessProperty('ProcessMiningProperty', null, [PropertyTag.ProcessMiningGraphV2]);
        this.propertyTaggingService.addProperty(this.currentPageName, processMiningPropertyV2);

        this.displayGraphService.setProcessMap(processMiningGraph);
        this.displayGraphService.setProcessId(this.selectedProcessId);

        // add power bi property.
        const powerBIProperty = processMiningGraph.getGraphProperties().find(p => p.name === 'PowerBIReport');
        if (powerBIProperty != null) {
          this.propertyTaggingService.addProperty(this.currentPageName, powerBIProperty);
        }

        // temp code: add one path case id property.
        if (!processMiningGraph.isEmpty()) {
          const pathId = processMiningGraph.getPathDistributionList()[0].pathId;
          const pathProperty = processMiningGraph.getGraphPathPropertiesByPathId(pathId).find(p => p.name === 'SampleCases');
          if (pathProperty != null) {
            this.propertyTaggingService.addProperty(this.currentPageName, pathProperty);
          }
        }

        // add query options only if user authorized.
        this.queryOptionsService.checkUserAuthorization().subscribe((isUserAuthenticated: boolean) => {
          if (isUserAuthenticated) {
            const queryOptionPropertyTag: Array<PropertyTag> = [PropertyTag.QueryOptions];
            const queryOptionsProperty = new ProcessProperty('QueryOptions', null, queryOptionPropertyTag);
            this.propertyTaggingService.addProperty(this.currentPageName, queryOptionsProperty);
          }
        });

        // temp, always set display tab to first tab.
        this.tabPathHandler('process-map');
        this.urlProcessTab = 'process-map';

        // enable display tab component.
        this.propertyTaggingService.setComponentInput();
        this.propertyTaggingService.enableComponent();

        this.isGraphLoading = false;
      },
      (error: HttpErrorResponse) => {
        this.isGraphLoading = false;
        this.appInsightsService.stopTrackEvent('Process Discovery Graph Load Time');
        console.log('error occured while getting process mining graph: ', error);
        this.appInsightsService.trackException(error);
        const modal = this.modalService.open(NgbModalErrorComponent);
        modal.componentInstance.message = "Error: " + error.error;
      }
    );
  }

  // toggle methods for views.
  toggleBusinessProcessView(): void {
    this.isBusinessProcessCollapsed = !this.isBusinessProcessCollapsed;
  }

  serviceTreeHandler(serviceTreeSelection: TreeSelection): void {
    // cancel loading the graph, if any of the service tree component changes
    if (this.isGraphLoading) {
      this.cancelClick();
    }
    // The selection hierarchy in service tree holds the selected nodes in tree view with the last level at [0] index
    // If admin page was previously visited, the selection hierarchy will not have the process else it will
    // The internalId here is the level above the process
    const internalId = (serviceTreeSelection.selectionHierarchy.length === this.hierarchyLevelsToLoad + 1
      ? serviceTreeSelection.selectionHierarchy[1].id : serviceTreeSelection.selectionHierarchy[0].id);
    const hierarchyServiceSubscription = this.hierarchyTreeService.getTreeNodeById(internalId).subscribe((response: HierarchyTreeNode) => {
      if (response.nodeId != null) {
        this.queryOptionsService.setOwnerGroupId(response.nodeId);
      }
    },
      (error: HttpErrorResponse) => {
        console.log('error occured while getting team group id: ', error);
        this.appInsightsService.trackException(error);
        const modal = this.modalService.open(NgbModalErrorComponent);
        modal.componentInstance.message = "Error: " + error.error;
      },
      () => {
        // update the process id if emitted by the service tree component.
        // set the url to process and start loading graph
        const processId = serviceTreeSelection.selectionHierarchy.find(x => x.type === constants.BUSINESS_PROCESS).id;
        if (processId != null) {
          if (this.selectedProcessId !== undefined) {
            this.urlProcessTab = null;
          }
          this.selectedProcessId = processId;
          // update path for new process
          this.tabPathHandler("process-map");
          this.isProcessSelected = true;
          this.queryOptionsService.setProcessId(this.selectedProcessId);
          this.processResults();
        } else {
          this.isProcessSelected = false;
        }
      });
    this.subscription.add(hierarchyServiceSubscription);
  }

  tabPathHandler(path: string): void {
    const url = `bam-dashboard/process-discovery/${this.selectedProcessId}/${path}`;
    this.location.replaceState(url);
  }

  setTabPath(): void {
    const lastUrlSegment = this.router.url.split('/').pop();
    this.urlProcessTab = lastUrlSegment !== "process-discovery" && isNaN(+lastUrlSegment) ? lastUrlSegment : null;
  }

  cancelClick() {
    this.appInsightsService.stopTrackEvent('Process Discovery Graph Load Time');
    this.appInsightsService.trackEvent({ name: 'Process Discovery Graph Execution Cancelled'});
    this.graphProcessSubscription$.unsubscribe();
    this.isGraphLoading = false;
  }
}
