export class TableInputV2 {
    columns: Array<string>;
    rows: Array<Array<string>>;
}
 
export class TableOptions {
    addEditColumn: boolean; // Set to true, if there is a edit column in table
    editColumns?: Array<string>; // Add the names of edit columns in table
    enableSearch: boolean; // Set to true, if table should have search feature
    searchColumns?: Array<string>; // Add the columns names on which search should be enabled
    columnWidths?: Array<number>;  // Add the display column width
    centeredColumns?: Array<string>; // Add columns that need to centered
    wrapTextColumns?: Array<string>; // Add columns to wrap text to first 80 chars
    addNavColumn?: boolean; // Set to true, if there is a navigation column in table
    navColumns?: Array<string>; // Add the names of nav columns in the table
}

export interface RowEditEvent {
    value: string;
    column: string;
}

export interface RowNavigateEvent {
    value: string;
    column: string;
}

export interface ColumnInterface {
    prop: string;
    name: string;
    flexGrow: number;
}

export interface RowInterface {
    [key: string]: string;
}
