<div class="p-2" #titleDiv tabindex="-1">
  <h4>Contact Us</h4>
  For any feedback or questions, please contact us at <a class="link-text"
    href="mailto:M360PlatAdmin@microsoft.com">M360PlatAdmin@microsoft.com</a>
</div>

<div class="p-2" title="Process Mining Introduction video">
  <h5>Process Mining Introduction</h5>
  <iframe width="640" height="360" aria-label="Process Mining Introduction"
    src="https://msit.microsoftstream.com/embed/video/2c4998c3-a413-428a-89bc-f4d190959607?autoplay=false&amp;showinfo=true"
    allowfullscreen style="border:none;" sandbox="allow-same-origin allow-scripts">
  </iframe>
</div>